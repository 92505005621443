import React, { useEffect, useState } from 'react';
import '../styles/Greeting.css';

function Greeting({ screen }) {
  const [firstName, setFirstName] = useState('');
  const [greeting, setGreeting] = useState('');
  const [timeBasedGreeting, setTimeBasedGreeting] = useState('');
  const [summary, setSummary] = useState('');
  const [examples, setExamples] = useState([]);
  const [displayedExample, setDisplayedExample] = useState('');
  const [exampleIndex, setExampleIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [typingSpeed, setTypingSpeed] = useState(150);

  useEffect(() => {
    const storedFirstName = localStorage.getItem('userFirstName');
    if (storedFirstName) {
      setFirstName(storedFirstName);
    }
  }, []);

  useEffect(() => {
    const getTimeBasedGreeting = () => {
      const hour = new Date().getHours();
      if (firstName) {
        if (hour >= 5 && hour < 12) return `Good morning, ${firstName}`;
        if (hour >= 12 && hour < 18) return `Good afternoon, ${firstName}`;
        if (hour >= 18 && hour < 22) return `Good evening, ${firstName}`;
        return `Burning the midnight oil? Remember, a well-rested ${firstName} is a super ${firstName}!`;
      } else {
        if (hour >= 5 && hour < 12) return "Good morning";
        if (hour >= 12 && hour < 18) return "Good afternoon";
        if (hour >= 18 && hour < 22) return "Good evening";
        return "Burning the midnight oil? Remember, a well-rested user is a super user!";
      }
    };

    setTimeBasedGreeting(getTimeBasedGreeting());

    let greetingText = '';
    let summaryText = '';
    let examplesText = [];
    switch (screen) {
      case 'PatientExplorer':
        greetingText = 'Welcome to Patient Explorer!';
        summaryText = 'Search and query information on patients, including visit histories, surgeries, medications, and appointment schedules.';
        examplesText = [
          'When was their last visit?',
          'How many surgeries has this patient had?',
          'What is the patient\'s current medication list?',
          'Are there any upcoming appointments?',
        ];
        break;
      case 'AcademicSearch':
        greetingText = 'Welcome to Academic Search!';
        summaryText = 'Query for cited sources on diseases or medications, ideal for in-depth research and obtaining trusted information.';
        examplesText = [
          'What is the best treatment for Leukemia in dogs?',
          'What dosage of gabapentin to give to felines?',
          'Latest research on heart disease in elderly cats',
          'Effectiveness of CBD oil for anxiety in dogs',
        ];
        break;
      case 'QuickSOAP':
        greetingText = 'Welcome to QuickSOAP!';
        summaryText = 'Generate formatted SOAP notes quickly from brief descriptions for efficient patient documentation.';
        examplesText = [
          'Generate a SOAP note for a 5-year-old female patient with abdominal pain.',
          'Create a follow-up plan for a patient with chronic kidney disease.',
          'Summarize the medical history for a new patient consultation.',
        ];
        break;
      default:
        greetingText = 'Welcome!';
        summaryText = '';
        examplesText = [];
    }
    setGreeting(greetingText);
    setSummary(summaryText);
    setExamples(examplesText);
  }, [screen, firstName]);

  useEffect(() => {
    if (examples.length === 0) return;

    let timeout;
    if (isDeleting) {
      timeout = setTimeout(() => {
        setDisplayedExample(current => current.slice(0, current.length - 1));
        setTypingSpeed(50);
      }, typingSpeed);
    } else {
      timeout = setTimeout(() => {
        setDisplayedExample(examples[exampleIndex].slice(0, displayedExample.length + 1));
        setTypingSpeed(150);
      }, typingSpeed);
    }

    if (!isDeleting && displayedExample === examples[exampleIndex]) {
      timeout = setTimeout(() => {
        setIsDeleting(true);
        setTypingSpeed(50);
      }, 2000);
    } else if (isDeleting && displayedExample === '') {
      setIsDeleting(false);
      setExampleIndex(prevIndex => (prevIndex + 1) % examples.length);
    }

    return () => clearTimeout(timeout);
  }, [examples, displayedExample, exampleIndex, isDeleting, typingSpeed]);

  return (
    <div className="greeting">
      <h1>{timeBasedGreeting}</h1>
      <p>{greeting}</p>
      <p>{summary}</p>
      {examples.length > 0 && (
        <>
          <hr /><br />
          <div className="examples">
            <span className="example-item">{displayedExample}</span>
            <span className="cursor"></span>
          </div>
        </>
      )}
    </div>
  );
}

export default Greeting;