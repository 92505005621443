import React, { useState } from 'react';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db, auth } from '../config/firebase';
import '../styles/QuickPickPopup.css';

function AddQuickPickPopup({ onClose, onSubmit }) {
  const [label, setLabel] = useState('');
  const [question, setQuestion] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Just call onSubmit with the label and question
    // Remove any direct Firestore operations from here
    onSubmit(label, question);
    onClose();
  };

  return (
    <div className="popup-overlay">
      <div className="popup">
        <h3>Add Quick Pick</h3>
        <form onSubmit={handleSubmit}>
          <div className="popup-content">
            <div className="popup-fields">
              <input
                type="text"
                placeholder="Label"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                required
              />
            </div>
            <div className="popup-buttons">
              <button type="button" onClick={onClose} className="popup-button cancel-button">
                Cancel
              </button>
              <button type="submit" className="popup-button primary-button">
                Add
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddQuickPickPopup;
