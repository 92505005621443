import React, { useEffect, useState, useCallback } from 'react';
import '../styles/AccountPopup.css';
import { auth, db } from '../config/firebase';
import { signOut } from "firebase/auth";
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { sendPasswordResetEmail } from 'firebase/auth';
import PrimerSettings from './SettingsFunctionality/PrimerSettings';
import ScribeSettings from './SettingsFunctionality/ScribeSettings';

const AccountPopup = ({ user, onClose, isOpen, refreshFirestoreData }) => {
  const [activeSection, setActiveSection] = useState('account');
  const [userData, setUserData] = useState(null);
  const [clinicData, setClinicData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedUserData, setEditedUserData] = useState({});
  const [editedClinicData, setEditedClinicData] = useState({});
  const [width, setWidth] = useState(550);
  const [isDragging, setIsDragging] = useState(false);
  const [saveMessage, setSaveMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const storedData = localStorage.getItem('userData');
        if (storedData) {
          const parsedData = JSON.parse(storedData);
          const userData = parsedData.user;
          setUserData(userData);
          setEditedUserData(userData);
          
          if (userData.clinicId) {
            const clinicData = {
              clinicName: userData.clinicName,
              primaryContactName: userData.primaryContactName,
              city: userData.city,
              state: userData.state,
              zipCode: userData.zipCode,
              phoneNumber: userData.phoneNumber,
              numberOfDoctors: userData.numberOfDoctors,
              subscriptionStatus: userData.subscriptionStatus,
            };
            setClinicData(clinicData);
            setEditedClinicData(clinicData);
          }
        }
      }
    };

    fetchUserData();
  }, [user]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      onClose();
    } catch (error) {
      console.error(error.message);
    }
  };

  const handlePasswordReset = async () => {
    if (user && user.email) {
      try {
        await sendPasswordResetEmail(auth, user.email);
        alert('Password reset email sent!');
      } catch (error) {
        console.error('Error sending password reset email:', error);
      }
    }
  };

  const handleInputChange = (e, setState, state) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const saveChanges = async () => {
    try {
      const clinicId = userData.clinicId;
      console.log('🔍 Current Clinic ID:', clinicId);
      
      if (!clinicId) {
        throw new Error('No clinic ID found');
      }

      // Log the Firestore path
      const firestorePath = `clinics/${clinicId}/users/${user.uid}`;
      console.log('📍 Firestore Path:', firestorePath);
      const userRef = doc(db, firestorePath);
      
      // Prepare the update data
      const updateData = {
        firstName: editedUserData.firstName,
        lastName: editedUserData.lastName,
        email: editedUserData.email,
        role: editedUserData.role,
      };

      console.log('👤 Basic User Data to Update:', updateData);

      // If user is account owner, add clinic data
      if (userData.role === 'account owner') {
        const clinicUpdateData = {
          clinicName: editedClinicData.clinicName,
          primaryContactName: editedClinicData.primaryContactName,
          city: editedClinicData.city,
          state: editedClinicData.state,
          zipCode: editedClinicData.zipCode,
          phoneNumber: editedClinicData.phoneNumber,
          numberOfDoctors: editedClinicData.numberOfDoctors,
          subscriptionStatus: editedClinicData.subscriptionStatus,
        };
        
        console.log('🏥 Clinic Data to Update:', clinicUpdateData);
        Object.assign(updateData, clinicUpdateData);
      }

      console.log('📤 Final Update Data:', updateData);

      // Update Firestore
      console.log('🔄 Attempting Firestore update...');
      await updateDoc(userRef, updateData);
      console.log('✅ Firestore update successful');

      // Update localStorage
      const storedData = JSON.parse(localStorage.getItem('userData'));
      const updatedData = {
        ...storedData,
        user: {
          ...storedData.user,
          ...updateData
        }
      };
      localStorage.setItem('userData', JSON.stringify(updatedData));
      console.log('💾 LocalStorage updated:', updatedData);

      // Refresh the data
      if (refreshFirestoreData) {
        console.log('🔄 Refreshing Firestore data...');
        await refreshFirestoreData();
        console.log('✅ Firestore data refresh complete');
      }

      setUserData(editedUserData);
      if (userData.role === 'account owner') {
        setClinicData(editedClinicData);
      }

      setIsEditing(false);
      setSaveMessage('Changes saved successfully');
      
      // Clear the message after 3 seconds
      setTimeout(() => {
        setSaveMessage('');
      }, 3000);
      
    } catch (error) {
      console.error('❌ Error saving changes:', error);
      console.error('Error details:', {
        message: error.message,
        stack: error.stack,
        userData: userData,
        editedUserData: editedUserData,
        editedClinicData: editedClinicData
      });
      setSaveMessage(`Error: ${error.message}`);
    }
  };

  const handleAccountManagement = () => {
    window.location.href = '/account-settings';
  };

  const toggleEditMode = () => {
    if (isEditing) {
      // Revert changes
      setEditedUserData(userData);
      setEditedClinicData(clinicData);
    }
    setIsEditing(!isEditing);
  };

  const handleClose = async () => {
    if (refreshFirestoreData) {
      try {
        await refreshFirestoreData();
        // Force a reload of the page to ensure all data is fresh
        window.location.reload();
      } catch (error) {
        console.error('Error refreshing data:', error);
      }
    }
    onClose();
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleMouseMove = useCallback((e) => {
    if (isDragging) {
      const newWidth = window.innerWidth - e.clientX;
      setWidth(Math.max(550, Math.min(newWidth, window.innerWidth - 100)));
    }
  }, [isDragging]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const renderAccountSection = () => (
    <div className="account-info">
      <div className="card">
        <h3>User Information</h3>
        <div className="info-group">
          <div className="info-item">
            <label>First Name:</label>
            {isEditing ? (
              <input
                type="text"
                name="firstName"
                value={editedUserData.firstName}
                onChange={(e) => handleInputChange(e, setEditedUserData, editedUserData)}
              />
            ) : (
              <p>{userData.firstName}</p>
            )}
          </div>
          <div className="info-item">
            <label>Last Name:</label>
            {isEditing ? (
              <input
                type="text"
                name="lastName"
                value={editedUserData.lastName}
                onChange={(e) => handleInputChange(e, setEditedUserData, editedUserData)}
              />
            ) : (
              <p>{userData.lastName}</p>
            )}
          </div>
          <div className="info-item">
            <label>Email:</label>
            {isEditing ? (
              <input
                type="email"
                name="email"
                value={editedUserData.email}
                onChange={(e) => handleInputChange(e, setEditedUserData, editedUserData)}
              />
            ) : (
              <p>{userData.email}</p>
            )}
          </div>
          <div className="info-item">
            <label>Role:</label>
            {isEditing ? (
              <input
                type="text"
                name="role"
                value={editedUserData.role}
                onChange={(e) => handleInputChange(e, setEditedUserData, editedUserData)}
              />
            ) : (
              <p>{userData.role}</p>
            )}
          </div>
        </div>
      </div>
      
      {clinicData && userData?.role === 'account owner' && (
        <div className="card">
          <h3>Clinic Information</h3>
          <div className="info-group">
            <div className="info-item">
              <label>Clinic Name:</label>
              {isEditing ? (
                <input
                  type="text"
                  name="clinicName"
                  value={editedClinicData.clinicName}
                  onChange={(e) => handleInputChange(e, setEditedClinicData, editedClinicData)}
                />
              ) : (
                <p>{clinicData.clinicName}</p>
              )}
            </div>
            <div className="info-item">
              <label>Primary Contact:</label>
              {isEditing ? (
                <input
                  type="text"
                  name="primaryContactName"
                  value={editedClinicData.primaryContactName}
                  onChange={(e) => handleInputChange(e, setEditedClinicData, editedClinicData)}
                />
              ) : (
                <p>{clinicData.primaryContactName}</p>
              )}
            </div>
            <div className="info-item">
              <label>City:</label>
              {isEditing ? (
                <input
                  type="text"
                  name="city"
                  value={editedClinicData.city}
                  onChange={(e) => handleInputChange(e, setEditedClinicData, editedClinicData)}
                />
              ) : (
                <p>{clinicData.city}</p>
              )}
            </div>
            <div className="info-item">
              <label>State:</label>
              {isEditing ? (
                <input
                  type="text"
                  name="state"
                  value={editedClinicData.state}
                  onChange={(e) => handleInputChange(e, setEditedClinicData, editedClinicData)}
                />
              ) : (
                <p>{clinicData.state}</p>
              )}
            </div>
            <div className="info-item">
              <label>Zip Code:</label>
              {isEditing ? (
                <input
                  type="text"
                  name="zipCode"
                  value={editedClinicData.zipCode}
                  onChange={(e) => handleInputChange(e, setEditedClinicData, editedClinicData)}
                />
              ) : (
                <p>{clinicData.zipCode}</p>
              )}
            </div>
            <div className="info-item">
              <label>Phone Number:</label>
              {isEditing ? (
                <input
                  type="text"
                  name="phoneNumber"
                  value={editedClinicData.phoneNumber}
                  onChange={(e) => handleInputChange(e, setEditedClinicData, editedClinicData)}
                />
              ) : (
                <p>{clinicData.phoneNumber}</p>
              )}
            </div>
            <div className="info-item">
              <label>Number of Doctors:</label>
              {isEditing ? (
                <input
                  type="text"
                  name="numberOfDoctors"
                  value={editedClinicData.numberOfDoctors}
                  onChange={(e) => handleInputChange(e, setEditedClinicData, editedClinicData)}
                />
              ) : (
                <p>{clinicData.numberOfDoctors}</p>
              )}
            </div>
            <div className="info-item">
              <label>Subscription Status:</label>
              {isEditing ? (
                <input
                  type="text"
                  name="subscriptionStatus"
                  value={editedClinicData.subscriptionStatus}
                  onChange={(e) => handleInputChange(e, setEditedClinicData, editedClinicData)}
                />
              ) : (
                <p>{clinicData.subscriptionStatus}</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const renderPrimerSection = () => (
    <div className="settings-section">
      <div className="card">
        <h3>Primer Settings</h3>
        <p>Primer settings and configurations will be available here.</p>
      </div>
    </div>
  );

  const renderScribeSection = () => (
    <ScribeSettings user={user} />
  );

  const saveMessageElement = (
    saveMessage && (
      <div className={`save-message ${saveMessage.includes('Error') ? 'error' : 'success'}`}>
        {saveMessage}
      </div>
    )
  );

  return (
    <div className={`account-popup-overlay ${isOpen ? 'open' : ''}`} onClick={handleClose}>
      <div 
        className={`account-popup ${isOpen ? 'open' : ''} ${isDragging ? 'dragging' : ''}`}
        onClick={(e) => e.stopPropagation()}
        style={{ width: `${width}px` }}
      >
        <div 
          className="resize-handle"
          onMouseDown={handleMouseDown}
        ></div>
        <div className="header">
          <h2>Settings</h2>
          <button className="settings-close-button" onClick={handleClose}>Close</button>
        </div>

        <div className="settings-nav">
          <ul>
            <li 
              className={activeSection === 'account' ? 'active' : ''} 
              onClick={() => setActiveSection('account')}
            >
              Account
            </li>
            <li 
              className={activeSection === 'primer' ? 'active' : ''} 
              onClick={() => setActiveSection('primer')}
            >
              Primer
            </li>
            <li 
              className={activeSection === 'scribe' ? 'active' : ''} 
              onClick={() => setActiveSection('scribe')}
            >
              Scribe
            </li>
          </ul>
        </div>

        <div className="settings-content">
          {userData ? (
            <>
              {activeSection === 'account' && (
                <div className="card">
                  <div className="card-header">
                    <h3>User Information</h3>
                    <div className="button-group">
                      {isEditing ? (
                        <>
                          <button className="button button-primary" onClick={saveChanges}>Save</button>
                          <button className="button button-secondary" onClick={toggleEditMode}>Cancel</button>
                        </>
                      ) : (
                        <button className="button button-primary" onClick={toggleEditMode}>Edit</button>
                      )}
                    </div>
                  </div>
                  <div className="info-group">
                    <div className="info-item">
                      <label>First Name:</label>
                      {isEditing ? (
                        <input
                          type="text"
                          name="firstName"
                          value={editedUserData.firstName}
                          onChange={(e) => handleInputChange(e, setEditedUserData, editedUserData)}
                        />
                      ) : (
                        <p>{userData.firstName}</p>
                      )}
                    </div>
                    <div className="info-item">
                      <label>Last Name:</label>
                      {isEditing ? (
                        <input
                          type="text"
                          name="lastName"
                          value={editedUserData.lastName}
                          onChange={(e) => handleInputChange(e, setEditedUserData, editedUserData)}
                        />
                      ) : (
                        <p>{userData.lastName}</p>
                      )}
                    </div>
                    <div className="info-item">
                      <label>Email:</label>
                      {isEditing ? (
                        <input
                          type="email"
                          name="email"
                          value={editedUserData.email}
                          onChange={(e) => handleInputChange(e, setEditedUserData, editedUserData)}
                        />
                      ) : (
                        <p>{userData.email}</p>
                      )}
                    </div>
                    <div className="info-item">
                      <label>Role:</label>
                      {isEditing ? (
                        <input
                          type="text"
                          name="role"
                          value={editedUserData.role}
                          onChange={(e) => handleInputChange(e, setEditedUserData, editedUserData)}
                        />
                      ) : (
                        <p>{userData.role}</p>
                      )}
                    </div>
                  </div>
                  {saveMessageElement}
                </div>
              )}
              {activeSection === 'primer' && <PrimerSettings />}
              {activeSection === 'scribe' && renderScribeSection()}
            </>
          ) : (
            <p>Loading...</p>
          )}
        </div>

        <div className="footer">
          <div className="action-links">
            {activeSection === 'account' && (
              <>
                <a href="#" className="action-link" onClick={handlePasswordReset}>Reset Password</a>
                {userData?.isAdmin && (
                  <a href="#" className="action-link" onClick={handleAccountManagement}>Manage Account</a>
                )}
                <a href="#" className="action-link" onClick={handleSignOut}>Sign Out</a>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPopup;