import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
  useMemo,
} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./config/firebase";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { AuthProvider } from "./contexts/AuthContext";
import { useDoctorStore } from "./stores/doctorStore";
import Login from "./pages/LoginScreen";
import ProtectedRoute from "./components/ProtectedRoute";
import PatientPrimer from "./components/PatientPrimer/PatientPrimer";
import Scribe from "./pages/Scribe";
import LoadingScreen from "./components/LoadingScreen";
import { ConversationProvider } from "./components/ConversationContext";
import ReleaseNotes from "./pages/ReleaseNotes";
import TopicOfWeek from "./pages/TopicOfWeek";
import { DoctorProvider } from "./contexts/DoctorContext";
import AccountSettings from "./pages/AccountSettings";
import RegisterWithInvite from "./pages/RegisterWithInvite";

const UserContext = createContext<any>(null);
export const useUser = () => useContext(UserContext);

// Type definitions
interface ClinicData {
  clinicId: string;
  [key: string]: any;
}

interface UserSettings {
  appointmentTypes: { [key: string]: any };
  quickPicks: { [key: string]: any };
  scribe: { [key: string]: any };
}

interface ConsolidatedData {
  user: {
    uid: string;
    clinic: ClinicData | null;
    settings: UserSettings;
    missionBrief: { [key: string]: any };
    isAdmin?: boolean;
    [key: string]: any;
  };
}

const App: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [firstName, setFirstName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [defaultsLoaded, setDefaultsLoaded] = useState<boolean>(false);
  const [refreshTrigger, setRefreshTrigger] = useState<number>(0);
  const [isDataReady, setIsDataReady] = useState<boolean>(false);
  const [isInitialAuthCheck, setIsInitialAuthCheck] = useState(true);
  const [lastAuthTime, setLastAuthTime] = useState<number>(0);

  const { selectedDoctor, initializeDefaultDoctor } = useDoctorStore();

  const refreshFirestoreData = useCallback(async () => {
    if (!user) {
      console.log("❌ No user object available");
      return;
    }

    try {
      console.log("🔍 Searching for user:", user.uid);

      // Query all subcollections named 'users' within clinics collection
      const clinicsRef = collection(db, "clinics");
      const clinicsSnapshot = await getDocs(clinicsRef);

      let clinicId = null;
      let clinicUserDoc = null;

      // Iterate through each clinic to find the user
      for (const clinic of clinicsSnapshot.docs) {
        const userRef = doc(db, `clinics/${clinic.id}/users/${user.uid}`);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          clinicId = clinic.id;
          clinicUserDoc = userDoc;
          console.log("✅ Found user in clinic:", clinicId);
          break;
        }
      }

      if (!clinicId || !clinicUserDoc) {
        console.error("❌ User not found in any clinic");
        return;
      }

      console.log("1️⃣ Clinic user data:", clinicUserDoc.data());

      // After finding the clinic, check if user is admin
      const clinicRef = doc(db, `clinics/${clinicId}`);
      const clinicDoc = await getDoc(clinicRef);
      const admins = clinicDoc.data()?.admins || [];
      const isAdmin = admins.includes(user.uid);

      // Get settings from the nested collections
      const settingsPath = `clinics/${clinicId}/users/${user.uid}/settings`;

      // Get scribe settings
      const scribeSettingsRef = doc(db, `${settingsPath}/scribeSettings`);
      const scribeSettingsDoc = await getDoc(scribeSettingsRef);
      console.log("2️⃣ Scribe settings:", scribeSettingsDoc.data());

      // Get global templates
      const globalTemplatesRef = doc(db, "globalAssets/scribeTemplates");
      const globalTemplatesDoc = await getDoc(globalTemplatesRef);
      const globalTemplates = globalTemplatesDoc.data();
      console.log("3️⃣ Global templates:", globalTemplates);
      // Determine which templates to use based on user preferences
      const scribeSettings = scribeSettingsDoc.data() || {};
      const finalScribeSettings = {
        soapTemplate: scribeSettings.usingSoapNoteDefaultTemplate
          ? globalTemplates?.soap || ""
          : scribeSettings.personalSoapNoteTemplate || "",
        clientDischargeTemplate:
          scribeSettings.usingClientDischargeDefaultTemplate
            ? globalTemplates?.clientDischarge || ""
            : scribeSettings.personalDischargeTemplate || "",
        soapNoteAdditions: scribeSettings.soapNoteAdditions || "",
        clientDischargeAdditions: scribeSettings.clientDischargeAdditions || "",
      };
      console.log("4️⃣ Final scribe settings:", finalScribeSettings);

      // Get settings and appointment types
      const appointmentTypesRef = collection(
        db,
        `${settingsPath}/missionBrief/appointmentTypes`
      );
      const appointmentTypesSnapshot = await getDocs(appointmentTypesRef);
      const appointmentTypes = Object.fromEntries(
        appointmentTypesSnapshot.docs.map((doc) => [doc.id, doc.data()])
      );

      // Get quick picks from the correct path
      const quickPicksRef = doc(
        db,
        `clinics/${clinicId}/users/${user.uid}/settings/quickPicks`
      );
      const quickPicksDoc = await getDoc(quickPicksRef);
      console.log("4️⃣ Quick picks:", quickPicksDoc.data()?.quickPicks || []);

      // New mission brief fetching logic
      const missionBriefRef = collection(
        db,
        `clinics/${clinicId}/missionBrief`
      );
      const doctorsSnapshot = await getDocs(missionBriefRef);

      // Initialize the mission brief data structure
      const missionBriefData: { [key: string]: any } = {};

      // Process each doctor's appointments
      for (const doctorDoc of doctorsSnapshot.docs) {
        const doctorId = doctorDoc.id;
        const appointmentsRef = collection(doctorDoc.ref, "appointments");
        const appointmentsSnapshot = await getDocs(appointmentsRef);

        // Transform appointments into array format
        const appointments = appointmentsSnapshot.docs.map((doc) => {
          const aptData = doc.data();
          return {
            appointmentTime: aptData.appointmentTime,
            appointmentType: aptData.appointmentType,
            duration: aptData.duration,
            ...(aptData.patientData && { patientData: aptData.patientData }),
          };
        });

        // Sort appointments by time
        appointments.sort((a, b) => {
          const timeA = a.appointmentTime.toDate().getTime();
          const timeB = b.appointmentTime.toDate().getTime();
          return timeA - timeB;
        });

        // Add to mission brief data structure
        missionBriefData[doctorId] = {
          resourceName: doctorDoc.data().name || "Unknown",
          appointments,
        };
      }

      // Process appointment colors (existing code)
      if (missionBriefData) {
        Object.keys(missionBriefData).forEach((doctorId) => {
          if (missionBriefData[doctorId]?.appointments) {
            missionBriefData[doctorId].appointments = missionBriefData[
              doctorId
            ].appointments.map((apt: any) => {
              if (apt.appointmentType) {
                const trimmedAppointmentType = apt.appointmentType.trim();

                for (const [groupName, settings] of Object.entries(
                  appointmentTypes
                )) {
                  if (
                    settings.subTypes?.some(
                      (subType: string) =>
                        subType.trim() === trimmedAppointmentType
                    )
                  ) {
                    apt.appointmentColor = settings.color || "#A5B5BD";
                    apt.appointmentMacroGroup = groupName;
                    break;
                  }
                }
                if (!apt.appointmentColor) {
                  apt.appointmentColor =
                    appointmentTypes.Other?.color || "#A5B5BD";
                  apt.appointmentMacroGroup = "Other";
                }
              }
              return apt;
            });
          }
        });
      }

      // Include isAdmin in consolidated data
      const consolidatedData = {
        user: {
          uid: user.uid,
          ...clinicUserDoc.data(),
          clinicId: clinicId,
          isAdmin: isAdmin,
          settings: {
            appointmentTypes,
            quickPicks: quickPicksDoc.data()?.quickPicks || [],
            scribe: finalScribeSettings,
          },
          missionBrief: missionBriefData || {},
        },
      };

      // Add error checking for consolidated data
      if (!consolidatedData?.user) {
        console.error(
          "❌ Consolidated data is missing user object:",
          consolidatedData
        );
        return;
      }

      // Add more verbose localStorage operations
      try {
        localStorage.setItem("userData", JSON.stringify(consolidatedData));

        const firstName = clinicUserDoc.data()?.firstName || "";
        localStorage.setItem("userFirstName", firstName);

        // Only initialize doctor if we don't have one
        if (!selectedDoctor) {
          initializeDefaultDoctor();
        }

        setRefreshTrigger((prev) => prev + 1);
      } catch (storageError) {
        console.error("❌ Error saving to localStorage:", storageError);
      }
    } catch (error) {
      console.error("❌ Error loading data:", error);
      if (error instanceof Error) {
        console.error("Error message:", error.message);
        console.error("Error stack:", error.stack);
      }
    } finally {
      setIsDataReady(true);
    }
  }, [user, selectedDoctor, initializeDefaultDoctor]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      const now = Date.now();

      if (!currentUser) {
        setUser(null);
        setFirstName("");
        localStorage.removeItem("userFirstName");
        localStorage.removeItem("userData");
        setDefaultsLoaded(true);
        setIsDataReady(true);
        setLoading(false);
        return;
      }

      // Set the user immediately
      setUser(currentUser);
      setLoading(true); // Ensure loading is true while we fetch data
      setIsDataReady(false);

      try {
        console.log("Starting data fetch for user:", currentUser.uid);
        await refreshFirestoreData();
        setLastAuthTime(now);
      } catch (error) {
        console.error("❌ Error in auth state change:", error);
      } finally {
        setIsDataReady(true);
        setLoading(false);
        setIsInitialAuthCheck(false);
      }
    });

    return () => unsubscribe();
  }, [refreshFirestoreData]); // Remove lastAuthTime from dependencies

  const isFullyLoaded = useMemo(() => {
    const hasDoctor = selectedDoctor || !user;
    const isReady = !loading && isDataReady;
    return isReady && hasDoctor;
  }, [loading, isDataReady, selectedDoctor, user]);

  return (
    <DoctorProvider>
      <UserContext.Provider value={{ user, firstName }}>
        <Router>
          <AuthProvider>
            <ConversationProvider>
              <Routes>
                {/* Public routes */}
                <Route path="/login" element={<Login />} />

                {/* Protected routes */}
                <Route
                  path="/account-settings"
                  element={
                    <ProtectedRoute>
                      <AccountSettings />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/PatientPrimer"
                  element={
                    <ProtectedRoute>
                      {loading || !isDataReady ? (
                        <LoadingScreen />
                      ) : (
                        <PatientPrimer
                          refreshFirestoreData={refreshFirestoreData}
                          refreshTrigger={refreshTrigger}
                        />
                      )}
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/Scribe"
                  element={
                    <ProtectedRoute>
                      {loading || !isDataReady ? (
                        <LoadingScreen />
                      ) : (
                        <Scribe
                          refreshFirestoreData={refreshFirestoreData}
                          refreshTrigger={refreshTrigger}
                        />
                      )}
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/beacon/topic"
                  element={
                    <ProtectedRoute>
                      {loading || !isDataReady ? (
                        <LoadingScreen />
                      ) : (
                        <TopicOfWeek />
                      )}
                    </ProtectedRoute>
                  }
                />

                <Route path="/release-notes" element={<ReleaseNotes />} />

                <Route path="/register" element={<RegisterWithInvite />} />

                {/* Redirect root to login */}
                <Route path="/" element={<Navigate to="/login" replace />} />

                {/* Catch all route - redirect to login */}
                <Route path="*" element={<Navigate to="/login" replace />} />
              </Routes>
            </ConversationProvider>
          </AuthProvider>
        </Router>
      </UserContext.Provider>
    </DoctorProvider>
  );
};

export default App;
