import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { FaMicrophone, FaStop, FaTrash } from "react-icons/fa";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  FirebaseStorage,
} from "firebase/storage";
import { doc, updateDoc, getDoc, Firestore } from "firebase/firestore";
import { Auth } from "firebase/auth";
import "../styles/MedicalScribe.css";
import { LiveAudioVisualizer, AudioVisualizer } from "react-audio-visualize";
import { audioStorage } from "../services/AudioStorageService";
import { useDoctorStore } from "../stores/doctorStore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Patient } from "../stores/patientStore";
import HistoricalNote from "./HistoricalNote";

const functions = getFunctions();
const assemblysoap = httpsCallable(functions, "assemblysoap", {
  timeout: 540000, // 9 minutes timeout
});

interface MedicalScribeProps {
  soapMode: "spoken" | "written";
  auth: Auth;
  storage: FirebaseStorage;
  db: Firestore;
  selectedPatient: Patient | null;
  currentStaticInfo?: StaticInfo | null;
  refreshFirestoreData: () => Promise<void>;
  onStartNote?: () => void;
  onRecordingComplete?: () => Promise<void>;
  selectedDate?: string;
}

interface StaticInfo {
  formattedDate: string;
  time: string;
  patientName: string;
  patientBreed: string;
}

interface DoctorData {
  value: string;
  label: string;
  // Add other doctor properties as needed
}

interface ScribeSettings {
  clientDischargeAdditions: string;
  clientDischargeTemplate: string;
  soapTemplate: string;
  soapNoteAdditions: string;
}

// Add this type near your other interfaces
type AssemblySoapResponse = {
  data: string;
};

interface NoteData {
  staging?: boolean;
  draftSOAP?: string;
  clientDischarge?: boolean;
}

const MedicalScribe = forwardRef<any, MedicalScribeProps>(
  (
    {
      soapMode,
      auth,
      storage,
      db,
      selectedPatient,
      currentStaticInfo,
      onStartNote,
      onRecordingComplete,
      selectedDate,
    },
    forwardedRef
  ) => {
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [writtenInput, setWrittenInput] = useState<string>("");
    const [additionalDetails, setAdditionalDetails] = useState<string>("");
    const [showTrashIcon, setShowTrashIcon] = useState<boolean>(false);
    const [showGenerateNote, setShowGenerateNote] = useState<boolean>(false);
    const [hasStoppedRecording, setHasStoppedRecording] =
      useState<boolean>(false);
    const [hasInteractedWithTextArea, setHasInteractedWithTextArea] =
      useState<boolean>(false);
    const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
    const [generatedSOAP, setGeneratedSOAP] = useState<string>("");
    const [elapsedTime, setElapsedTime] = useState<number>(0);
    const [accumulatedTime, setAccumulatedTime] = useState<number>(0);
    const [noteTitle, setNoteTitle] = useState<string>("");
    const [showInputs, setShowInputs] = useState<boolean>(true);
    const [isEditingSOAP, setIsEditingSOAP] = useState<boolean>(false);
    const [editedSOAP, setEditedSOAP] = useState<string>("");
    const [copyButtonText, setCopyButtonText] = useState<string>("Copy Text");
    const [isNoteEdited, setIsNoteEdited] = useState<boolean>(false);
    const [docId, setDocId] = useState<string | null>(null);
    const [selectedPatientData, setSelectedPatientData] =
      useState<Patient | null>(null);
    const [selectedDoctorData, setSelectedDoctorData] =
      useState<DoctorData | null>(null);
    const [isMicrophoneTesting, setIsMicrophoneTesting] =
      useState<boolean>(false);
    const [audioLevel, setAudioLevel] = useState<number>(0);
    const [isMicrophoneWorking, setIsMicrophoneWorking] =
      useState<boolean>(true);
    const [microphoneTestRecorder, setMicrophoneTestRecorder] =
      useState<MediaRecorder | null>(null);
    const [generateProgress, setGenerateProgress] = useState<number>(0);
    const [isInitialized, setIsInitialized] = useState(false);
    const [existingNote, setExistingNote] = useState<NoteData | null>(null);
    const [showNoteInputs, setShowNoteInputs] = useState<{
      [key: string]: boolean;
    }>({});
    const [hasExistingRecordings, setHasExistingRecordings] = useState(false);

    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const timerIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const streamRef = useRef<MediaStream | null>(null);
    const startTimeRef = useRef<number | null>(null);

    const doctorStore = useDoctorStore();

    const [generatingPatients, setGeneratingPatients] = useState<{
      [key: string]: boolean;
    }>({});
    const [generateProgressMap, setGenerateProgressMap] = useState<{
      [key: string]: number;
    }>({});

    // Add state maps for per-patient data
    const [patientInputs, setPatientInputs] = useState<{
      [key: string]: string;
    }>({});
    const [patientAdditionalDetails, setPatientAdditionalDetails] = useState<{
      [key: string]: string;
    }>({});
    const [patientShowInputs, setPatientShowInputs] = useState<{
      [key: string]: boolean;
    }>({});
    const [patientGeneratedSOAP, setPatientGeneratedSOAP] = useState<{
      [key: string]: string;
    }>({});
    const [patientEditedSOAP, setPatientEditedSOAP] = useState<{
      [key: string]: string;
    }>({});

    // Helper functions to get current patient's data
    const getCurrentInput = () => {
      return selectedPatient?.id ? patientInputs[selectedPatient.id] || "" : "";
    };

    const getCurrentAdditionalDetails = () => {
      return selectedPatient?.id
        ? patientAdditionalDetails[selectedPatient.id] || ""
        : "";
    };

    const getCurrentShowInputs = () => {
      return selectedPatient?.id
        ? patientShowInputs[selectedPatient.id] !== false
        : true;
    };

    const isCurrentPatientGenerating = () => {
      return selectedPatient?.id
        ? generatingPatients[selectedPatient.id]
        : false;
    };

    const getCurrentPatientProgress = () => {
      return selectedPatient?.id
        ? generateProgressMap[selectedPatient.id] || 0
        : 0;
    };

    const simulateProgress = (patientId: string) => {
      setGenerateProgressMap((prev) => ({ ...prev, [patientId]: 0 }));

      const interval = setInterval(() => {
        setGenerateProgressMap((prev) => {
          const currentProgress = prev[patientId] || 0;
          if (currentProgress >= 97) {
            clearInterval(interval);
            return { ...prev, [patientId]: 92 };
          }
          const increment =
            currentProgress < 30
              ? 0.3
              : currentProgress < 60
              ? 0.5
              : currentProgress < 90
              ? 0.2
              : 0.1;
          return { ...prev, [patientId]: currentProgress + increment };
        });
      }, 250);

      return interval;
    };

    useEffect(() => {
      const storedPatientStr = localStorage.getItem("selectedPatient");
      if (storedPatientStr) {
        const storedPatient = JSON.parse(storedPatientStr);
        setSelectedPatientData(storedPatient);
      }
    }, []);

    useEffect(() => {
      const savedDoctorStr = localStorage.getItem("selectedDoctor");
      if (savedDoctorStr) {
        const savedDoctor = JSON.parse(savedDoctorStr);
        setSelectedDoctorData(savedDoctor);
      }
    }, []);

    useImperativeHandle(forwardedRef, () => ({
      handleDiscard,
    }));

    useEffect(() => {
      if (selectedPatient) {
        setSelectedPatientData(selectedPatient); // Update patient data when selectedPatient changes
      }
    }, [selectedPatient]);

    useEffect(() => {
      return () => {
        if (timerIntervalRef.current) {
          clearInterval(timerIntervalRef.current);
        }
        if (streamRef.current) {
          streamRef.current.getTracks().forEach((track) => track.stop());
        }
      };
    }, []);

    useEffect(() => {
      setDefaultTitle();
    }, [selectedPatient, currentStaticInfo]);

    useEffect(() => {
      const checkForExistingNote = async () => {
        if (!selectedPatient?.id) return;

        // If there's a note currently being generated for this patient,
        // don't check for existing notes
        if (generatingPatients[selectedPatient.id]) {
          setShowNoteInputs((prev) => ({
            ...prev,
            [selectedPatient.id]: true,
          }));
          setPatientShowInputs((prev) => ({
            ...prev,
            [selectedPatient.id]: true,
          }));
          setShowInputs(true);
          return;
        }

        const userId = auth.currentUser?.uid;
        if (!userId) return;

        const userData = JSON.parse(localStorage.getItem("userData") || "{}");
        const clinicId = userData?.user?.clinicId;
        if (!clinicId) {
          console.error("No clinic ID found");
          return;
        }

        const pimsId = selectedPatient.pims_id || selectedPatient.id;
        const currentDate = getCurrentDateString();
        try {
          const noteRef = doc(
            db,
            "clinics",
            clinicId,
            "users",
            userId,
            "scribe",
            currentDate,
            "dailyNotes",
            pimsId
          );

          const noteDoc = await getDoc(noteRef);
          if (noteDoc.exists()) {
            const noteData = noteDoc.data() as NoteData;
            if (
              noteData.staging === false ||
              noteData.draftSOAP ||
              noteData.clientDischarge
            ) {
              setExistingNote(noteData);

              // Only show historical note if there's no generation in progress
              if (noteData.staging === false && noteData.draftSOAP) {
                setShowNoteInputs((prev) => ({
                  ...prev,
                  [selectedPatient.id]: false,
                }));
                setPatientShowInputs((prev) => ({
                  ...prev,
                  [selectedPatient.id]: false,
                }));
                setShowInputs(false);
              }
            } else {
              setExistingNote(null);
              setShowNoteInputs((prev) => ({
                ...prev,
                [selectedPatient.id]: true,
              }));
              setPatientShowInputs((prev) => ({
                ...prev,
                [selectedPatient.id]: true,
              }));
              setShowInputs(true);
            }
          } else {
            setExistingNote(null);
            setShowNoteInputs((prev) => ({
              ...prev,
              [selectedPatient.id]: true,
            }));
            setPatientShowInputs((prev) => ({
              ...prev,
              [selectedPatient.id]: true,
            }));
            setShowInputs(true);
          }
        } catch (error) {
          console.error(
            "[MedicalScribe] Error checking for existing note:",
            error
          );
        }
      };

      checkForExistingNote();
    }, [
      selectedPatient,
      auth.currentUser,
      db,
      selectedDate,
      generatingPatients,
    ]);

    const setDefaultTitle = () => {
      if (selectedPatient && currentStaticInfo) {
        setNoteTitle(
          `${selectedPatient.name} | ${currentStaticInfo.formattedDate} | ${currentStaticInfo.time}`
        );
      } else if (currentStaticInfo) {
        setNoteTitle(
          `Unknown | ${currentStaticInfo.formattedDate} | ${currentStaticInfo.time}`
        );
      } else {
        setNoteTitle("Unknown");
      }
    };

    const formatTime = (timeInSeconds: number): string => {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = Math.floor(timeInSeconds % 60);
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    };

    const updateElapsedTime = () => {
      if (startTimeRef.current) {
        const currentTime = Date.now();
        const delta = (currentTime - startTimeRef.current) / 1000;
        setElapsedTime(accumulatedTime + delta);
      }
    };

    useEffect(() => {
      audioStorage
        .initialize()
        .then(() => setIsInitialized(true))
        .catch((error) =>
          console.error("Failed to initialize audio storage:", error)
        );
    }, []);

    const handleRecordingToggle = async () => {
      if (!isRecording) {
        onStartNote?.();
      }
      if (isRecording && mediaRecorderRef.current) {
        // Stop recording
        mediaRecorderRef.current.stop();
        setIsRecording(false);
        setHasStoppedRecording(true);
        setShowTrashIcon(true);
        setShowGenerateNote(true);

        // Clear the timer interval
        if (timerIntervalRef.current) {
          clearInterval(timerIntervalRef.current);
          timerIntervalRef.current = null;
        }

        // Add current session time to accumulated time
        if (startTimeRef.current) {
          const currentTime = Date.now();
          const sessionTime = (currentTime - startTimeRef.current) / 1000;
          setAccumulatedTime((prev) => prev + sessionTime);
        }
        startTimeRef.current = null;

        // Save the recording chunk
        if (audioChunks.length > 0 && selectedPatient?.id) {
          try {
            const audioBlob = new Blob(audioChunks, {
              type: mediaRecorderRef.current.mimeType,
            });
            await audioStorage.saveRecording(
              selectedPatient.id,
              audioBlob,
              elapsedTime
            );
            setAudioChunks([]); // Clear chunks after saving

            // Add this line to call the callback after successful save
            await onRecordingComplete?.();
          } catch (error) {
            console.error("Failed to save recording:", error);
          }
        }

        // Stop stream tracks but don't reset accumulated time
        if (streamRef.current) {
          streamRef.current.getTracks().forEach((track) => track.stop());
          streamRef.current = null;
        }
        mediaRecorderRef.current = null;
      } else {
        // Start/Resume recording
        try {
          if (!streamRef.current) {
            const stream = await navigator.mediaDevices.getUserMedia({
              audio: true,
            });
            streamRef.current = stream;
          }

          const mimeType = getSupportedMimeType();
          const recorder = new MediaRecorder(streamRef.current, {
            mimeType: mimeType,
          });
          mediaRecorderRef.current = recorder;

          recorder.ondataavailable = async (e) => {
            setAudioChunks((prevChunks) => [...prevChunks, e.data]);
          };

          recorder.start(1000);
          setIsRecording(true);
          setShowTrashIcon(false);
          setShowGenerateNote(false);

          // Start timer from current accumulated time
          startTimeRef.current = Date.now();
          timerIntervalRef.current = setInterval(updateElapsedTime, 1000);
        } catch (error) {
          console.error("Error accessing the microphone:", error);
          setIsMicrophoneWorking(false);
          alert("An error occurred while trying to access your microphone.");
        }
      }
    };

    // Add this helper function to get supported MIME type
    const getSupportedMimeType = (): string => {
      const types = [
        "audio/webm",
        "audio/webm;codecs=opus",
        "audio/ogg;codecs=opus",
        "audio/mp4",
      ];

      for (const type of types) {
        if (MediaRecorder.isTypeSupported(type)) {
          return type;
        }
      }

      throw new Error("No supported audio MIME types found");
    };

    const handleInputFocus = () => {
      if (!hasInteractedWithTextArea) {
        onStartNote?.();
        setHasInteractedWithTextArea(true);
        setShowTrashIcon(true);
        setShowGenerateNote(true);
      }
    };

    const handleDiscard = async () => {
      if (!selectedPatient?.id) return;

      // Delete recordings if any exist
      if (selectedPatient?.id && hasStoppedRecording) {
        try {
          const currentTime = Date.now();
          // Delete all recordings from this session
          const recordingGroups = await audioStorage.getRecordingGroups(
            selectedPatient.id
          );
          for (const group of recordingGroups) {
            // Only delete recordings from the current session
            if (group.startTime >= startTimeRef.current!) {
              await audioStorage.deleteRecording(
                selectedPatient.id,
                group.startTime.toString()
              );
            }
          }
          // Trigger refresh of recordings UI
          await onRecordingComplete?.();
        } catch (error) {
          console.error("Error deleting recordings:", error);
        }
      }

      // Clear recording-related state
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
      setElapsedTime(0);
      setAccumulatedTime(0);
      setAudioChunks([]);
      setIsRecording(false);
      setHasStoppedRecording(false);
      startTimeRef.current = null;
      streamRef.current = null;
      mediaRecorderRef.current = null;

      // Clear patient-specific state
      setPatientInputs((prev) => ({ ...prev, [selectedPatient.id]: "" }));
      setPatientAdditionalDetails((prev) => ({
        ...prev,
        [selectedPatient.id]: "",
      }));
      setPatientShowInputs((prev) => ({ ...prev, [selectedPatient.id]: true }));
      setPatientGeneratedSOAP((prev) => ({
        ...prev,
        [selectedPatient.id]: "",
      }));
      setPatientEditedSOAP((prev) => ({ ...prev, [selectedPatient.id]: "" }));

      // Clear written input and related state
      setWrittenInput("");
      setAdditionalDetails("");
      setShowTrashIcon(false);
      setShowGenerateNote(false);
      setHasInteractedWithTextArea(false);

      // Clear any generated content
      setGeneratedSOAP("");
      setEditedSOAP("");
      setShowInputs(true);
      setIsNoteEdited(false);
    };

    const handleGenerateNote = async () => {
      if (!selectedPatient?.id) return;
      const currentPatientId = selectedPatient.id; // Store the ID of the patient we're generating for

      if (soapMode === "written" && !getCurrentInput().trim()) {
        alert("Please enter some text before generating a note.");
        return;
      }

      if (soapMode === "spoken") {
        // Check for both new recordings and existing recordings
        const hasExistingRecordings =
          selectedPatient?.id &&
          (await audioStorage.hasRecordings(selectedPatient.id));

        if (!hasStoppedRecording && !hasExistingRecordings) {
          alert("Please record some audio before generating a note.");
          return;
        }
      }

      setGeneratingPatients((prev) => ({
        ...prev,
        [selectedPatient.id]: true,
      }));
      const progressInterval = simulateProgress(selectedPatient.id);

      try {
        // Get clinic data from localStorage
        const userData = JSON.parse(localStorage.getItem("userData") || "{}");
        const clinicId = userData?.user?.clinicId;

        // Get scribe settings from userData
        const scribeSettings: ScribeSettings = {
          clientDischargeAdditions:
            userData?.user?.settings?.scribe?.clientDischargeAdditions || "",
          clientDischargeTemplate:
            userData?.user?.settings?.scribe?.clientDischargeTemplate || "",
          soapTemplate: userData?.user?.settings?.scribe?.soapTemplate || "",
          soapNoteAdditions:
            userData?.user?.settings?.scribe?.soapNoteAdditions || "",
        };

        if (!clinicId) {
          throw new Error("No clinic ID available in user data");
        }

        // Declare soapInput variable
        let soapInput: string;

        // Get doctor data directly from the store
        const selectedDoctor = doctorStore.selectedDoctor;
        if (!selectedDoctor?.value) {
          throw new Error("No doctor selected");
        }

        // Validate user authentication
        if (!auth.currentUser) {
          throw new Error("No authenticated user");
        }
        const userId = auth.currentUser.uid;

        // Validate patient data
        if (!selectedPatientData) {
          throw new Error("No patient data available");
        }
        const pims_id = selectedPatientData.pims_id?.toString();
        if (!pims_id) {
          throw new Error("Invalid patient ID");
        }
        setDocId(pims_id);
        console.log("SENDING selectedDate", selectedDate);
        if (soapMode === "written") {
          soapInput = getCurrentInput();
          if (!soapInput.trim()) {
            throw new Error("No input available for written SOAP note");
          }

          // Generate note using regular fetch
          const generatedNoteResponse = await fetch(
            "https://us-central1-lyravet-ac8ca.cloudfunctions.net/createWrittenSOAP",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                writtenNotes: soapInput,
                userId: userId,
                clinicId: clinicId,
                scribeSettings,
                patientData: {
                  pimsId: pims_id,
                  name: selectedPatientData.name || "Unknown",
                  breed: selectedPatientData.breed || "Unknown",
                  appointmentTime: selectedPatientData.appointmentTime,
                  appointmentType: selectedPatientData.appointmentType,
                },
                doctorData: selectedDoctor,
                additionalDetails: getCurrentAdditionalDetails(),
                today: selectedDate,
              }),
            }
          );

          if (!generatedNoteResponse.ok) {
            throw new Error(`API error: ${generatedNoteResponse.status}`);
          }

          // After successful generation, update staging to false
          const currentDate = getCurrentDateString();
          console.log("currentDate", currentDate);
          const pimsId = selectedPatientData.pims_id || selectedPatientData.id;

          const noteRef = doc(
            db,
            "clinics",
            clinicId,
            "users",
            userId,
            "scribe",
            currentDate,
            "dailyNotes",
            pimsId
          );
          await updateDoc(noteRef, {
            staging: false,
          });

          const noteDoc = await getDoc(noteRef);
          if (noteDoc.exists()) {
            const noteData = noteDoc.data();

            // Update patient-specific state with the note data
            setPatientGeneratedSOAP((prev) => ({
              ...prev,
              [currentPatientId]: noteData.draftSOAP || "",
            }));
            setPatientEditedSOAP((prev) => ({
              ...prev,
              [currentPatientId]: noteData.draftSOAP || "",
            }));
            setPatientShowInputs((prev) => ({
              ...prev,
              [currentPatientId]: false,
            }));

            // Only update UI state if we're still on the same patient
            if (selectedPatient?.id === currentPatientId) {
              setExistingNote(noteData);
              setShowNoteInputs((prev) => ({
                ...prev,
                [currentPatientId]: false,
              }));
              setShowInputs(false);
            }
          }

          // Complete progress animation
          clearInterval(progressInterval);
          await new Promise<void>((resolve) => {
            const startProgress = generateProgress;
            const steps = 20;
            const stepDuration = 30;
            let step = 0;

            const finishInterval = setInterval(() => {
              step++;
              const progress =
                startProgress +
                (100 - startProgress) * (1 - Math.pow(1 - step / steps, 3));
              setGenerateProgress(progress);

              if (step >= steps) {
                clearInterval(finishInterval);
                setGenerateProgress(100);
                resolve();
              }
            }, stepDuration);
          });

          setShowInputs(false);
          setIsNoteEdited(false);
        } else if (soapMode === "spoken" && selectedPatient?.id) {
          try {
            // Get all recordings for this patient
            const recordings = await audioStorage.getRecordings(
              selectedPatient.id
            );

            // Start progress simulation
            const progressInterval = simulateProgress(selectedPatient.id);
            let currentProgress = 0;

            try {
              // Upload each recording and get URLs
              const audioUrls = [];

              for (const recording of recordings) {
                const audioRef = storageRef(
                  storage,
                  `audio/${userId}/${selectedPatient.id}/${Date.now()}.wav`
                );
                await uploadBytes(audioRef, recording);
                const audioUrl = await getDownloadURL(audioRef);
                audioUrls.push(audioUrl);
              }

              const result = (await assemblysoap({
                audioFiles: audioUrls,
                userId: userId,
                clinicId: clinicId,
                scribeSettings,
                patientData: {
                  pimsId: pims_id,
                  name: selectedPatientData.name || "Unknown",
                  breed: selectedPatientData.breed || "Unknown",
                  appointmentTime: selectedPatientData.appointmentTime,
                  appointmentType: selectedPatientData.appointmentType,
                },
                doctorData: selectedDoctor,
                additionalDetails: getCurrentAdditionalDetails(),
                today: selectedDate,
              })) as AssemblySoapResponse;

              // After successful generation, update staging to false
              const currentDate = getCurrentDateString();
              console.log("currentDate", currentDate);
              const pimsId =
                selectedPatientData.pims_id || selectedPatientData.id;

              const noteRef = doc(
                db,
                "clinics",
                clinicId,
                "users",
                userId,
                "scribe",
                currentDate,
                "dailyNotes",
                pimsId
              );
              await updateDoc(noteRef, {
                staging: false,
              });

              const noteDoc = await getDoc(noteRef);
              if (noteDoc.exists()) {
                const noteData = noteDoc.data();

                // Update patient-specific state with the note data
                setPatientGeneratedSOAP((prev) => ({
                  ...prev,
                  [currentPatientId]: noteData.draftSOAP || "",
                }));
                setPatientEditedSOAP((prev) => ({
                  ...prev,
                  [currentPatientId]: noteData.draftSOAP || "",
                }));
                setPatientShowInputs((prev) => ({
                  ...prev,
                  [currentPatientId]: false,
                }));

                // Only update UI state if we're still on the same patient
                if (selectedPatient?.id === currentPatientId) {
                  setExistingNote(noteData);
                  setShowNoteInputs((prev) => ({
                    ...prev,
                    [currentPatientId]: false,
                  }));
                  setShowInputs(false);
                }
              }

              // Stop the progress simulation and get current value
              clearInterval(progressInterval);
              currentProgress = generateProgress;

              // Brief pause at current progress
              await new Promise((resolve) => setTimeout(resolve, 200));

              // Complete the progress smoothly
              await new Promise<void>((resolve) => {
                const startProgress = currentProgress;

                const steps = 20;
                const stepDuration = 30;
                let step = 0;

                const finishInterval = setInterval(() => {
                  step++;
                  const progress =
                    startProgress +
                    (100 - startProgress) * (1 - Math.pow(1 - step / steps, 3));
                  setGenerateProgress(progress);

                  if (step >= steps) {
                    clearInterval(finishInterval);
                    setGenerateProgress(100);
                    resolve();
                  }
                }, stepDuration);
              });

              // Small delay before showing the result
              await new Promise((resolve) => setTimeout(resolve, 100));

              setGeneratedSOAP(result.data);
              setEditedSOAP(result.data);
              setShowInputs(false);
              setIsNoteEdited(false);
            } catch (error) {
              console.error("[SCRIBE] Error during note generation:", error);
              clearInterval(progressInterval);
              throw error;
            }
          } catch (error) {
            console.error("[SCRIBE] Failed to process recordings:", error);
            throw error;
          }
        } else {
          throw new Error("No input available");
        }

        setGeneratingPatients((prev) => ({
          ...prev,
          [selectedPatient.id]: false,
        }));
        setGenerateProgressMap((prev) => ({
          ...prev,
          [selectedPatient.id]: 100,
        }));
      } catch (error) {
        setGeneratingPatients((prev) => ({
          ...prev,
          [selectedPatient.id]: false,
        }));
        setGenerateProgressMap((prev) => ({
          ...prev,
          [selectedPatient.id]: 0,
        }));
        clearInterval(progressInterval);
        console.error("Error generating SOAP note:", error);
        alert(
          `Error generating SOAP note: ${
            error instanceof Error ? error.message : "Unknown error"
          }`
        );
      }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (!selectedPatient?.id) return;
      setPatientInputs((prev) => ({
        ...prev,
        [selectedPatient.id]: e.target.value,
      }));
      if (e.target.value.trim() !== "") {
        setShowTrashIcon(true);
      }
    };

    const handleAdditionalDetailsChange = (
      e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      if (!selectedPatient?.id) return;
      setPatientAdditionalDetails((prev) => ({
        ...prev,
        [selectedPatient.id]: e.target.value,
      }));
    };

    const handleMicrophoneTest = async () => {
      try {
        if (isMicrophoneTesting) {
          if (microphoneTestRecorder) {
            microphoneTestRecorder.stop();
          }
          setIsMicrophoneTesting(false);
          setMicrophoneTestRecorder(null);
        } else {
          try {
            const stream = await navigator.mediaDevices.getUserMedia({
              audio: true,
            });
            const recorder = new MediaRecorder(stream);

            // Set up audio analysis
            const audioContext = new AudioContext();
            const audioSource = audioContext.createMediaStreamSource(stream);
            const analyser = audioContext.createAnalyser();
            analyser.fftSize = 256; // Smaller FFT size for more responsive readings
            audioSource.connect(analyser);

            // Monitor audio levels with adjusted threshold
            const checkAudioLevel = () => {
              const dataArray = new Uint8Array(analyser.frequencyBinCount);
              analyser.getByteFrequencyData(dataArray);

              // Calculate RMS value for more accurate level detection
              const rms = Math.sqrt(
                dataArray.reduce((sum, value) => sum + value * value, 0) /
                  dataArray.length
              );

              setAudioLevel(rms);
              // Lower threshold to 1 for more sensitive detection
              setIsMicrophoneWorking(rms > 1);

              if (isMicrophoneTesting) {
                requestAnimationFrame(checkAudioLevel);
              }
            };

            checkAudioLevel();
            recorder.start();
            setMicrophoneTestRecorder(recorder);
            setIsMicrophoneTesting(true);
          } catch (permissionError) {
            alert("Please allow microphone permission in extension settings");
            setIsMicrophoneWorking(false);
            return;
          }
        }
      } catch (error) {
        console.error("Error testing microphone:", error);
        setIsMicrophoneWorking(false);
      }
    };

    const getActiveRecorder = (): MediaRecorder | null => {
      if (isRecording && mediaRecorderRef.current) {
        return mediaRecorderRef.current;
      }
      if (isMicrophoneTesting && microphoneTestRecorder) {
        return microphoneTestRecorder;
      }
      return null;
    };

    const handleNewNote = () => {
      if (!selectedPatient?.id) return;

      // Toggle the note inputs for the current patient
      setShowNoteInputs((prev) => ({
        ...prev,
        [selectedPatient.id]: !prev[selectedPatient.id],
      }));

      // Toggle the patient's show inputs state
      setPatientShowInputs((prev) => ({
        ...prev,
        [selectedPatient.id]: !prev[selectedPatient.id],
      }));

      // Toggle the global show inputs state
      setShowInputs((prev) => !prev);
    };

    const getCurrentDateString = () => {
      if (selectedDate) return selectedDate;
      return new Date().toLocaleDateString("en-CA");
    };

    // Add this effect to handle patient changes
    useEffect(() => {
      // Reset recording-related UI states when patient changes
      setIsRecording(false);
      setHasStoppedRecording(false);
      setShowTrashIcon(false);
      setShowGenerateNote(false);
      setElapsedTime(0);
      setAccumulatedTime(0);

      // Clear timer if it exists
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
        timerIntervalRef.current = null;
      }

      // Reset time references
      startTimeRef.current = null;

      // Stop any active recording
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current = null;
      }

      // Stop and clear media stream
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
        streamRef.current = null;
      }
    }, [selectedPatient?.id]); // Only trigger when patient ID changes

    // Add effect to check for existing recordings when patient changes
    useEffect(() => {
      const checkExistingRecordings = async () => {
        if (selectedPatient?.id) {
          const hasRecordings = await audioStorage.hasRecordings(
            selectedPatient.id
          );
          setHasExistingRecordings(hasRecordings);
        } else {
          setHasExistingRecordings(false);
        }
      };

      checkExistingRecordings();
    }, [selectedPatient?.id]);

    // Helper function to check if we should show note inputs for current patient
    const shouldShowNoteInputs = () => {
      if (!selectedPatient?.id) return true; // Default to showing inputs if no patient selected
      return showNoteInputs[selectedPatient.id] !== false;
    };

    return (
      <div className="medical-scribe-container show">
        <div className="header-controls">
          {existingNote &&
            selectedPatient?.id &&
            !isCurrentPatientGenerating() &&
            existingNote.staging === false &&
            existingNote.draftSOAP && (
              <button className="new-note-button" onClick={handleNewNote}>
                {shouldShowNoteInputs() ? "Back to Note" : "New Note"}
              </button>
            )}
        </div>

        {!shouldShowNoteInputs() &&
        existingNote &&
        !isCurrentPatientGenerating() &&
        selectedPatient?.id &&
        existingNote.staging === false &&
        existingNote.draftSOAP ? (
          <HistoricalNote
            patient={{
              id: selectedPatient.id,
              name: selectedPatient.name || "",
              breed: selectedPatient.breed,
              appointmentTime: selectedPatient.appointmentTime,
              appointmentType: selectedPatient.appointmentType,
              pims_id: selectedPatient.pims_id,
              hasNote: true,
            }}
            onClose={() => {
              if (selectedPatient?.id) {
                setShowNoteInputs((prev) => ({
                  ...prev,
                  [selectedPatient.id]: true,
                }));
                setPatientShowInputs((prev) => ({
                  ...prev,
                  [selectedPatient.id]: true,
                }));
                setShowInputs(true);
              }
            }}
            date={selectedDate}
          />
        ) : (
          <>
            {getCurrentShowInputs() && (
              <div className="medical-scribe-box">
                <div className="medical-scribe-content">
                  {showTrashIcon && (
                    <div
                      className="trash-icon-container show"
                      onClick={handleDiscard}
                    >
                      <FaTrash className="trash-icon" />
                    </div>
                  )}
                  {soapMode === "spoken" && (
                    <>
                      <div className="recording-controls">
                        <div
                          className={`recording-button ${
                            isRecording ? "recording" : ""
                          } ${
                            isCurrentPatientGenerating() ? "generating" : ""
                          }`}
                          onClick={
                            isCurrentPatientGenerating()
                              ? undefined
                              : handleRecordingToggle
                          }
                          style={{
                            cursor: isCurrentPatientGenerating()
                              ? "not-allowed"
                              : "pointer",
                          }}
                        >
                          {isCurrentPatientGenerating() ? (
                            <div className="centered-content">
                              <div className="loading-icon-container">
                                <span className="progress-text">
                                  {getCurrentPatientProgress() > 0 &&
                                    `${Math.round(
                                      getCurrentPatientProgress()
                                    )}%`}
                                </span>
                              </div>
                            </div>
                          ) : isRecording ? (
                            <div className="centered-content">
                              <FaStop className="recording-icon" />
                              <span>
                                Stop <br /> Recording
                              </span>
                            </div>
                          ) : (
                            <div className="centered-content">
                              <FaMicrophone className="recording-icon" />
                              <span>
                                {hasStoppedRecording ? (
                                  <>
                                    Resume <br /> Recording
                                  </>
                                ) : (
                                  <>
                                    Start <br /> Recording
                                  </>
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                        <div
                          className={`recording-timer ${
                            isRecording || hasStoppedRecording ? "show" : ""
                          }`}
                        >
                          {formatTime(elapsedTime)}
                        </div>
                        {!isRecording && !hasStoppedRecording && (
                          <button
                            className={`microphone-test-button ${
                              isMicrophoneTesting ? "testing" : ""
                            }`}
                            onClick={handleMicrophoneTest}
                          >
                            {isMicrophoneTesting
                              ? "Stop Test"
                              : "Test Microphone"}
                          </button>
                        )}
                        {(mediaRecorderRef.current && isRecording) ||
                        (microphoneTestRecorder && isMicrophoneTesting) ? (
                          <div className="audio-visualizer-container">
                            {(() => {
                              const activeRecorder = getActiveRecorder();
                              return activeRecorder ? (
                                <LiveAudioVisualizer
                                  mediaRecorder={activeRecorder}
                                  width={200}
                                  height={50}
                                  barColor={isRecording ? "#dc3545" : "#4285f4"}
                                />
                              ) : null;
                            })()}
                          </div>
                        ) : null}
                      </div>
                    </>
                  )}
                  {soapMode === "written" && (
                    <div
                      className={`written-mode-container ${
                        isCurrentPatientGenerating() ? "generating" : ""
                      }`}
                    >
                      <textarea
                        className="written-mode-input"
                        value={getCurrentInput()}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        placeholder="Type SOAP details to start your note..."
                        disabled={isCurrentPatientGenerating()}
                      />
                      {isCurrentPatientGenerating() && (
                        <div className="written-mode-loading">
                          <div className="loading-text">
                            Generating...{" "}
                            {getCurrentPatientProgress() > 0 &&
                              `(${Math.round(getCurrentPatientProgress())}%)`}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div
                  className={`control-buttons ${
                    showGenerateNote ? "show" : ""
                  }`}
                >
                  {soapMode === "spoken" && hasStoppedRecording && (
                    <div className="additional-details-container">
                      <textarea
                        className="additional-details-input"
                        value={getCurrentAdditionalDetails()}
                        onChange={handleAdditionalDetailsChange}
                        placeholder="Additional details..."
                      />
                    </div>
                  )}
                  <button
                    className={`generate-note-button ${
                      isCurrentPatientGenerating() ? "generating" : ""
                    }`}
                    onClick={handleGenerateNote}
                    disabled={
                      isCurrentPatientGenerating() ||
                      (soapMode === "written" && !getCurrentInput().trim()) ||
                      (soapMode === "spoken" &&
                        !hasStoppedRecording &&
                        !hasExistingRecordings)
                    }
                  >
                    <span className="button-text">
                      {isCurrentPatientGenerating() ? (
                        <>
                          Generating...{" "}
                          {getCurrentPatientProgress() > 0 &&
                            `(${Math.round(getCurrentPatientProgress())}%)`}
                        </>
                      ) : (
                        "Generate Note"
                      )}
                    </span>
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
);

export default MedicalScribe;
