import React, { useState, useEffect, useRef } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";
import Header from "../components/Header/Header";
import QuickSOAP from "./QuickSOAP";
import {
  FaCheck,
  FaArrowRight,
  FaCalendar,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import "../styles/Scribe.css";
import { useDoctor } from "../contexts/DoctorContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { usePatientStore } from "../stores/patientStore";
import PatientListItem from "../components/Scribe/ScribePatientListItem";
import ReactConfetti from "react-confetti";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { auth, db } from "../config/firebase";

interface ScribeProps {
  refreshFirestoreData: () => Promise<void>;
  refreshTrigger: number;
}

interface Patient {
  id: string;
  name: string;
  breed: string;
  status?: "upcoming" | "inProgress" | "complete";
  appointmentTime?: string;
  appointmentType?: string;
  age?: string;
  sex?: string;
  weight?: string;
  pims_id?: string;
  color?: string;
  species?: string;
  missionBrief?: any;
}

interface Appointment {
  patient_id: string;
  time: string;
  type: string;
  patient: string;
  duration: number;
  appointmentColor?: string;
  status?: "upcoming" | "inProgress" | "complete";
  timeForSort?: Date;
  isUnassigned?: boolean;
}

interface UserData {
  user?: {
    clinicId?: string;
    settings?: {
      appointmentTypes?: {
        [key: string]: {
          types: string[];
          subTypes?: string[];
          color: string;
        };
      };
    };
  };
}

interface AppointmentTypeSettings {
  types: string[];
  color: string;
}

interface AppointmentTypes {
  [key: string]: AppointmentTypeSettings;
}

const Scribe: React.FC<ScribeProps> = ({
  refreshFirestoreData,
  refreshTrigger,
}) => {
  const { selectedDoctor } = useDoctor();
  const {
    selectedPatient,
    selectedHistoricalNote,
    patientsData,
    progressStats,
    setSelectedPatient,
    setSelectedHistoricalNote,
    setPatientsData,
    updatePatient,
    updateProgressStats,
    moveToInProgress,
    moveToComplete,
    clearSelections,
  } = usePatientStore();

  const [userData] = useState<UserData>(() => {
    const data = JSON.parse(localStorage.getItem("userData") || "{}");
    return data;
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showConfetti, setShowConfetti] = useState(false);
  const lastCompletionRef = useRef({
    date: new Date().toDateString(),
    wasComplete: false,
  });

  // Add state for daily completion tracking
  const [dailyCompletionShown, setDailyCompletionShown] = useState(() => {
    const today = new Date().toDateString();
    const stored = localStorage.getItem("lastConfettiDate");
    return stored === today;
  });

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Add new state for scribe notes
  const [scribenotes, setScribeNotes] = useState<{ [key: string]: any }>({});

  // Get appointmentTypes from userData once
  const appointmentTypes = userData?.user?.settings?.appointmentTypes || {};
  useEffect(() => {
    document.body.style.userSelect = "none";
    return () => {
      document.body.style.userSelect = "auto";
    };
  }, []);

  useEffect(() => {
    const fetchScribeNotes = async () => {
      try {
        const clinicId = userData?.user?.clinicId;
        const userId = auth.currentUser?.uid;
        const doctorId = selectedDoctor?.value;

        if (!clinicId || !userId || !doctorId) {
          console.error("Missing required IDs");
          return;
        }

        // Adjust the date to start of day in local timezone
        const localDate = new Date(selectedDate);
        localDate.setHours(0, 0, 0, 0);

        // Format date as YYYY-MM-DD using local timezone
        const dateStr = localDate.toLocaleDateString("en-CA"); // en-CA gives YYYY-MM-DD format

        const notesRef = collection(
          db,
          `clinics/${clinicId}/users/${userId}/scribe/${dateStr}/dailyNotes`
        );

        const notesSnapshot = await getDocs(notesRef);

        // Clear existing data first
        setPatientsData({});

        const notesData = notesSnapshot.docs.reduce((acc, doc) => {
          const data = doc.data();

          // Check if the note's doctorId matches the selected doctor's ID
          if (data.doctorId && data.doctorId !== doctorId) {
            return acc; // Skip this note if it belongs to a different doctor
          }

          // Process appointment colors using the appointmentTypes from settings
          if (data.appointmentType) {
            const trimmedAppointmentType = data.appointmentType.trim();
            for (const [groupName, settings] of Object.entries(
              appointmentTypes
            )) {
              if (
                settings.subTypes?.some(
                  (subType: string) => subType.trim() === trimmedAppointmentType
                )
              ) {
                data.appointmentColor = settings.color || "#A5B5BD";
                data.appointmentMacroGroup = groupName;
                break;
              }
            }
            if (!data.appointmentColor) {
              data.appointmentColor =
                appointmentTypes.Other?.color || "#A5B5BD";
              data.appointmentMacroGroup = "Other";
            }
          }

          acc[doc.id] = {
            ...data,
            pims_id: doc.id,
          };
          return acc;
        }, {} as { [key: string]: any });

        setScribeNotes(notesData);
        // Transform the scribe notes directly into patients data
        const newPatientsData = Object.entries(notesData).reduce(
          (
            acc: { [key: string]: Patient },
            [pimsId, noteData]: [string, any]
          ) => {
            acc[pimsId] = {
              id: pimsId,
              name: noteData.patientName || "",
              breed: noteData.breed || "",
              species: noteData.species || "",
              age: calculateAge(noteData.birthday),
              sex: noteData.sex || "",
              appointmentTime: formatTime(noteData.appointmentTime),
              appointmentType: noteData.appointmentType || "",
              status: noteData.status || "upcoming",
              pims_id: pimsId,
              weight: noteData.weight || "",
              color: noteData.appointmentColor,
            };
            return acc;
          },
          {}
        );

        setPatientsData(newPatientsData);

        // Transform for progress stats
        const transformedAppointments = Object.values(newPatientsData).map(
          (patient) => ({
            patient_id: patient.id,
            patient: patient.name,
            time: patient.appointmentTime || "",
            type: patient.appointmentType || "",
            duration: 0,
            appointmentColor: patient.color,
            status: patient.status || "upcoming",
            timeForSort: patient.appointmentTime
              ? new Date(`1970/01/01 ${patient.appointmentTime}`)
              : new Date(`1970/01/01 23:59`),
          })
        );

        updateProgressStats(transformedAppointments, newPatientsData);

        // Check if currently selected patient exists in the new data
        const currentPatientExists =
          selectedPatient && newPatientsData[selectedPatient.id];

        // Only auto-select if we're changing days or doctors (not on manual deselection)
        const isChangingDayOrDoctor =
          !currentPatientExists && // Current patient doesn't exist in new data
          selectedPatient !== null; // We had a patient selected before

        if (isChangingDayOrDoctor) {
          // First try to find the earliest upcoming patient
          let nextPatient: Patient | null =
            Object.values(newPatientsData)
              .filter((patient) => patient.status === "upcoming")
              .sort((a, b) => {
                const timeA = a.appointmentTime
                  ? new Date(`1970/01/01 ${a.appointmentTime}`).getTime()
                  : Infinity;
                const timeB = b.appointmentTime
                  ? new Date(`1970/01/01 ${b.appointmentTime}`).getTime()
                  : Infinity;
                return timeA - timeB;
              })[0] || null;

          // If no upcoming patients, try to find the first in-progress patient
          if (!nextPatient) {
            nextPatient =
              Object.values(newPatientsData).find(
                (patient) => patient.status === "inProgress"
              ) || null;
          }

          // If no in-progress patients, try to find the first complete patient
          if (!nextPatient) {
            nextPatient =
              Object.values(newPatientsData).find(
                (patient) => patient.status === "complete"
              ) || null;
          }

          if (nextPatient) {
            const appointment = transformedAppointments.find(
              (apt) => apt.patient_id === nextPatient!.id
            );
            if (appointment) {
              handlePatientSelect(nextPatient, appointment);
            }
          } else {
            clearSelections(); // Clear selections if no patients are available
          }
        } else if (!currentPatientExists) {
          clearSelections(); // Clear selections if the current patient doesn't exist and we're not changing day/doctor
        }
      } catch (error) {
        console.error("Error fetching scribe notes:", error);
      }
    };

    if (selectedDoctor) {
      fetchScribeNotes();
    } else {
      clearSelections(); // Clear selections if no doctor is selected
    }
  }, [
    selectedDoctor,
    selectedDate,
    setPatientsData,
    updateProgressStats,
    userData?.user?.clinicId,
    appointmentTypes,
    selectedPatient,
  ]);

  // Update the confetti effect
  useEffect(() => {
    const isToday = selectedDate.toDateString() === new Date().toDateString();
    if (!isToday) {
      // Store the completion state when leaving today's view
      if (lastCompletionRef.current.date === new Date().toDateString()) {
        lastCompletionRef.current.wasComplete =
          progressStats.percentage === 100;
      }
      return;
    }

    const currentPercentage = progressStats.percentage;
    const isCompleteNow = currentPercentage === 100;
    const today = new Date().toDateString();

    // Only show confetti if:
    // 1. We're looking at today
    // 2. The list is complete now
    // 3. It wasn't complete last time we were on today's view
    // 4. We haven't shown confetti yet today
    if (
      isCompleteNow &&
      today === lastCompletionRef.current.date &&
      !lastCompletionRef.current.wasComplete &&
      !dailyCompletionShown
    ) {
      setShowConfetti(true);
      setDailyCompletionShown(true);
      localStorage.setItem("lastConfettiDate", today);
      const timer = setTimeout(() => setShowConfetti(false), 5000);
      return () => clearTimeout(timer);
    }

    // Update the ref with current state
    lastCompletionRef.current = {
      date: today,
      wasComplete: isCompleteNow,
    };
  }, [progressStats.percentage, selectedDate, dailyCompletionShown]);

  // Reset daily completion flag at midnight
  useEffect(() => {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    const timeUntilMidnight = tomorrow.getTime() - now.getTime();

    const resetDailyFlag = () => {
      const today = new Date().toDateString();
      const lastShownDate = localStorage.getItem("lastConfettiDate");
      if (lastShownDate !== today) {
        setDailyCompletionShown(false);
      }
    };

    const timer = setTimeout(resetDailyFlag, timeUntilMidnight);
    return () => clearTimeout(timer);
  }, []);

  const onDragEnd = async (result: DropResult) => {
    const { source, destination, draggableId: patientId } = result;
  
    // Remove drag-over classes
    const columns = document.querySelectorAll(".kanban-section");
    columns.forEach((column) => {
      column.classList.remove("drag-over");
    });
    document.body.style.cursor = "auto";
  
    if (!destination || !patientId) return;
  
    const doctorId = selectedDoctor?.value;
    if (!doctorId) return;
  
    const newStatus = destination.droppableId as "upcoming" | "inProgress" | "complete";
    const patient = patientsData[patientId];
  
    // Update local state immediately with the new status
    const updatedPatient: Patient = {
      ...patient,
      status: newStatus,
    };
  
    const newPatientsData: { [key: string]: Patient } = {
      ...patientsData,
      [patientId]: updatedPatient,
    };
  
    // Update local state
    setPatientsData(newPatientsData);
  
    // Update localStorage immediately with the new status
    const storedUserData = JSON.parse(localStorage.getItem("userData") || "{}");
    if (storedUserData.user?.missionBrief?.[doctorId]?.appointments) {
      const updatedAppointments = storedUserData.user.missionBrief[doctorId].appointments.map((apt: any) => {
        if (apt.patientData?.patientId === patientId) {
          return {
            ...apt,
            status: newStatus,
            patientData: {
              ...apt.patientData,
              status: newStatus,
            },
          };
        }
        return apt;
      });
  
      const updatedUserData = {
        ...storedUserData,
        user: {
          ...storedUserData.user,
          missionBrief: {
            ...storedUserData.user.missionBrief,
            [doctorId]: {
              ...storedUserData.user.missionBrief[doctorId],
              appointments: updatedAppointments,
            },
          },
        },
      };
      localStorage.setItem("userData", JSON.stringify(updatedUserData));
    }
  
    // Update progress stats immediately
    const transformedAppointments = Object.values(newPatientsData).map((patient) => ({
      patient_id: patient.id,
      patient: patient.name,
      time: patient.appointmentTime || "",
      type: patient.appointmentType || "",
      duration: 0,
      appointmentColor: "#A5B5BD",
      status: patient.status || "upcoming",
    }));
  
    updateProgressStats(transformedAppointments, newPatientsData);
  
    // When dropping into "inProgress", force the patient to remain selected
    if (destination.droppableId === "inProgress") {
      const appointment = transformedAppointments.find((apt) => apt.patient_id === patientId);
      if (appointment) {
        // Pass 'true' as the third argument to force selection even if the patient is already selected
        handlePatientSelect(updatedPatient, appointment, true);
      }
    }
  
    // Update Firestore with the new status
    try {
      const userData = JSON.parse(localStorage.getItem("userData") || "{}");
      const clinicId = userData?.user?.clinicId;
      const userId = auth.currentUser?.uid;
  
      if (!clinicId || !userId || !patient.pims_id) {
        throw new Error("Missing required IDs");
      }
  
      const dateStr = selectedDate.toLocaleDateString("en-CA"); // Format: YYYY-MM-DD
      const noteRef = doc(
        db,
        `clinics/${clinicId}/users/${userId}/scribe/${dateStr}/dailyNotes/${patient.pims_id}`
      );
  
      await updateDoc(noteRef, {
        status: newStatus,
      });
    } catch (error) {
      console.error("Error updating status in Firestore:", error);
    }
  };  

  const onDragStart = () => {
    // Remove the global cursor style change
  };

  const onDragUpdate = (update: any) => {
    const { destination } = update;

    const containers = document.querySelectorAll(".kanban-cards-container");
    containers.forEach((container) => {
      container.classList.remove("drag-over");
    });

    if (destination) {
      const destinationEl = document.querySelector(
        `.${destination.droppableId} .kanban-cards-container`
      );
      destinationEl?.classList.add("drag-over");
    }
  };

  const getPatientsByStatus = (
    status: "upcoming" | "inProgress" | "complete"
  ): Appointment[] => {
    return Object.values(patientsData)
      .filter((patient: Patient) => patient.status === status)
      .map((patient: Patient) => ({
        patient_id: patient.id,
        patient: patient.name,
        time: patient.appointmentTime || "",
        type: patient.appointmentType || "",
        duration: 0,
        appointmentColor: patient.color || "#A5B5BD",
        status: patient.status,
        isUnassigned: patient.appointmentTime === "12:00 am",
      }))
      .sort((a, b) => {
        if (a.isUnassigned && !b.isUnassigned) return 1;
        if (!a.isUnassigned && b.isUnassigned) return -1;

        const timeA = new Date(`1970/01/01 ${a.time}`).getTime();
        const timeB = new Date(`1970/01/01 ${b.time}`).getTime();
        return timeA - timeB;
      });
  };

  const handlePatientSelect = (
    patient: Patient,
    appointment: Appointment,
    forceSelect: boolean = false
  ) => {
    // Only toggle deselection if not forced
    if (!forceSelect && selectedPatient?.id === appointment.patient_id) {
      clearSelections();
    } else {
      // Create the updated patient object (you already do this)
      const updatedPatient: Patient = {
        ...patient,
        appointmentTime: appointment.time,
        appointmentType: appointment.type,
        id: appointment.patient_id,
        pims_id: patient.pims_id,
        weight: patient.weight,
        color: appointment.appointmentColor,
      };
  
      if (patient.status === "complete") {
        setSelectedPatient(updatedPatient);
        setSelectedHistoricalNote(updatedPatient);
      } else {
        setSelectedHistoricalNote(null);
        setSelectedPatient(updatedPatient);
      }
    }
  };
  

  const getMinDate = () => {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return date;
  };

  const handlePreviousDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() - 1);
    if (newDate >= getMinDate()) {
      setSelectedDate(newDate);
    }
  };

  const handleNextDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + 1);
    if (newDate <= new Date()) {
      setSelectedDate(newDate);
    }
  };

  const handleStartNote = async () => {
    const doctorId = selectedDoctor?.value;
    if (!doctorId) return;

    if (selectedPatient && selectedPatient.status !== "inProgress") {
      // Move patient to in-progress
      const updatedPatient: Patient = {
        ...selectedPatient,
        status: "inProgress" as const,
      };

      // Update patients data with explicit typing
      const newPatientsData: { [key: string]: Patient } = {
        ...patientsData,
        [selectedPatient.id]: updatedPatient,
      };

      // Update local state
      setPatientsData(newPatientsData);

      // Update localStorage
      const storedUserData = JSON.parse(
        localStorage.getItem("userData") || "{}"
      );
      if (storedUserData.user?.missionBrief?.[doctorId]?.appointments) {
        const updatedAppointments = storedUserData.user.missionBrief[
          doctorId
        ].appointments.map((apt: any) => {
          if (apt.patientData?.patientId === selectedPatient.id) {
            return {
              ...apt,
              status: "inProgress",
              patientData: {
                ...apt.patientData,
                status: "inProgress",
              },
            };
          }
          return apt;
        });

        const updatedUserData = {
          ...storedUserData,
          user: {
            ...storedUserData.user,
            missionBrief: {
              ...storedUserData.user.missionBrief,
              [doctorId]: {
                ...storedUserData.user.missionBrief[doctorId],
                appointments: updatedAppointments,
              },
            },
          },
        };
        localStorage.setItem("userData", JSON.stringify(updatedUserData));
      }

      // Update Firebase
      try {
        const userData = JSON.parse(localStorage.getItem("userData") || "{}");
        const clinicId = userData?.user?.clinicId;
        const userId = auth.currentUser?.uid;

        if (!clinicId || !userId || !selectedPatient.pims_id) {
          throw new Error("Missing required IDs");
        }

        const dateStr = selectedDate.toLocaleDateString("en-CA"); // en-CA gives YYYY-MM-DD format
        const noteRef = doc(
          db,
          `clinics/${clinicId}/users/${userId}/scribe/${dateStr}/dailyNotes/${selectedPatient.pims_id}`
        );

        await updateDoc(noteRef, {
          status: "inProgress",
        });
      } catch (error) {
        console.error("Error updating status in Firebase:", error);
      }

      // Update progress stats
      const transformedAppointments = Object.values(newPatientsData).map(
        (patient) => ({
          patient_id: patient.id,
          patient: patient.name,
          time: patient.appointmentTime || "",
          type: patient.appointmentType || "",
          duration: 0,
          appointmentColor: patient.color || "#A5B5BD",
          status: patient.status || "upcoming",
        })
      );

      updateProgressStats(transformedAppointments, newPatientsData);
    }
  };

  const handleStatusChange = async (
    patientId: string,
    newStatus: "upcoming" | "inProgress" | "complete"
  ) => {
    const doctorId = selectedDoctor?.value;
    if (!doctorId) return;

    const patient = patientsData[patientId];

    // Update local state immediately
    const updatedPatient: Patient = {
      ...patient,
      status: newStatus,
    };

    const newPatientsData: { [key: string]: Patient } = {
      ...patientsData,
      [patientId]: updatedPatient,
    };

    // Update local state
    setPatientsData(newPatientsData);

    // Update localStorage
    const storedUserData = JSON.parse(localStorage.getItem("userData") || "{}");
    if (storedUserData.user?.missionBrief?.[doctorId]?.appointments) {
      const updatedAppointments = storedUserData.user.missionBrief[
        doctorId
      ].appointments.map((apt: any) => {
        if (apt.patientData?.patientId === patientId) {
          return {
            ...apt,
            status: newStatus,
            patientData: {
              ...apt.patientData,
              status: newStatus,
            },
          };
        }
        return apt;
      });

      const updatedUserData = {
        ...storedUserData,
        user: {
          ...storedUserData.user,
          missionBrief: {
            ...storedUserData.user.missionBrief,
            [doctorId]: {
              ...storedUserData.user.missionBrief[doctorId],
              appointments: updatedAppointments,
            },
          },
        },
      };
      localStorage.setItem("userData", JSON.stringify(updatedUserData));
    }

    // Update Firestore with new path
    try {
      const userData = JSON.parse(localStorage.getItem("userData") || "{}");
      const clinicId = userData?.user?.clinicId;
      const userId = auth.currentUser?.uid;

      if (!clinicId || !userId || !patient.pims_id) {
        throw new Error("Missing required IDs");
      }

      const dateStr = selectedDate.toLocaleDateString("en-CA"); // en-CA gives YYYY-MM-DD format
      const noteRef = doc(
        db,
        `clinics/${clinicId}/users/${userId}/scribe/${dateStr}/dailyNotes/${patient.pims_id}`
      );

      await updateDoc(noteRef, {
        status: newStatus,
      });
    } catch (error) {
      console.error("Error updating status in Firestore:", error);
    }

    // Update progress stats
    const transformedAppointments = Object.values(newPatientsData).map(
      (patient) => ({
        patient_id: patient.id,
        patient: patient.name,
        time: patient.appointmentTime || "",
        type: patient.appointmentType || "",
        duration: 0,
        appointmentColor: "#A5B5BD",
        status: patient.status || "upcoming",
      })
    );

    updateProgressStats(transformedAppointments, newPatientsData);
  };

  // Add helper functions
  const formatTime = (timestamp: any) => {
    if (!timestamp) return "";
    const date = new Date(timestamp.seconds * 1000);
    return date
      .toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })
      .toLowerCase();
  };

  const calculateAge = (birthday: string) => {
    if (!birthday) return "";
    const birthDate = new Date(birthday);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return `${age}y`;
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <main className="scribe-container">
        {showConfetti && (
          <ReactConfetti
            width={windowSize.width}
            height={windowSize.height}
            recycle={false}
            numberOfPieces={777}
            style={{ position: "fixed", top: 0, left: 0, zIndex: 9999 }}
          />
        )}
        <div className="progress-container">
          <h2>Daily Progress</h2>
          <div className="progress-bar">
            <div
              className="progress-fill"
              style={{ width: `${progressStats.percentage}%` }}
            />
          </div>
          <div className="progress-stats">
            <span>
              {progressStats.completed} of {progressStats.total} notes completed
            </span>
            <span>{progressStats.percentage.toFixed(1)}%</span>
          </div>
        </div>

        <div className="content-container">
          <DragDropContext
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            onDragUpdate={onDragUpdate}
          >
            <div className="kanban-container">
              <div className="col-span-3 w-full flex items-center justify-between px-4 py-3 bg-white border-b">
                <button
                  onClick={handlePreviousDay}
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  disabled={selectedDate <= getMinDate()}
                >
                  <FaChevronLeft
                    className={
                      selectedDate <= getMinDate()
                        ? "text-gray-300"
                        : "text-gray-600"
                    }
                  />
                </button>

                <div className="relative flex items-center gap-2">
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date: Date | null) =>
                      date && setSelectedDate(date)
                    }
                    dateFormat="MMMM d, yyyy"
                    minDate={getMinDate()}
                    maxDate={new Date()}
                    customInput={
                      <div className="flex items-center gap-2">
                        <FaCalendar className="text-gray-500" />
                        {selectedDate.toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </div>
                    }
                  />
                </div>

                <button
                  onClick={handleNextDay}
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  disabled={selectedDate >= new Date()}
                >
                  <FaChevronRight
                    className={
                      selectedDate >= new Date()
                        ? "text-gray-300"
                        : "text-gray-600"
                    }
                  />
                </button>
              </div>
              <div className="kanban-columns-container">
                {(["upcoming", "inProgress", "complete"] as const).map(
                  (status) => (
                    <div className={`kanban-section ${status}`} key={status}>
                      <div className="kanban-title">
                        {status === "upcoming" && "Upcoming"}
                        {status === "inProgress" && "In Progress"}
                        {status === "complete" && "Complete"}
                        <span className="kanban-count">
                          {getPatientsByStatus(status).length}
                        </span>
                      </div>

                      <Droppable droppableId={status}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={`kanban-cards-container ${
                              snapshot.isDraggingOver ? "drag-over" : ""
                            }`}
                          >
                            {status === "upcoming" ? (
                              <>
                                {/* Scheduled Appointments */}
                                {getPatientsByStatus(status).some(
                                  (apt) => !apt.isUnassigned
                                ) && (
                                  <div className="appointment-group">
                                    <div className="appointment-group-header">
                                      Scheduled Appointments:{" "}
                                      {
                                        getPatientsByStatus(status).filter(
                                          (apt) => !apt.isUnassigned
                                        ).length
                                      }
                                    </div>
                                    {getPatientsByStatus(status)
                                      .filter(
                                        (appointment) =>
                                          !appointment.isUnassigned
                                      )
                                      .map(
                                        (
                                          appointment: Appointment,
                                          index: number
                                        ) => (
                                          <Draggable
                                            key={appointment.patient_id}
                                            draggableId={appointment.patient_id}
                                            index={index}
                                          >
                                            {(provided, snapshot) => {
                                              const props = {
                                                patient: {
                                                  id: appointment.patient_id,
                                                  name: `${
                                                    patientsData[
                                                      appointment.patient_id
                                                    ]?.name || ""
                                                  } | ${
                                                    patientsData[
                                                      appointment.patient_id
                                                    ]?.breed || ""
                                                  }`.trim(),
                                                  appointmentTime:
                                                    appointment.time,
                                                  appointmentType:
                                                    appointment.type,
                                                  status:
                                                    patientsData[
                                                      appointment.patient_id
                                                    ]?.status,
                                                },
                                                status: appointment.status as
                                                  | "upcoming"
                                                  | "inProgress"
                                                  | "complete",
                                                isSelected:
                                                  selectedPatient?.id ===
                                                  appointment.patient_id,
                                                appointmentColor:
                                                  appointment.appointmentColor ||
                                                  "#A5B5BD",
                                              };

                                              return (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  style={
                                                    provided.draggableProps
                                                      .style
                                                  }
                                                >
                                                  <PatientListItem
                                                    {...props}
                                                    onSelect={() =>
                                                      handlePatientSelect(
                                                        patientsData[
                                                          appointment.patient_id
                                                        ],
                                                        appointment
                                                      )
                                                    }
                                                    onMoveToInProgress={() =>
                                                      handleStatusChange(
                                                        appointment.patient_id,
                                                        "inProgress"
                                                      )
                                                    }
                                                    onToggleNote={() =>
                                                      handleStatusChange(
                                                        appointment.patient_id,
                                                        "complete"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              );
                                            }}
                                          </Draggable>
                                        )
                                      )}
                                  </div>
                                )}

                                {/* Unscheduled Appointments */}
                                {getPatientsByStatus(status).some(
                                  (apt) => apt.isUnassigned
                                ) && (
                                  <div className="appointment-group">
                                    <div className="appointment-group-header">
                                      Unscheduled Appointments:{" "}
                                      {
                                        getPatientsByStatus(status).filter(
                                          (apt) => apt.isUnassigned
                                        ).length
                                      }
                                    </div>
                                    {getPatientsByStatus(status)
                                      .filter(
                                        (appointment) =>
                                          appointment.isUnassigned
                                      )
                                      .map(
                                        (
                                          appointment: Appointment,
                                          index: number
                                        ) => (
                                          <Draggable
                                            key={appointment.patient_id}
                                            draggableId={appointment.patient_id}
                                            index={
                                              getPatientsByStatus(
                                                status
                                              ).filter(
                                                (apt) => !apt.isUnassigned
                                              ).length + index
                                            }
                                          >
                                            {(provided, snapshot) => {
                                              const props = {
                                                patient: {
                                                  id: appointment.patient_id,
                                                  name: `${
                                                    patientsData[
                                                      appointment.patient_id
                                                    ]?.name || ""
                                                  } | ${
                                                    patientsData[
                                                      appointment.patient_id
                                                    ]?.breed || ""
                                                  }`.trim(),
                                                  appointmentTime:
                                                    appointment.time,
                                                  appointmentType:
                                                    appointment.type,
                                                  status:
                                                    patientsData[
                                                      appointment.patient_id
                                                    ]?.status,
                                                },
                                                status: appointment.status as
                                                  | "upcoming"
                                                  | "inProgress"
                                                  | "complete",
                                                isSelected:
                                                  selectedPatient?.id ===
                                                  appointment.patient_id,
                                                appointmentColor:
                                                  appointment.appointmentColor ||
                                                  "#A5B5BD",
                                              };

                                              return (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  style={
                                                    provided.draggableProps
                                                      .style
                                                  }
                                                >
                                                  <PatientListItem
                                                    {...props}
                                                    onSelect={() =>
                                                      handlePatientSelect(
                                                        patientsData[
                                                          appointment.patient_id
                                                        ],
                                                        appointment
                                                      )
                                                    }
                                                    onMoveToInProgress={() =>
                                                      handleStatusChange(
                                                        appointment.patient_id,
                                                        "inProgress"
                                                      )
                                                    }
                                                    onToggleNote={() =>
                                                      handleStatusChange(
                                                        appointment.patient_id,
                                                        "complete"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              );
                                            }}
                                          </Draggable>
                                        )
                                      )}
                                  </div>
                                )}
                              </>
                            ) : (
                              // Regular list for other columns
                              getPatientsByStatus(status).map(
                                (appointment: Appointment, index: number) => (
                                  <Draggable
                                    key={appointment.patient_id}
                                    draggableId={appointment.patient_id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      const props = {
                                        patient: {
                                          id: appointment.patient_id,
                                          name: `${
                                            patientsData[appointment.patient_id]
                                              ?.name || ""
                                          } | ${
                                            patientsData[appointment.patient_id]
                                              ?.breed || ""
                                          }`.trim(),
                                          appointmentTime: appointment.time,
                                          appointmentType: appointment.type,
                                          status:
                                            patientsData[appointment.patient_id]
                                              ?.status,
                                        },
                                        status: appointment.status as
                                          | "upcoming"
                                          | "inProgress"
                                          | "complete",
                                        isSelected:
                                          selectedPatient?.id ===
                                          appointment.patient_id,
                                        appointmentColor:
                                          appointment.appointmentColor ||
                                          "#A5B5BD",
                                      };

                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={provided.draggableProps.style}
                                        >
                                          <PatientListItem
                                            {...props}
                                            onSelect={() =>
                                              handlePatientSelect(
                                                patientsData[
                                                  appointment.patient_id
                                                ],
                                                appointment
                                              )
                                            }
                                            onMoveToInProgress={() =>
                                              handleStatusChange(
                                                appointment.patient_id,
                                                "inProgress"
                                              )
                                            }
                                            onToggleNote={() =>
                                              handleStatusChange(
                                                appointment.patient_id,
                                                "complete"
                                              )
                                            }
                                          />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                )
                              )
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  )
                )}
              </div>
            </div>
          </DragDropContext>

          <div className="soap-container">
            <QuickSOAP
              refreshFirestoreData={refreshFirestoreData}
              refreshTrigger={refreshTrigger}
              selectedPatient={selectedPatient}
              selectedHistoricalNote={selectedHistoricalNote}
              onCloseHistoricalNote={() => setSelectedHistoricalNote(null)}
              appointmentTypes={
                userData.user?.settings?.appointmentTypes || {
                  Other: {
                    types: [],
                    color: "#A5B5BD",
                  },
                }
              }
              onStartNote={handleStartNote}
              selectedDate={selectedDate.toLocaleDateString("en-CA")}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Scribe;
