import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import '../../styles/PatientPrimer/AppointmentGuide.css';
import Select from 'react-select';
import { usePatientStore } from '../../stores/patientStore';
import { getFirestore, getDoc, doc, updateDoc, Timestamp } from 'firebase/firestore';

const StarDecoration = ({ color = '#3B82F6' }) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const updateWidth = () => {
        const width = containerRef.current.offsetWidth;
        // Convert width to relative units (assuming 20px is our base unit)
        const relativeWidth = Math.round((width / 20) * 5); // This will give us similar scaling to our previous percentage-based approach
        setContainerWidth(relativeWidth);
      };

      updateWidth();
      window.addEventListener('resize', updateWidth);
      return () => window.removeEventListener('resize', updateWidth);
    }
  }, []);
  
  // Calculate number of stars based on actual measured width
  const numStars = Math.min(
    Math.max(
      Math.round(containerWidth / 5), // 1 star per 20px of width
      3 // minimum stars (to ensure edge stars and at least one middle element)
    ),
    7 // maximum stars
  );

  // If numStars is even, add one to make it odd (ensuring stars on edges)
  const adjustedNumStars = numStars % 2 === 0 ? numStars + 1 : numStars;

  return (
    <div 
      ref={containerRef}
      style={{ display: 'flex', justifyContent: 'space-between', width: '100%', position: 'absolute', top: '-5px' }}
    >
      {Array.from({ length: adjustedNumStars }).map((_, i) => {
        // For odd indices (1, 3, 5...), show plus symbols
        // For even indices (0, 2, 4...), show stars
        const isEdge = i === 0 || i === adjustedNumStars - 1;
        const isStar = i % 2 === 0;

        return (
          <div
            key={i}
            className="star-decoration"
            style={{
              fontSize: isEdge ? '12px' : '10px', // Larger size for edge stars
              color: color,
              textShadow: '0 0 3px rgba(255, 255, 255, 0.8)',
            }}
          >
            {isStar ? '✦' : '+'}
          </div>
        );
      })}
    </div>
  );
};

const formatTimeToAMPM = (time) => {
  if (!time) return '';
  const [hours, minutes] = time.split(':');
  const hoursNum = parseInt(hours, 10);
  const formattedHours = hoursNum % 12 || 12;
  return `${formattedHours}:${minutes}`;
};

const MultiAppointmentBlock = ({ 
  appointments, 
  slot, 
  handleSlotMouseEnter, 
  handleSlotMouseLeave, 
  handleSlotClick, 
  totalSlots, 
  appointmentTypesData, 
  clickedCategory, 
  hoveredCategory, 
  selectedPrimerCard,
  getPatientName,
  isAddingAppointment,
  combinedSchedule
}) => {
  const { selectedPrimerPatient, setSelectedPrimerPatient } = usePatientStore();
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredAppointment, setHoveredAppointment] = useState(null);
  
  // Check if any appointment in the block matches the clicked/hovered category
  const hasMatchingAppointment = appointments.some(app => 
    !clickedCategory || app.appointmentMacroGroup === clickedCategory
  );

  // Check if this contains the first available appointment for the clicked category
  const isFirstAvailableAppointment = clickedCategory && 
    combinedSchedule.reduce((firstTime, slot) => {
      if (firstTime) return firstTime; // Already found first time
      
      // For multi-appointment slots, check each appointment
      if (slot.appointments) {
        const matchingApp = slot.appointments.find(app => 
          app.appointmentMacroGroup === clickedCategory
        );
        if (matchingApp) return slot.time;
      }
      // For single appointment slots
      else if (slot.appointmentMacroGroup === clickedCategory) {
        return slot.time;
      }
      return firstTime;
    }, null) === slot.time;

  // Find the first matching appointment within this slot
  const firstMatchingAppointment = useMemo(() => {
    if (!isFirstAvailableAppointment || !clickedCategory) return null;
    return appointments.find(app => app.appointmentMacroGroup === clickedCategory);
  }, [isFirstAvailableAppointment, clickedCategory, appointments]);

  // Update hasHighlightedAppointment to consider first available appointment
  const hasHighlightedAppointment = hasMatchingAppointment && (
    (selectedPrimerPatient && appointments.some(app => app.patient_id === selectedPrimerPatient.id)) ||
    (hoveredCategory && appointments.some(app => app.appointmentMacroGroup === hoveredCategory)) ||
    (clickedCategory && isFirstAvailableAppointment) ||
    (selectedPrimerCard && appointments.some(app => app.patient_id === selectedPrimerCard.patient_id))
  );

  // Update showStars to only show for first available appointment when category is clicked
  const showStars = hasMatchingAppointment && (
    (hoveredCategory && appointments.some(app => app.appointmentMacroGroup === hoveredCategory)) || 
    (clickedCategory && isFirstAvailableAppointment) ||
    isHovered || 
    hasHighlightedAppointment
  );
  
  // Get color from the first matching appointment
  const hoverColor = appointments[0].appointmentColor || '#A5B5BD';

  // Update the star color logic to consider the selected appointment
  const starColor = hasHighlightedAppointment 
    ? (selectedPrimerPatient 
        ? appointments.find(app => app.patient_id === selectedPrimerPatient.id)?.appointmentColor 
        : appointments.find(app => 
            (clickedCategory && app.appointmentMacroGroup === clickedCategory) ||
            (hoveredCategory && app.appointmentMacroGroup === hoveredCategory)
          )?.appointmentColor) || hoverColor
    : hoverColor;

  return (
    <div 
      className="schedule-slot multi-appointment"
      style={{
        width: `${(slot.duration / totalSlots) * 100}%`,
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
        backgroundColor: 'transparent',
        transition: 'all 0.3s ease',
        zIndex: (isHovered || hasHighlightedAppointment) ? 2 : 1,
        position: 'relative',
        padding: '2px',
        pointerEvents: 'auto',
        opacity: 1,
        boxShadow: (!selectedPrimerPatient && hasMatchingAppointment && (
          isHovered || 
          (hasHighlightedAppointment && (!clickedCategory || isFirstAvailableAppointment))
        )) ? '0 2px 8px rgba(0, 0, 0, 0.2)' : 'none',
        border: (!selectedPrimerPatient && hasMatchingAppointment && (
          isHovered || 
          (hasHighlightedAppointment && (!clickedCategory || isFirstAvailableAppointment))
        )) ? '2px solid #1A237E' : 'none'
      }}
      onMouseEnter={(e) => {
        e.stopPropagation();
        setIsHovered(true);
        setHoveredAppointment(appointments[0]);
        handleSlotMouseEnter(appointments[0]);
      }}
      onMouseLeave={(e) => {
        e.stopPropagation();
        setIsHovered(false);
        setHoveredAppointment(null);
        handleSlotMouseLeave();
      }}
    >
      {showStars && (
        <div 
          style={{ 
            position: 'absolute', 
            width: '100%', 
            zIndex: 1, 
            pointerEvents: 'none',
            top: '0px'
          }} 
          className="highlight"
        >
          <StarDecoration 
            color={starColor}
            width={slot.duration / totalSlots * 100}
          />
        </div>
      )}
      
      {appointments.map((app, index) => {
        const isHighlighted = 
          (selectedPrimerPatient && app.patient_id === selectedPrimerPatient.id) ||
          (hoveredCategory && app.appointmentMacroGroup === hoveredCategory) ||
          (clickedCategory && app.appointmentMacroGroup === clickedCategory && isFirstAvailableAppointment) ||
          (selectedPrimerCard && app.patient_id === selectedPrimerCard.patient_id);
        
        const isHoveredOnly = hoveredAppointment === app && !selectedPrimerPatient;
        
        const appointmentOpacity = clickedCategory 
          ? app.appointmentMacroGroup === clickedCategory ? 1 : 0.3
          : 1;
        
        return (
          <div 
            key={index}
            className={`appointment-sub-block ${isHighlighted ? 'highlight' : ''}`}
            style={{
              backgroundColor: app.appointmentColor || '#A5B5BD',
              flex: 1,
              borderRadius: '4px',
              transform: isHighlighted ? 'scale(1.15)' : 'scale(1)',
              transition: 'all 0.3s ease',
              cursor: 'pointer',
              position: 'relative',
              zIndex: isHighlighted ? 3 : 2,
              pointerEvents: 'auto',
              border: (isHighlighted || isHoveredOnly) ? '2px solid #1A237E' : 'none',
              boxShadow: (isHighlighted || isHoveredOnly) ? '0 2px 8px rgba(0, 0, 0, 0.2)' : 'none',
              opacity: appointmentOpacity
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleSlotClick(app);
            }}
            onMouseEnter={(e) => {
              e.stopPropagation();
              handleSlotMouseEnter(app);
              setHoveredAppointment(app);
              setIsHovered(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              handleSlotMouseLeave();
              setHoveredAppointment(null);
              setIsHovered(false);
            }}
          />
        );
      })}
      
      {hoveredAppointment && (
        <div className="tooltip">
          <strong>
            {appointments
              .map(app => getPatientName(app.patient_id))
              .join(' + ')
            }
          </strong> @ {formatTimeToAMPM(slot.time)}
          <br />
          <em>{hoveredAppointment.type}</em>
        </div>
      )}
    </div>
  );
};

const formatDateTime = () => {
  const now = new Date();
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  
  const month = months[now.getMonth()];
  const day = now.getDate();
  const year = now.getFullYear();
  
  let hours = now.getHours();
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const period = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12;

  return {
    date: `${month} ${day}${getOrdinalSuffix(day)}, ${year}`,
    time: `${hours}:${minutes}${period}`
  };
};

const getOrdinalSuffix = (day) => {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
  }
};

const AppointmentGuide = ({
  appointmentTypesData,
  combinedSchedule,
  totalSlots,
  workdayStartHour,
  workdayEndHour,
  hoveredCategory,
  clickedCategory,
  hoveredAppointment,
  handleBarChartMouseEnter,
  handleBarChartMouseLeave,
  handleCategoryClick,
  handleAppointmentHover,
  handleAppointmentLeave,
  handleCategoryHover,
  unassignedAppointments,
  patientMockData,
  selectedDoctor,
  refreshFirestoreData,
  selectedSlot,
  setSelectedSlot,
  selectedFreeSlot,
  setSelectedFreeSlot,
  selectedStartTime,
  setSelectedStartTime,
  appointmentDuration,
  setAppointmentDuration,
  selectedAppointmentType,
  setSelectedAppointmentType,
  isUnassignedAppointment,
  setIsUnassignedAppointment,
  showUnassignedButtons,
  setShowUnassignedButtons,
}) => {
  const { selectedPrimerPatient, setSelectedPrimerPatient, patientsData } = usePatientStore();

  // Replace selectedAppointment checks with selectedPrimerPatient
  const isSelected = (appointment) => 
    selectedPrimerPatient && 
    selectedPrimerPatient.id === appointment.patient_id &&
    selectedPrimerPatient.appointmentTime === appointment.time;

  const handleAppointmentClick = (appointment) => {
    if (isSelected(appointment)) {
      setSelectedPrimerPatient(null);
    } else {
      setSelectedPrimerPatient({
        ...patientsData[appointment.patient_id],
        appointmentTime: appointment.time,
        appointmentType: appointment.type,
        id: appointment.patient_id
      });
    }
  };

  const formatTimeToAMPM = (time) => {
    const [hours, minutes] = time.split(':');
    const hoursNum = parseInt(hours, 10);
    const formattedHours = hoursNum % 12 || 12;
    return `${formattedHours}:${minutes}`;  // Removed space before AM/PM
  };

  const getPastelColor = (hexColor) => {
    let r = parseInt(hexColor.slice(1, 3), 16);
    let g = parseInt(hexColor.slice(3, 5), 16);
    let b = parseInt(hexColor.slice(5, 7), 16);

    r = Math.floor((r + 255) / 2);
    g = Math.floor((g + 255) / 2);
    b = Math.floor((b + 255) / 2);

    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };

  const getDarkTextColor = (hexColor) => {
    let r = parseInt(hexColor.slice(1, 3), 16);
    let g = parseInt(hexColor.slice(3, 5), 16);
    let b = parseInt(hexColor.slice(5, 7), 16);

    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    return luminance > 0.5 ? '#000000' : '#333333';
  };

  const [userData] = useState(() => 
    JSON.parse(localStorage.getItem('userData') || '{}')
  );
  const appointmentTypes = useMemo(() => {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    
    const types = userData.user?.settings?.appointmentTypes || {};
    return types;
  }, []);

  const [hoveredSlot, setHoveredSlot] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState('');

  const handleSlotClick = (slot) => {
    if (slot.type === 'Free Time' || slot.type === 'Block') {
      // If clicking the same slot that's already selected, deselect it
      if (selectedSlot === slot) {
        setSelectedSlot(null);
        setSelectedFreeSlot(null);
        setSelectedStartTime('');
        setAppointmentDuration(null);
      } else {
        // Clear previous selection and set new one
        setSelectedSlot(slot);
        setSelectedFreeSlot(slot);
        setSelectedStartTime('');
        setAppointmentDuration(null);
      }
    } else {
      // If clicking a non-free time slot, clear any selected free time
      setSelectedSlot(null);
      setSelectedFreeSlot(null);
      setSelectedStartTime('');
      setAppointmentDuration(null);
      handleAppointmentClick(slot);
    }
  };

  const handleCloseAddAppointment = () => {
    setSelectedSlot(null);
    setSelectedFreeSlot(null);
    setSelectedPatient('');
    setSelectedAppointmentType(null);
    setSelectedStartTime('');
    setAppointmentDuration(null);
    setIsUnassignedAppointment(false);
    setShowUnassignedButtons(true); // Reset this when closing the form
  };

  const handleSlotMouseEnter = (slot) => {
    setHoveredSlot(slot);
    handleAppointmentHover(slot);
  };

  const handleSlotMouseLeave = () => {
    setHoveredSlot(null);
    handleAppointmentLeave();
  };

  const handleUnassignedAppointmentClick = (appointment) => {
    setSelectedPatient(appointment.patient_id);
    setIsUnassignedAppointment(true);
    setShowUnassignedButtons(false);
    // Set the appointment type automatically
    setSelectedAppointmentType({ value: appointment.type, label: appointment.type });
  };

  const handlePatientIdChange = (e) => {
    setSelectedPatient(e.target.value);
    setIsUnassignedAppointment(false);
    setShowUnassignedButtons(false);
  };

  const formatTimeForAPI = (time) => {
    const [hours, minutes] = time.split(':');
    const hour = parseInt(hours, 10);
    const ampm = hour >= 12 ? 'pm' : 'am';
    const formattedHour = hour % 12 || 12;
    return `${formattedHour}:${minutes}${ampm}`;
  };

  const [isAddingPatient, setIsAddingPatient] = useState(false);

  const handleAddPatient = async () => {
    try {
      setIsAddingPatient(true);
      
      const userData = JSON.parse(localStorage.getItem('userData') || '{}');
      const clinicId = userData.user?.clinicId;
      const userId = userData.user?.uid;
      const doctorId = selectedDoctor.value;
      const dateStr = new Date().toLocaleDateString("en-CA"); // Format: YYYY-MM-DD

      if (!clinicId || !doctorId || !userId) {
        throw new Error('Missing clinic, doctor, or user ID');
      }

      const db = getFirestore();
      const clinicDoc = await getDoc(doc(db, 'clinics', clinicId));
      
      if (!clinicDoc.exists()) {
        throw new Error('Clinic not found');
      }

      const installationId = clinicDoc.data().installationId;

      if (!clinicId || !installationId) {
        throw new Error('Missing clinic or installation ID');
      }

      if (isUnassignedAppointment) {
        console.log('Processing unassigned appointment...');
        const missionBriefRef = doc(db, `clinics/${clinicId}/missionBrief/${doctorId}/appointments/${selectedPatient}`);
        
        const [hours, minutes] = selectedStartTime.split(':');
        const appointmentDate = new Date(dateStr);
        appointmentDate.setHours(parseInt(hours, 10));
        appointmentDate.setMinutes(parseInt(minutes, 10));
        const appointmentTimestamp = Timestamp.fromDate(appointmentDate);

        console.log('Updating missionBrief with:', {
          path: `clinics/${clinicId}/missionBrief/${doctorId}/appointments/${selectedPatient}`,
          appointmentTime: appointmentDate.toLocaleString(),
        });

        await updateDoc(missionBriefRef, {
          appointmentTime: appointmentTimestamp
        });
        console.log('MissionBrief update successful');

        const noteRef = doc(db, `clinics/${clinicId}/users/${userId}/scribe/${dateStr}/dailyNotes/${selectedPatient}`);
        console.log('Updating dailyNotes with:', {
          path: `clinics/${clinicId}/users/${userId}/scribe/${dateStr}/dailyNotes/${selectedPatient}`,
          appointmentTime: appointmentDate.toLocaleString(),
        });

        await updateDoc(noteRef, {
          appointmentTime: appointmentTimestamp
        });

        console.log('DailyNotes update successful');

        await refreshFirestoreData();
        console.log('Firestore data refreshed');
        
        handleCloseAddAppointment();
        console.log('Appointment update completed successfully');
        return;
      }

      // Existing logic for new appointments
      const action = isUnassignedAppointment ? 'assign_patient' : 'add_new';
      const formattedStartTime = formatTimeForAPI(selectedStartTime);

      const data = {
        clinic_id: clinicId,
        installation_id: installationId,
        pims_id: selectedPatient,
        patient_id: selectedPatient,
        start_time: formattedStartTime,
        appointment_type: selectedAppointmentType.value,
        duration: appointmentDuration,
        doctor_id: doctorId,
        action: action
      };

      if (!clinicId || !installationId || !selectedPatient || !formattedStartTime || 
          !selectedAppointmentType.value || !appointmentDuration || !doctorId) {
        throw new Error('Missing required fields');
      }

      const response = await fetch('https://us-central1-lyravet-ac8ca.cloudfunctions.net/add_primer_pt', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} ${response.statusText}. Error: ${errorText}`);
      }

      const result = await response.json();
      console.log('Patient added successfully:', result);

      await refreshFirestoreData();

      const newAppointment = {
        patient_id: selectedPatient,
        time: selectedStartTime,
        type: selectedAppointmentType.value
      };

      handleAppointmentClick(newAppointment);
      handleCloseAddAppointment();
    } catch (error) {
      console.error('Error adding/updating patient:', error);
    } finally {
      setIsAddingPatient(false);
    }
  };

  const isFormComplete = selectedPatient && selectedAppointmentType && selectedStartTime && appointmentDuration;

  const formatDuration = (minutes) => {
    if (minutes < 60) {
      return `${minutes} minutes`;
    } else {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      if (remainingMinutes === 0) {
        return `${hours} hour${hours > 1 ? 's' : ''}`;
      } else {
        return `${hours} hour${hours > 1 ? 's' : ''} ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
      }
    }
  };

  const dynamicTitle = useMemo(() => {
    const parts = [];
    if (selectedPatient) {
      parts.push(selectedPatient);
    }
    if (selectedStartTime) {
      parts.push(`@ ${formatTimeToAMPM(selectedStartTime)}`);
    }
    if (appointmentDuration) {
      parts.push(`(${formatDuration(appointmentDuration)})`);
    }
    if (selectedAppointmentType) {
      parts.push(`| ${selectedAppointmentType.value}`);
    }
    return parts.join(' ');
  }, [selectedPatient, selectedStartTime, appointmentDuration, selectedAppointmentType]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '5px',
      border: '1px solid #ccc',
      boxShadow: 'none',
      minHeight: '38px',
      '&:hover': {
        border: '1px solid #3B82F6',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#3B82F6' : state.isFocused ? '#93C5FD' : 'white',
      color: state.isSelected ? 'white' : 'inherit',
      cursor: 'pointer',
      '&:active': {
        backgroundColor: state.isSelected ? '#3B82F6' : '#93C5FD',
      },
    }),
  };

  const inputStyles = {
    width: '100%',
    height: '38px',
    padding: '2px 8px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
    fontSize: '14px',
    '&::placeholder': {
      fontSize: '8px',
      color: '#757575',
    },
    '&:hover': {
      border: '1px solid #3B82F6',
    },
    '&:focus': {
      outline: 'none',
      border: '1px solid #3B82F6',
      boxShadow: '0 0 0 1px #3B82F6',
    },
  };

  const [selectedPrimerCard] = useState(null);


  const [currentBlock, setCurrentBlock] = useState(null);

  useEffect(() => {
    const parseTime = (timeStr) => {
      // Remove any spaces
      timeStr = timeStr.replace(/\s/g, '');
      
      // Handle 24-hour format
      if (timeStr.match(/^\d{2}:\d{2}$/)) {
        const [hours, minutes] = timeStr.split(':').map(Number);
        return { hours, minutes };
      }
      
      // Handle am/pm format
      const match = timeStr.match(/^(\d{1,2}):(\d{2})(am|pm)$/i);
      if (match) {
        let hours = parseInt(match[1], 10);
        const minutes = parseInt(match[2], 10);
        const isPM = match[3].toLowerCase() === 'pm';
        
        if (isPM && hours !== 12) hours += 12;
        if (!isPM && hours === 12) hours = 0;
        
        return { hours, minutes };
      }
      
      return { hours: NaN, minutes: NaN };
    };
  
    const findCurrentBlock = () => {
      const now = new Date();
      const currentTime = now.getHours() * 60 + now.getMinutes();
        
      for (let slot of combinedSchedule) {
        const { hours, minutes } = parseTime(slot.time);
        const slotStartTime = hours * 60 + minutes;
        const slotEndTime = slotStartTime + slot.duration;
  
        if (currentTime >= slotStartTime && currentTime < slotEndTime) {
          setCurrentBlock(slot);
          return;
        }
      }
      setCurrentBlock(null);
    };
  
    findCurrentBlock();
    const intervalId = setInterval(findCurrentBlock, 5000);
    return () => clearInterval(intervalId);
  }, [combinedSchedule]);

  const renderAppointmentBlock = (slot, index) => {
    const width = `${(slot.duration / totalSlots) * 100}%`;
    let backgroundColor = '#e0e0e0';
    let className = 'schedule-slot';

    // Add selected class if this slot is the selected one
    if (selectedSlot === slot) {
      className += ' selected';
    }

    // Filter out Block and Free Time from appointments array
    const validAppointments = slot.appointments?.filter(app => 
      app.type !== 'Block' && app.type !== 'Free Time'
    ) || [];

    if (validAppointments.length > 0) {
      // If we have valid appointments, use those
      return (
        <MultiAppointmentBlock 
          key={index}
          appointments={validAppointments.map(app => ({
            ...app,
            time: slot.time,
            duration: slot.duration / validAppointments.length
          }))}
          slot={slot}
          handleSlotMouseEnter={handleSlotMouseEnter}
          handleSlotMouseLeave={handleSlotMouseLeave}
          handleSlotClick={handleSlotClick}
          totalSlots={totalSlots}
          appointmentTypesData={appointmentTypesData}
          clickedCategory={clickedCategory}
          hoveredCategory={hoveredCategory}
          selectedPrimerCard={selectedPrimerCard}
          getPatientName={getPatientName}  // Add this line
          combinedSchedule={combinedSchedule}
        />
      );
    }

    // Handle single blocks or free time slots
    if (slot.type === 'Block') {
      className += ' blocked';
      backgroundColor = '#A9A9A9';
    } else if (slot.type === 'Free Time') {
      className += ' free-time';
      backgroundColor = undefined;
    } else if (slot.type !== 'Free Time') {
      backgroundColor = slot.appointmentColor || '#A5B5BD';  // Use the slot's appointmentColor
    }

    const isHighlighted = 
      (hoveredCategory && slot.appointmentMacroGroup === hoveredCategory) ||
      (hoveredAppointment &&
        (slot.patient_id === hoveredAppointment.patient_id &&
        slot.time === hoveredAppointment.time)
      ) ||
      (selectedPrimerPatient &&
        (slot.patient_id === selectedPrimerPatient.id &&
        slot.time === selectedPrimerPatient.appointmentTime)
      );

    const isCurrentBlock = currentBlock && slot.time === currentBlock.time;

    // Update the opacity logic for filtering
    const opacity = clickedCategory && slot.appointmentMacroGroup !== clickedCategory ? 0.3 : 1;

    return (
      <div
        key={index}
        className={`${className} ${isHighlighted ? 'highlight' : ''} ${isCurrentBlock ? 'current-block' : ''}`}
        style={{
          width,
          backgroundColor,
          position: 'relative',
          transform: isHighlighted ? 'scale(1.15)' : 'scale(1)',
          boxShadow: isHighlighted 
            ? '0 8px 16px rgba(59, 130, 246, 0.25)'
            : '0 2px 4px rgba(0, 0, 0, 0.1)',
          transition: 'all 0.3s ease',
          zIndex: isHighlighted ? 2 : 1,
          opacity: opacity
        }}
        onMouseEnter={() => handleSlotMouseEnter(slot)}
        onMouseLeave={handleSlotMouseLeave}
        onClick={() => handleSlotClick(slot)}
      >
        {isHighlighted && <StarDecoration color={backgroundColor} />}
        {isCurrentBlock && <div className="current-block-indicator"></div>}
        {(slot.type === 'Free Time' || slot.type === 'Block') && hoveredSlot === slot && (
          <div className="add-appointment-icon">+</div>
        )}
        {hoveredSlot === slot && (
          <div className="tooltip">
            {slot.type === 'Block' ? (
              <>
                <strong>Blocked Time</strong> @ {formatTimeToAMPM(slot.time)}
                <br />
                <em>Not available for appointments</em>
              </>
            ) : slot.type === 'Free Time' ? (
              <>
                <strong>Free Time</strong> @ {formatTimeToAMPM(slot.time)}
                <br />
                <em>Available for appointments</em>
              </>
            ) : (
              <>
                <strong>{getPatientName(slot.patient_id)}</strong> @ {formatTimeToAMPM(slot.time)}
                <br />
                <em>{slot.type}</em>
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  const availableAppointmentTypes = useMemo(() => 
    Object.entries(appointmentTypes).map(([type, data]) => ({
      value: type,
      label: type,
      color: data.color
    }))
  , [appointmentTypes]);

  const renderAppointmentTypeSelect = () => (
    <Select
      id="appointment-type"
      value={selectedAppointmentType}
      onChange={(option) => setSelectedAppointmentType(option)}
      options={availableAppointmentTypes}
      placeholder="Select Type"
      styles={customStyles}
      isDisabled={isUnassignedAppointment}
    />
  );

  // Add new state variables for time selection
  const [selectedHour, setSelectedHour] = useState(null);
  const [availableMinutes, setAvailableMinutes] = useState([]);

  const parseTimeString = (timeStr) => {
    // Remove any spaces
    timeStr = timeStr.replace(/\s/g, '');
    
    // Parse time in format "H:MMam" or "H:MMpm"
    const match = timeStr.match(/^(\d{1,2}):(\d{2})(am|pm)$/i);
    if (!match) return null;
    
    let hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);
    const isPM = match[3].toLowerCase() === 'pm';
    
    // Convert to 24-hour format
    if (isPM && hours !== 12) hours += 12;
    if (!isPM && hours === 12) hours = 0;
    
    return { hours, minutes };
  };

  const generateAvailableHours = useCallback((slot) => {
    console.log('Generating hours for slot:', slot);
    if (!slot) return [];
    
    const parsedTime = parseTimeString(slot.time);
    if (!parsedTime) {
      console.error('Could not parse time:', slot.time);
      return [];
    }
    
    const { hours: startHour, minutes: startMinute } = parsedTime;
    console.log('Parsed start time:', { startHour, startMinute });
    
    // Calculate end time
    const endTimeDate = new Date(2000, 0, 1, startHour, startMinute + slot.duration);
    const endHour = endTimeDate.getHours();
    
    console.log('Calculated end time:', { endHour, fullEndTime: endTimeDate });

    const hours = new Set();
    for (let hour = startHour; hour <= endHour; hour++) {
      // Only include hours within workday (8am-8pm)
      if (hour >= 8 && hour <= 20) {
        hours.add(hour);
      }
    }

    const result = Array.from(hours).sort((a, b) => a - b).map(hour => ({
      value: hour,
      label: `${hour > 12 ? hour - 12 : hour}${hour >= 12 ? 'pm' : 'am'}`
    }));
    
    console.log('Generated hours:', result);
    return result;
  }, []);

  const generateAvailableMinutes = useCallback((selectedHour, slot) => {
    if (!selectedHour || !slot) return [];
    
    const parsedTime = parseTimeString(slot.time);
    if (!parsedTime) return [];
    
    const { hours: startHour, minutes: startMinute } = parsedTime;
    const endTimeDate = new Date(2000, 0, 1, startHour, startMinute + slot.duration);
    const endHour = endTimeDate.getHours();
    const endMinute = endTimeDate.getMinutes();
    
    const hour = parseInt(selectedHour, 10);
    const minutes = [];
    
    // Generate minutes in 5-minute increments
    for (let min = 0; min < 60; min += 5) {
      // For start hour, only include minutes after start time
      if (hour === startHour && min < startMinute) continue;
      // For end hour, only include minutes before end time
      if (hour === endHour && min >= endMinute) continue;
      minutes.push(min);
    }

    return minutes.map(minute => ({
      value: minute,
      label: minute.toString().padStart(2, '0')
    }));
  }, []);

  // Generate available duration minutes based on selected start time
  const generateAvailableDurationMinutes = useCallback((startTime, slot) => {
    console.log('Generating durations for:', { startTime, slot });
    if (!startTime || !slot) return [];
    
    // Parse selected start time
    const [selectedHour, selectedMinute] = startTime.split(':').map(Number);
    const selectedDateTime = new Date(2000, 0, 1, selectedHour, selectedMinute);
    
    // Find the next appointment in combinedSchedule
    const nextAppointment = combinedSchedule.find(scheduleSlot => {
      if (scheduleSlot.type === 'Free Time' || scheduleSlot.type === 'Block') return false;
      
      const parsedTime = parseTimeString(scheduleSlot.time);
      if (!parsedTime) return false;
      
      const slotDateTime = new Date(2000, 0, 1, parsedTime.hours, parsedTime.minutes);
      return slotDateTime > selectedDateTime;
    });
    
    console.log('Next appointment:', nextAppointment);

    // Calculate maximum available minutes
    let maxMinutes;
    if (nextAppointment) {
      // If there's a next appointment, calculate minutes until that appointment
      const nextTime = parseTimeString(nextAppointment.time);
      const nextDateTime = new Date(2000, 0, 1, nextTime.hours, nextTime.minutes);
      maxMinutes = Math.floor((nextDateTime - selectedDateTime) / (1000 * 60));
    } else {
      // If no next appointment, calculate minutes until end of workday (8pm)
      const endOfDay = new Date(2000, 0, 1, 20, 0); // 8pm
      maxMinutes = Math.floor((endOfDay - selectedDateTime) / (1000 * 60));
    }

    console.log('Maximum available minutes:', maxMinutes);

    // Generate durations in 5-minute increments
    const durations = [];
    for (let min = 5; min <= maxMinutes; min += 5) {
      durations.push({
        value: min,
        label: formatDuration(min)
      });
    }

    console.log('Generated durations:', durations);
    return durations;
  }, [combinedSchedule]);

  // Handler functions
  const handleHourSelect = (option) => {
    setSelectedHour(option.value);
    setSelectedStartTime(''); // Clear the full time when hour changes
    setAppointmentDuration(null); // Reset duration
  };

  const handleMinuteSelect = (option) => {
    const timeString = `${selectedHour.toString().padStart(2, '0')}:${option.value.toString().padStart(2, '0')}`;
    setSelectedStartTime(timeString);
    setAppointmentDuration(null); // Reset duration when time changes
  };

  const handleDurationSelect = (option) => {
    setAppointmentDuration(option.value);
  };

  // Update the render section
  const renderTimeSelectors = () => (
    <div className="time-selectors">
      <div className="form-field">
        <label htmlFor="hour-select">Start Hour</label>
        <Select
          id="hour-select"
          value={selectedHour ? { 
            value: selectedHour,
            label: `${selectedHour > 12 ? selectedHour - 12 : selectedHour}${selectedHour >= 12 ? 'pm' : 'am'}`
          } : null}
          onChange={handleHourSelect}
          options={generateAvailableHours(selectedSlot)}
          placeholder="Select Hour"
          styles={customStyles}
        />
      </div>
      
      <div className="form-field">
        <label htmlFor="minute-select">Start Minute</label>
        <Select
          id="minute-select"
          value={selectedStartTime ? {
            value: parseInt(selectedStartTime.split(':')[1], 10),
            label: selectedStartTime.split(':')[1]
          } : null}
          onChange={handleMinuteSelect}
          options={generateAvailableMinutes(selectedHour, selectedSlot)}
          placeholder="Select Minute"
          isDisabled={!selectedHour}
          styles={customStyles}
        />
      </div>
    </div>
  );

  const renderDurationSelector = () => (
    <div className="form-field duration-field">
      <label htmlFor="duration">Duration</label>
      <Select
        id="duration"
        value={appointmentDuration ? { 
          value: appointmentDuration,
          label: formatDuration(appointmentDuration)
        } : null}
        onChange={handleDurationSelect}
        options={generateAvailableDurationMinutes(selectedStartTime, selectedSlot)}
        placeholder="Select Duration"
        isDisabled={!selectedStartTime}
        styles={customStyles}
      />
    </div>
  );

  // Add this inside the component, before it's used
  const calculateAppointmentTypeCounts = useMemo(() => {
    const macroGroupCounts = {};
    
    const allAppointments = [
      ...combinedSchedule.flatMap(slot => {
        // If the slot has multiple appointments, include each one
        if (slot.appointments && slot.appointments.length > 0) {
          return slot.appointments;
        }
        // Otherwise include the slot itself if it's a regular appointment
        if (slot.type !== 'Free Time' && slot.type !== 'Block') {
          return [slot];
        }
        return [];
      }),
      ...(unassignedAppointments || [])
    ];
    
    allAppointments.forEach((slot) => {
      if (slot.type !== 'Free Time' && slot.type !== 'Block') {
        const macroGroup = slot.appointmentMacroGroup || 'Other';
        
        if (!macroGroupCounts[macroGroup]) {
          macroGroupCounts[macroGroup] = {
            type: macroGroup,
            count: 0,
            color: slot.appointmentColor,
            appointments: [],
          };
        }
        macroGroupCounts[macroGroup].count += 1;
        macroGroupCounts[macroGroup].appointments.push(slot);
      }
    });

    return Object.values(macroGroupCounts).sort((a, b) => b.count - a.count);
  }, [combinedSchedule, unassignedAppointments]);

  const getPatientName = useCallback((patientId) => {
    // First check patientMockData
    if (patientMockData[patientId]?.name) {
      return patientMockData[patientId].name;
    }

    // Fall back to checking missionBrief if not found in patientMockData
    const doctorId = selectedDoctor?.value;
    if (!doctorId || !userData.user?.missionBrief?.[doctorId]) return patientId;

    const appointment = userData.user.missionBrief[doctorId].appointments.find(
      apt => apt.patientData?.patientId === patientId
    );

    return appointment?.patientData?.name || patientId;
  }, [selectedDoctor, userData, patientMockData]);

  // Replace the existing handleCategoryButtonClick implementation
  const handleCategoryButtonClick = (category) => {
    if (category === clickedCategory) {
      // Don't clear selectedPrimerPatient when deselecting category
      handleCategoryClick(null);
      return;
    }

    // Flatten all appointments from combinedSchedule
    const allAppointments = combinedSchedule.reduce((acc, slot) => {
      if (slot.appointments) {
        // Add all appointments from multi-appointment slots
        return [...acc, ...slot.appointments];
      } else if (slot.type !== 'Free Time' && slot.type !== 'Block') {
        // Add single appointments
        return [...acc, slot];
      }
      return acc;
    }, []);

    // Find first matching appointment
    const firstMatchingAppointment = allAppointments.find(app => 
      app.appointmentMacroGroup === category
    );

    if (firstMatchingAppointment) {
      setSelectedPrimerPatient({
        ...patientsData[firstMatchingAppointment.patient_id],
        appointmentTime: firstMatchingAppointment.time,
        appointmentType: firstMatchingAppointment.type,
        id: firstMatchingAppointment.patient_id
      });
    }

    handleCategoryClick(category);
  };

  return (
    <div className="appointment-container">
      <div className="appointment-header">
        <div className="header-top">
          <div className="current-datetime">
            <span className="date">{formatDateTime().date}</span>
            <span className="time">{formatDateTime().time}</span>
          </div>
          <div className="schedule-instruction">
            Click on free time or blocked slots to add a new appointment
          </div>
          <div className="appointment-type-buttons">
            {calculateAppointmentTypeCounts.map((macroGroup, index) => {
              const pastelColor = getPastelColor(macroGroup.color);
              const textColor = getDarkTextColor(pastelColor);
              
              return (
                <button
                  key={index}
                  className="appointment-type-button"
                  style={{
                    backgroundColor: pastelColor,
                    color: textColor,
                    transform: (hoveredCategory === macroGroup.type || clickedCategory === macroGroup.type)
                      ? 'scale(1.15)'
                      : 'scale(1)',
                    boxShadow: (hoveredCategory === macroGroup.type || clickedCategory === macroGroup.type)
                      ? '0 8px 16px rgba(59, 130, 246, 0.25)'
                      : '0 2px 4px rgba(0, 0, 0, 0.1)',
                    transition: 'all 0.3s ease',
                    opacity: clickedCategory && clickedCategory !== macroGroup.type ? 0.3 : 1
                  }}
                  onMouseEnter={() => {
                    handleBarChartMouseEnter(macroGroup.type);
                    handleCategoryHover(macroGroup.type);
                  }}
                  onMouseLeave={() => {
                    handleBarChartMouseLeave();
                    handleCategoryHover(null);
                  }}
                  onClick={() => handleCategoryButtonClick(macroGroup.type)}
                >
                  {`${macroGroup.type}: ${macroGroup.count}`}
                </button>
              );
            })}
          </div>
        </div>
      </div>

      <div className="appointment-schedule-container">
        <div className="appointment-schedule">
          {combinedSchedule.map((slot, index) => renderAppointmentBlock(slot, index))}
        </div>
        <div className="time-ticks">
          {Array.from(
            { length: workdayEndHour - workdayStartHour + 1 },
            (_, i) => {
              const hour = workdayStartHour + i;
              const displayHour = hour > 12 ? hour - 12 : hour;
              const period = hour >= 12 ? 'pm' : 'am';
              return (
                <div key={i} className="time-tick">
                  {displayHour}
                  {period}
                </div>
              );
            }
          )}
        </div>
      </div>

      <div className="add-appointment-section">
        {selectedSlot && (
          <div className="add-appointment-form">
            <button className="close-btn" onClick={handleCloseAddAppointment}>×</button>
            {dynamicTitle && (
              <h3 className="dynamic-title">{dynamicTitle}</h3>
            )}
            {showUnassignedButtons && (
              <div className="unassigned-appointments">
                {unassignedAppointments.map((appointment, index) => {
                  const patientName = patientMockData[appointment.patient_id]?.name || 'Unknown';
                  return (
                    <button
                      key={index}
                      className="unassigned-appointment-btn"
                      onClick={() => handleUnassignedAppointmentClick(appointment)}
                    >
                      {appointment.patient_id} - {patientName} - {appointment.type}
                    </button>
                  );
                })}
              </div>
            )}
            <div className="form-fields">
              <div className="form-field">
                <label htmlFor="patient-id">Patient-ID</label>
                <input
                  type="text"
                  id="patient-id"
                  value={selectedPatient}
                  onChange={handlePatientIdChange}
                  placeholder="Enter Patient-ID"
                  style={inputStyles}
                  disabled={isUnassignedAppointment}
                />
              </div>
              <div className="form-field">
                <label htmlFor="appointment-type">Appointment Type</label>
                {renderAppointmentTypeSelect()}
              </div>
              {renderTimeSelectors()}
              {renderDurationSelector()}
            </div>
            {isUnassignedAppointment ? (
              <button 
                className={`submit-btn ${isFormComplete && !isAddingPatient ? '' : 'disabled'}`}
                disabled={!isFormComplete || isAddingPatient}
                onClick={handleAddPatient}
                style={{ backgroundColor: isAddingPatient ? '#FBBF24' : '' }}
              >
                {isAddingPatient 
                  ? `Adding ${patientMockData[selectedPatient]?.name || selectedPatient}...` 
                  : `Add ${patientMockData[selectedPatient]?.name || selectedPatient}`}
              </button>
            ) : (
              <button 
                className={`submit-btn ${isFormComplete && !isAddingPatient ? '' : 'disabled'}`}
                disabled={!isFormComplete || isAddingPatient}
                onClick={handleAddPatient}
                style={{ backgroundColor: isAddingPatient ? '#FBBF24' : '' }}
              >
                {isAddingPatient ? 'Adding Patient...' : 'Add Patient'}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AppointmentGuide;