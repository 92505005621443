import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import {
  XMarkIcon,
  PlusIcon,
  CheckCircleIcon,
  ClockIcon,
  ExclamationCircleIcon,
  ChevronDownIcon,
  TrashIcon,
  ChevronUpDownIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import { format } from "date-fns";
import { db } from "../config/firebase";
import {
  collection,
  query,
  orderBy,
  addDoc,
  updateDoc,
  doc,
  onSnapshot,
  where,
  Timestamp,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { useUser } from "../App";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Combobox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/datepicker-custom.css";
import { usePatientStore } from "../stores/patientStore";

// Utility function to check if a date is today
const isToday = (date: Date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

// Utility function to get priority color classes
const getPriorityColor = (priority: string) => {
  switch (priority) {
    case "high":
      return "text-red-400";
    case "medium":
      return "text-yellow-400";
    case "low":
      return "text-green-400";
    default:
      return "text-white/60";
  }
};

interface Task {
  id: string;
  title: string;
  description?: string;
  status: string;
  priority: "low" | "medium" | "high";
  dueDate: Date;
  createdAt: Date;
  lastModified: Date;
  patientId: string;
  patientName: string;
  AITask?: boolean;
  editing?: boolean;
}

interface TasksPanelProps {
  isOpen: boolean;
  onToggle: () => void;
  isChatOpen: boolean;
}

// Update the interface for the new task state
interface NewTask {
  title: string;
  description?: string;
  dueDate: Date;
  priority: "low" | "medium" | "high";
  patientId: string;
  patientName: string;
}

// Update TaskSection props interface
interface TaskSectionProps {
  title: string;
  tasks: Task[];
  onToggle: (id: string, status: string) => void;
  onDelete: (id: string) => void;
  onEdit: (task: Task) => void;
}

// Update TaskSection component
const TaskSection: React.FC<TaskSectionProps> = ({
  title,
  tasks,
  onToggle,
  onDelete,
  onEdit,
}) => {
  return (
    <div className="mb-8">
      <h3 className="text-lg font-semibold text-[#0D1140] mb-4">{title}</h3>
      {tasks.length > 0 ? (
        <div className="grid grid-cols-2 gap-4">
          {tasks.map((task) => (
            <TaskItem
              key={task.id}
              task={task}
              onToggle={onToggle}
              onDelete={onDelete}
              onEdit={onEdit}
            />
          ))}
        </div>
      ) : (
        <p className="text-gray-500 text-sm italic">No tasks to display</p>
      )}
    </div>
  );
};

// Add interface for TaskSections props
interface TaskSectionsProps {
  tasks: Task[];
  onToggle: (id: string, status: string) => void;
  onDelete: (id: string) => void;
  onEdit: (task: Task) => void;
}

// Add a type for priority groups
type PriorityGroup = {
  title: string;
  tasks: Task[];
};

// Update the TaskSections component
const TaskSections: React.FC<TaskSectionsProps> = ({
  tasks,
  onToggle,
  onDelete,
  onEdit,
}) => {
  const today = new Date();
  const weekAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);

  const completedTasks = tasks.filter(
    (task) =>
      task.status === "completed" && new Date(task.lastModified) >= weekAgo
  );

  const inProgressTasks = tasks.filter((task) => task.status === "pending");
  const priorityGroups = [
    {
      title: "High Priority",
      tasks: inProgressTasks.filter((task) => task.priority === "high"),
    },
    {
      title: "Medium Priority",
      tasks: inProgressTasks.filter((task) => task.priority === "medium"),
    },
    {
      title: "Low Priority",
      tasks: inProgressTasks.filter((task) => task.priority === "low"),
    },
  ];

  return (
    <div className="absolute inset-0 flex flex-col">
      {/* Scrollable Priority Groups */}
      <div className="flex-1 overflow-y-auto px-6 pb-4">
        {priorityGroups.map(
          (group) =>
            group.tasks.length > 0 && (
              <div key={group.title} className="mb-6">
                <h3 className="text-sm font-medium text-gray-500 mb-3">
                  {group.title}
                </h3>
                <div className="grid grid-cols-2 gap-4 auto-rows-max bg-[#FCFAF7]">
                  {group.tasks.map((task) => (
                    <TaskItem
                      key={task.id}
                      task={task}
                      onToggle={onToggle}
                      onDelete={onDelete}
                      onEdit={onEdit}
                    />
                  ))}
                </div>
              </div>
            )
        )}
      </div>

      {/* Fixed Completed Tasks Section */}
      <div className="sticky bottom-0 bg-[#f8fafc] border-t border-gray-100">
        <div className="px-6 pt-4">
          <h2 className="text-lg font-semibold text-[#0D1140] mb-4">
            Recently Completed
          </h2>
          <div className="overflow-x-auto pb-4">
            <div className="flex gap-4" style={{ minWidth: "min-content" }}>
              {completedTasks.map((task) => (
                <div key={task.id} className="w-72 flex-shrink-0">
                  <TaskItem
                    key={task.id}
                    task={task}
                    onToggle={onToggle}
                    onDelete={onDelete}
                    onEdit={onEdit}
                  />
                </div>
              ))}
              {completedTasks.length === 0 && (
                <p className="text-gray-500 text-sm italic">
                  No completed tasks
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Add interface for patient data structure
interface Patient {
  id: string;
  name: string;
  pimsId?: string;
}

interface PatientOption {
  id: string;
  name: string;
  pimsId?: string;
}

// Utility function to round date to the nearest 15 minutes
const roundToNearest15Minutes = (date: Date) => {
  const minutes = 15;
  const ms = 1000 * 60 * minutes;
  return new Date(Math.round(date.getTime() / ms) * ms);
};

// Utility function to get next 15 minute interval
const getNextInterval = () => {
  const date = new Date();
  const minutes = date.getMinutes();
  const remainder = minutes % 15;
  // Add the remaining minutes to get to the next 15-minute interval
  date.setMinutes(minutes + (15 - remainder));
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

// Update the AISuggestions component
const AISuggestions: React.FC<{
  tasks: Task[];
  onAddTask: (task: Task) => void;
  onDelete: (id: string) => void;
}> = ({ tasks, onAddTask, onDelete }) => {
  const aiTasks = tasks
    .filter((task) => task.AITask && task.status !== "completed")
    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

  if (aiTasks.length === 0) return null;

  return (
    <div className="flex-shrink-0 px-6 py-4 border-b border-gray-100/80">
      <h3 className="text-sm font-medium text-gray-500 mb-3">AI Suggestions</h3>
      <div className="overflow-x-auto">
        {/* Add max-height and scrollbar styles */}
        <div 
          className="flex gap-3 pb-2 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent" 
          style={{ 
            maxHeight: '200px',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            WebkitOverflowScrolling: 'touch'
          }}
        >
          {aiTasks.map((task) => (
            <div key={task.id} className="flex-shrink-0 w-72">
              <div className="bg-white/90 p-4 rounded-lg border border-cosmic-cyan/30 shadow-sm">
                <div className="flex items-start justify-between mb-2">
                  <h4 className="text-sm font-medium text-gray-800 flex-1 mr-2">
                    {task.title}
                  </h4>
                  <div className="flex gap-2 flex-shrink-0">
                    <button
                      onClick={() => onAddTask(task)}
                      className="p-1 text-cosmic-cyan hover:bg-cosmic-cyan/10 rounded"
                    >
                      <PlusIcon className="w-4 h-4" />
                    </button>
                    <button
                      onClick={() => onDelete(task.id)}
                      className="p-1 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded"
                    >
                      <XMarkIcon className="w-4 h-4" />
                    </button>
                  </div>
                </div>
                {task.description && (
                  <p className="text-sm text-gray-600 line-clamp-2">
                    {task.description}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// Add new interface for edit state
interface EditingTask {
  id: string;
  title: string;
  description?: string;
  priority: "low" | "medium" | "high";
  dueDate: Date;
}

const TasksPanel: React.FC<TasksPanelProps> = ({
  isOpen,
  onToggle,
  isChatOpen,
}) => {
  const { user } = useUser();
  const [userData] = useState(() =>
    JSON.parse(localStorage.getItem("userData") || "{}")
  );
  const clinicId = userData.user?.clinicId;
  const [tasks, setTasks] = useState<Task[]>([]);
  const [showNewTaskForm, setShowNewTaskForm] = useState(false);
  const [newTask, setNewTask] = useState<NewTask>({
    title: "",
    dueDate: getNextInterval(),
    priority: "medium",
    patientId: "",
    patientName: "",
  });
  const [isCompletedExpanded, setIsCompletedExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [patientQuery, setPatientQuery] = useState("");
  const [selectedPatientOption, setSelectedPatientOption] =
    useState<PatientOption | null>(null);
  const [isManualInput, setIsManualInput] = useState(false);
  const [editingTask, setEditingTask] = useState<EditingTask | null>(null);

  // Get available patients from localStorage
  const availablePatients = useMemo(() => {
    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    const missionBrief = userData.user?.missionBrief || {};

    const uniquePatients = new Set<string>();
    const patients: Patient[] = [];

    Object.values(missionBrief).forEach((resource: any) => {
      if (resource.appointments) {
        resource.appointments.forEach((appointment: any) => {
          const patientData = appointment.patientData;
          if (patientData && !uniquePatients.has(patientData.patientId)) {
            uniquePatients.add(patientData.patientId);
            patients.push({
              id: patientData.patientId,
              name: patientData.name,
              pimsId: patientData.pimsId,
            });
          }
        });
      }
    });

    return patients;
  }, []);

  // Filter patients based on search query
  const filteredPatients = useMemo(() => {
    if (!patientQuery) return availablePatients;

    const searchQuery = patientQuery.toLowerCase();
    return availablePatients.filter((patient) => {
      const patientName = patient.name.toLowerCase();
      const patientId = String(patient.id).toLowerCase();
      return (
        patientName.includes(searchQuery) || patientId.includes(searchQuery)
      );
    });
  }, [availablePatients, patientQuery]);

  // Update the patient selection in newTask
  useEffect(() => {
    if (selectedPatientOption) {
      setNewTask((prev) => ({
        ...prev,
        patientId: selectedPatientOption.id,
        patientName: selectedPatientOption.name,
      }));
    }
  }, [selectedPatientOption]);

  // Add search filter function
  const filterTasks = (tasks: Task[]) => {
    if (!searchQuery) return tasks;

    const query = searchQuery.toLowerCase();
    return tasks.filter(
      (task) =>
        task.title.toLowerCase().includes(query) ||
        task.description?.toLowerCase().includes(query) ||
        task.patientName.toLowerCase().includes(query)
    );
  };

  // Single useEffect for real-time updates
  useEffect(() => {
    if (!user?.uid || !clinicId) return;

    const tasksRef = collection(
      db,
      "clinics",
      clinicId,
      "users",
      user.uid,
      "tasks"
    );
    const q = query(tasksRef, orderBy("lastModified", "desc"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const tasksList = snapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          // Add null checks and fallbacks for date fields
          dueDate: data.dueDate?.toDate() || new Date(),
          createdAt: data.createdAt?.toDate() || new Date(),
          lastModified: data.lastModified?.toDate() || new Date(),
        };
      }) as Task[];

      setTasks(tasksList);
    });

    return () => unsubscribe();
  }, [user?.uid, clinicId]);

  const addTask = async () => {
    console.log("Add task clicked", { user, clinicId, newTask }); // Debug log

    if (!user?.uid || !clinicId || !newTask.title) {
      console.log("Missing required data:", {
        hasUserId: !!user?.uid,
        hasClinicId: !!clinicId,
        hasTitle: !!newTask.title,
      }); // Debug log
      return;
    }

    try {
      const tasksRef = collection(
        db,
        "clinics",
        clinicId,
        "users",
        user.uid,
        "tasks"
      );

      const taskData = {
        ...newTask,
        status: "pending",
        createdAt: Timestamp.fromDate(new Date()),
        lastModified: Timestamp.fromDate(new Date()),
        dueDate: Timestamp.fromDate(newTask.dueDate),
        patientId: newTask.patientId,
        patientName: newTask.patientName,
      };

      console.log("Attempting to add task with data:", taskData); // Debug log

      await addDoc(tasksRef, taskData);
      console.log("Task added successfully"); // Debug log

      // Reset form with initial values
      resetNewTaskForm();
      setShowNewTaskForm(false);
    } catch (error) {
      console.error("Error adding task:", error);
    }
  };

  // Add this new function to handle form reset
  const resetNewTaskForm = () => {
    const nextInterval = getNextInterval();
    setNewTask({
      title: "",
      description: "",
      dueDate: nextInterval,
      priority: "medium",
      patientId: "",
      patientName: "",
    });
    setSelectedPatientOption(null);
    setPatientQuery("");
  };

  // Update the cancel button click handler
  const handleCancelNewTask = () => {
    resetNewTaskForm();
    setShowNewTaskForm(false);
  };

  const toggleTaskStatus = async (taskId: string, currentStatus: string) => {
    if (!user?.uid || !clinicId) return;

    const taskRef = doc(
      db,
      "clinics",
      clinicId,
      "users",
      user.uid,
      "tasks",
      taskId
    );
    const newStatus = currentStatus === "pending" ? "completed" : "pending";

    try {
      await updateDoc(taskRef, {
        status: newStatus,
        lastModified: new Date(),
      });

      // Update local state
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === taskId
            ? { ...task, status: newStatus, lastModified: new Date() }
            : task
        )
      );
    } catch (error) {
      console.error("Error updating task status:", error);
    }
  };

  const deleteTask = async (taskId: string) => {
    if (!user?.uid || !clinicId) return;

    try {
      const taskRef = doc(
        db,
        "clinics",
        clinicId,
        "users",
        user.uid,
        "tasks",
        taskId
      );
      await deleteDoc(taskRef);
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  // Filter and group tasks
  const today = new Date();
  const weekAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);

  // Update filters to exclude AITask: true
  const completedTasks = tasks.filter(
    (task) =>
      task.status === "completed" &&
      new Date(task.lastModified) >= weekAgo &&
      !task.AITask // Add this condition
  );

  const inProgressTasks = tasks.filter(
    (task) => task.status === "pending" && !task.AITask // Add this condition
  );

  // Update the filtered tasks
  const filteredInProgressTasks = filterTasks(inProgressTasks);
  const filteredCompletedTasks = filterTasks(completedTasks);

  // Update the priority groups with filtered tasks
  const priorityGroups: PriorityGroup[] = [
    {
      title: "High Priority",
      tasks: filteredInProgressTasks.filter((task) => task.priority === "high"),
    },
    {
      title: "Medium Priority",
      tasks: filteredInProgressTasks.filter(
        (task) => task.priority === "medium"
      ),
    },
    {
      title: "Low Priority",
      tasks: filteredInProgressTasks.filter((task) => task.priority === "low"),
    },
  ];

  // Update the patient selection logic
  const renderPatientSelection = () => (
    <div className="relative">
      <Combobox
        value={selectedPatientOption}
        onChange={(newValue) => {
          setSelectedPatientOption(newValue);
          // If a patient is selected from the dropdown
          if (newValue) {
            setNewTask((prev) => ({
              ...prev,
              patientId: newValue.id,
              patientName: newValue.name,
            }));
          }
        }}
      >
        <div className="relative mt-1">
          <div className="relative w-full">
            <Combobox.Input
              className="w-full p-3 bg-white rounded-xl
                       text-gray-700 placeholder-gray-400
                       border border-gray-200
                       focus:outline-none focus:ring-1 focus:ring-cosmic-cyan"
              displayValue={(patient: PatientOption) => {
                // If there's a selected patient, show their name
                if (patient?.name) return patient.name;
                // Otherwise show the manually entered name
                return newTask.patientName;
              }}
              onChange={(event) => {
                const value = event.target.value;
                setPatientQuery(value);
                // Always update the task with the current input
                setNewTask((prev) => ({
                  ...prev,
                  patientId: value,
                  patientName: value,
                }));
                // Clear selected patient when typing
                if (selectedPatientOption) {
                  setSelectedPatientOption(null);
                }
              }}
              onBlur={(event) => {
                // Ensure the value persists when leaving the input
                const value = event.target.value;
                if (!selectedPatientOption && value) {
                  setNewTask((prev) => ({
                    ...prev,
                    patientId: value,
                    patientName: value,
                  }));
                }
              }}
              placeholder="Select or type patient name"
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setPatientQuery("")}
          >
            <Combobox.Options
              className="absolute z-10 mt-1 max-h-60 w-full overflow-auto
                                     rounded-md bg-white py-1 text-base shadow-lg
                                     ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              {filteredPatients.length === 0 && patientQuery !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Creating new patient: "{patientQuery}"
                </div>
              ) : (
                filteredPatients.map((patient) => (
                  <Combobox.Option
                    key={patient.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-cosmic-cyan text-white" : "text-gray-900"
                      }`
                    }
                    value={patient}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {patient.name} ({patient.pimsId})
                        </span>
                        {selected && (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-cosmic-cyan"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );

  const updatePatientTaskStatus = useCallback(
    (patientId: string, hasTasks: boolean) => {
      const userData = JSON.parse(localStorage.getItem("userData") || "{}");
      if (userData?.user?.dailyPrimer?.patients?.patients) {
        const patients = userData.user.dailyPrimer.patients.patients;
        if (patients[patientId]) {
          patients[patientId].hasTasks = hasTasks;
          localStorage.setItem("userData", JSON.stringify(userData));

          // Dispatch event to notify other components
          const refreshEvent = new Event("localStorageUpdated");
          window.dispatchEvent(refreshEvent);
        }
      }
    },
    []
  );

  // Update task status whenever tasks change
  useEffect(() => {
    const patientTaskCounts = tasks.reduce((acc, task) => {
      if (task.status === "pending" && task.patientId) {
        acc[task.patientId] = (acc[task.patientId] || 0) + 1;
      }
      return acc;
    }, {} as Record<string, number>);

    Object.entries(patientTaskCounts).forEach(([patientId, count]) => {
      updatePatientTaskStatus(patientId, count > 0);
    });
  }, [tasks, updatePatientTaskStatus]);

  // Add this new function inside TasksPanel component
  const deleteAllCompletedTasks = async () => {
    if (!user?.uid || !clinicId) return;

    try {
      // Get all completed tasks
      const tasksRef = collection(
        db,
        "clinics",
        clinicId,
        "users",
        user.uid,
        "tasks"
      );
      const q = query(tasksRef, where("status", "==", "completed"));
      const querySnapshot = await getDocs(q);

      // Delete each completed task
      const deletePromises = querySnapshot.docs.map((doc) =>
        deleteDoc(doc.ref)
      );
      await Promise.all(deletePromises);
    } catch (error) {
      console.error("Error deleting completed tasks:", error);
    }
  };

  // For debugging, add this useEffect
  useEffect(() => {
    console.log("Current userData:", userData);
    console.log("Current clinicId:", clinicId);
  }, [userData, clinicId]);

  // Update the addAITaskToTasks function to accept full Task type
  const addAITaskToTasks = async (taskData: Task) => {
    if (!user?.uid || !clinicId) return;

    try {
      const taskRef = doc(
        db,
        "clinics",
        clinicId,
        "users",
        user.uid,
        "tasks",
        taskData.id
      );

      await updateDoc(taskRef, {
        AITask: false,
        status: "pending",
        lastModified: Timestamp.fromDate(new Date()),
      });
    } catch (error) {
      console.error("Error updating AI task:", error);
    }
  };

  // Add cleanup function for old AI tasks
  const cleanupOldAITasks = useCallback(async () => {
    if (!user?.uid || !clinicId) return;

    try {
      const tasksRef = collection(
        db,
        "clinics",
        clinicId,
        "users",
        user.uid,
        "tasks"
      );

      // First get all AI tasks
      const q = query(tasksRef, where("AITask", "==", true));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const cutoffTime = new Date(Date.now() - 24 * 60 * 60 * 1000);

        // Then filter locally for old tasks
        const oldTasks = querySnapshot.docs.filter((doc) => {
          const createdAt = doc.data().createdAt?.toDate();
          return createdAt && createdAt <= cutoffTime;
        });

        // Delete old tasks if any exist
        if (oldTasks.length > 0) {
          const deletePromises = oldTasks.map((doc) => deleteDoc(doc.ref));
          await Promise.all(deletePromises);
        }
      }
    } catch (error) {
      console.error("Error cleaning up old AI tasks:", error);
    }
  }, [user?.uid, clinicId]);

  // Run cleanup on mount and every hour
  useEffect(() => {
    // Initial cleanup
    cleanupOldAITasks();

    // Set up interval for periodic cleanup
    const interval = setInterval(cleanupOldAITasks, 60 * 60 * 1000); // Every hour

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, [cleanupOldAITasks]);

  const editTask = async (taskId: string, updatedData: Partial<Task>) => {
    if (!user?.uid || !clinicId) return;

    try {
      const taskRef = doc(
        db,
        "clinics",
        clinicId,
        "users",
        user.uid,
        "tasks",
        taskId
      );

      await updateDoc(taskRef, {
        ...updatedData,
        lastModified: Timestamp.fromDate(new Date()),
      });

      setEditingTask(null); // Close edit mode after successful update
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };

  return (
    <div
      className={`
        fixed top-[20px] bottom-[20px] z-50
        w-[90vw] max-w-xl
        bg-[#FDFBF8]
        transform transition-all duration-500 ease-out
        ${
          isOpen
            ? "translate-x-0 opacity-100"
            : "translate-x-[100px] opacity-0 pointer-events-none"
        }
        ${isChatOpen ? "right-[calc(38rem+20px)]" : "right-[20px]"}
        shadow-[0_8px_30px_rgb(0,0,0,0.06)]
        rounded-2xl border-2 border-gray-200/70
        flex flex-col
      `}
    >
      {/* Header Section - Added subtle gradient and refined borders */}
      <div className="flex-shrink-0 border-b border-gray-200/70 bg-white/50 rounded-t-2xl">
        <div className="h-[68px] px-6 flex items-center justify-between">
          <div className="flex items-center gap-3">
            <h2 className="text-xl font-semibold text-[#0D1140]">Tasks</h2>
            <div className="h-4 w-[1px] bg-gray-300/70"></div>
            <p className="text-sm text-gray-500">
              {format(new Date(), "EEEE, MMMM d")}
            </p>
          </div>
          <div className="absolute -right-3 -top-3 z-[9999]">
            <button
              onClick={onToggle}
              className="p-2 rounded-full
                        bg-white shadow-md
                        border border-gray-200/70 
                        hover:bg-red-50 
                        transition-colors
                        group"
            >
              <XMarkIcon className="w-4 h-4 text-gray-500 group-hover:text-red-500" />
            </button>
          </div>
        </div>
      </div>

      {/* Content Area */}
      <div className="flex-1 flex flex-col min-h-0">
        {/* Search and Add Task Row - Added frosted glass effect */}
        <div className="py-5 px-6 flex gap-3 items-center bg-white/60 border-b border-gray-100/80 backdrop-blur-sm">
          <div className="flex-1 relative">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search tasks..."
              className="w-full py-1.5 pl-10 pr-4
                       bg-white/80 rounded-xl
                       text-gray-700 placeholder-gray-400
                       border border-gray-200/60
                       focus:outline-none focus:ring-1 focus:ring-cosmic-cyan/30
                       shadow-sm"
            />
            <svg
              className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>

          <button
            onClick={() => setShowNewTaskForm(!showNewTaskForm)}
            className="flex-shrink-0 p-2.5 
                     bg-cosmic-cyan/10 text-cosmic-cyan rounded-xl
                     flex items-center justify-center 
                     transition-all duration-300 
                     hover:bg-cosmic-cyan/20
                     shadow-sm"
          >
            <PlusIcon className="w-5 h-5" />
          </button>
        </div>

        {/* New Task Form */}
        {showNewTaskForm && (
          <div className="px-6 py-4 bg-gray-50/80 border-b border-gray-100">
            <div className="space-y-4">
              {/* Title Input */}
              <input
                type="text"
                value={newTask.title}
                onChange={(e) =>
                  setNewTask({ ...newTask, title: e.target.value })
                }
                placeholder="Task title *"
                className="w-full p-3 bg-white rounded-xl
                         text-gray-700 placeholder-gray-400
                         border border-gray-200
                         focus:outline-none focus:ring-1 focus:ring-cosmic-cyan"
              />

              {/* Description */}
              <textarea
                value={newTask.description || ""}
                onChange={(e) =>
                  setNewTask({ ...newTask, description: e.target.value })
                }
                placeholder="Add description (optional)"
                className="w-full p-3 bg-white rounded-xl
                         text-gray-700 placeholder-gray-400
                         border border-gray-200
                         focus:outline-none focus:ring-1 focus:ring-cosmic-cyan
                         resize-none h-24"
              />

              {/* Due Date and Priority Row */}
              <div className="flex gap-3">
                <div className="flex-1">
                  <DatePicker
                    selected={newTask.dueDate}
                    onChange={(date) =>
                      setNewTask({
                        ...newTask,
                        dueDate: roundToNearest15Minutes(date || new Date()),
                      })
                    }
                    showTimeSelect
                    timeIntervals={15}
                    minTime={new Date(new Date().setHours(5, 0, 0, 0))} // 5 AM
                    maxTime={new Date(new Date().setHours(22, 0, 0, 0))} // 10 PM
                    dateFormat="MMM d, yyyy h:mm aa" // Shorthand format
                    className="w-full p-3 bg-white rounded-xl
                             text-gray-700 border border-gray-200
                             focus:outline-none focus:ring-1 focus:ring-cosmic-cyan"
                    placeholderText="Dec 5, 2025 11:14" // Example placeholder
                    popperClassName="react-datepicker-popper"
                    calendarClassName="rounded-lg border border-gray-200 shadow-lg"
                    wrapperClassName="w-full"
                  />
                </div>

                <select
                  value={newTask.priority}
                  onChange={(e) =>
                    setNewTask({
                      ...newTask,
                      priority: e.target.value as "low" | "medium" | "high",
                    })
                  }
                  className="flex-1 p-3 bg-white rounded-xl
                           text-gray-700 border border-gray-200
                           focus:outline-none focus:ring-1 focus:ring-cosmic-cyan
                           cursor-pointer appearance-none"
                >
                  <option value="low">Low Priority</option>
                  <option value="medium">Medium Priority</option>
                  <option value="high">High Priority</option>
                </select>
              </div>

              {/* Patient Selection */}
              {renderPatientSelection()}

              {/* Action Buttons */}
              <div className="flex gap-3 pt-2">
                <button
                  onClick={handleCancelNewTask}
                  className="flex-1 py-2 px-4 rounded-xl
                           border border-gray-200 text-gray-600
                           hover:bg-gray-50 transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={addTask}
                  className="flex-1 py-2 px-4 rounded-xl
                           bg-cosmic-cyan text-white
                           hover:bg-cosmic-cyan/90 transition-colors
                           disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={!newTask.title}
                >
                  Add Task
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Add AI Suggestions section */}
        <AISuggestions
          tasks={tasks}
          onAddTask={addAITaskToTasks}
          onDelete={deleteTask}
        />

        {/* Tasks Container - Added subtle background and spacing */}
        <div className="flex-1 flex flex-col min-h-0 pt-6 bg-gradient-to-b from-gray-50/50 to-transparent">
          {/* Scrollable Priority Groups */}
          <div className="flex-1 overflow-y-auto px-6 scrollbar-hide">
            {priorityGroups.map(
              (group) =>
                group.tasks.length > 0 && (
                  <div key={group.title} className="mb-8">
                    <h3 className="text-sm font-medium text-gray-500 mb-3 ml-1">
                      {group.title}
                    </h3>
                    <div className="grid grid-cols-2 gap-4 auto-rows-max">
                      {group.tasks.map((task) => (
                        <TaskItem
                          key={task.id}
                          task={task}
                          onToggle={toggleTaskStatus}
                          onDelete={deleteTask}
                          onEdit={(task) => setEditingTask({
                            id: task.id,
                            title: task.title,
                            description: task.description,
                            priority: task.priority,
                            dueDate: task.dueDate
                          })}
                          editing={editingTask?.id === task.id}
                          onSave={editTask}
                          onCancel={() => setEditingTask(null)}
                          compact={false}
                        />
                      ))}
                    </div>
                  </div>
                )
            )}
          </div>
          {/* Completed Tasks Section - Enhanced with frosted glass effect */}
          <div className="flex-shrink-0 border-t border-gray-200/70 bg-white/80 backdrop-blur-sm rounded-b-2xl shadow-[0_-8px_16px_-6px_rgb(0,0,0,0.03)]">
            <button
              onClick={() => setIsCompletedExpanded(!isCompletedExpanded)}
              className="w-full px-6 pt-5 pb-3 flex items-center justify-between group"
            >
              <div className="flex items-center gap-4">
                <h2 className="text-lg font-semibold text-[#0D1140]">
                  Recently Completed
                </h2>
                {isCompletedExpanded && filteredCompletedTasks.length > 0 && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteAllCompletedTasks();
                    }}
                    className="text-sm px-3 py-1.5 
                             text-gray-600 hover:text-red-500
                             flex items-center gap-1.5
                             hover:bg-red-50 rounded-md transition-colors"
                  >
                    <TrashIcon className="w-4 h-4" />
                    Clear All
                  </button>
                )}
              </div>
              <ChevronDownIcon
                className={`
                  w-5 h-5 text-gray-400 
                  transition-transform duration-200 
                  group-hover:text-gray-600
                  ${isCompletedExpanded ? "rotate-180" : "rotate-0"}
                `}
              />
            </button>

            <div
              className={`
                overflow-hidden transition-all duration-200 ease-in-out
                ${isCompletedExpanded ? "max-h-80" : "max-h-0"}
              `}
            >
              <div className="px-6 pb-6">
                <div className="overflow-x-auto">
                  <div
                    className="flex gap-4 pb-2"
                    style={{ minWidth: "min-content" }}
                  >
                    {filteredCompletedTasks.map((task) => (
                      <div key={task.id} className="w-72 flex-shrink-0">
                        <TaskItem
                          key={task.id}
                          task={task}
                          onToggle={toggleTaskStatus}
                          onDelete={deleteTask}
                          onEdit={(task) => setEditingTask({
                            id: task.id,
                            title: task.title,
                            description: task.description,
                            priority: task.priority,
                            dueDate: task.dueDate
                          })}
                          editing={editingTask?.id === task.id}
                          onSave={editTask}
                          onCancel={() => setEditingTask(null)}
                          compact={false}
                        />
                      </div>
                    ))}
                    {filteredCompletedTasks.length === 0 && (
                      <p className="text-gray-500 text-sm italic py-4">
                        No completed tasks
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Update the TaskItemProps interface
export interface TaskItemProps {
  task: Task;
  onToggle: (id: string, status: string) => void;
  onDelete: (id: string) => void;
  onEdit: (task: Task) => void;
  editing?: boolean;
  onSave?: (taskId: string, updatedData: Partial<Task>) => void;
  onCancel?: () => void;
  compact?: boolean;
}

export const TaskItem: React.FC<TaskItemProps> = ({
  task,
  onToggle,
  onDelete,
  onEdit,
  editing = false,
  onSave,
  onCancel,
  compact = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [editForm, setEditForm] = useState({
    title: task.title,
    description: task.description || '',
    priority: task.priority,
    dueDate: task.dueDate
  });
  
  const itemRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    if ((editing || isExpanded) && itemRef.current) {
      itemRef.current.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'center'
      });
    }
  }, [editing, isExpanded]);

  const hasLongContent = task.description && task.description.length > 100;
  const textStyles = task.status === "completed" ? "line-through text-gray-400" : "text-gray-800";

  if (editing) {
    return (
      <div 
        ref={itemRef}
        className="col-span-2 transition-all duration-300 ease-in-out"
      >
        <div 
          className={`
            bg-white rounded-lg p-4 shadow-sm
            border-l-4 ${getPriorityBorderColor(task.priority)}
            transition-all duration-200
          `}
        >
          <div className="space-y-3">
            <input
              type="text"
              value={editForm.title}
              onChange={(e) => setEditForm(prev => ({ ...prev, title: e.target.value }))}
              className="w-full p-2 border rounded"
              placeholder="Task title"
            />
            
            <textarea
              value={editForm.description}
              onChange={(e) => setEditForm(prev => ({ ...prev, description: e.target.value }))}
              className="w-full p-2 border rounded resize-none h-32"
              placeholder="Description (optional)"
            />
            
            <div className="flex gap-3">
              <select
                value={editForm.priority}
                onChange={(e) => setEditForm(prev => ({ 
                  ...prev, 
                  priority: e.target.value as "low" | "medium" | "high" 
                }))}
                className="p-2 border rounded"
              >
                <option value="low">Low Priority</option>
                <option value="medium">Medium Priority</option>
                <option value="high">High Priority</option>
              </select>
              
              <div className="flex-1">
                <DatePicker
                  selected={editForm.dueDate}
                  onChange={(date) => setEditForm(prev => ({ 
                    ...prev, 
                    dueDate: date || new Date() 
                  }))}
                  showTimeSelect
                  dateFormat="MMM d, yyyy h:mm aa"
                  className="w-full p-2 border rounded"
                  wrapperClassName="w-full"
                />
              </div>
            </div>
            
            <div className="flex justify-end gap-2">
              <button
                onClick={onCancel}
                className="px-3 py-1 text-gray-600 hover:bg-gray-100 rounded"
              >
                Cancel
              </button>
              <button
                onClick={() => onSave?.(task.id, editForm)}
                className="px-3 py-1 bg-cosmic-cyan text-white rounded hover:bg-cosmic-cyan/90"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={itemRef}
      className={`
        transition-all duration-300 ease-in-out
        ${isExpanded ? "col-span-2" : ""}
      `}
    >
      <div
        className={`
          transition-all duration-200
          ${task.status === "completed" ? "opacity-70" : "opacity-100"}
          bg-white/90
          p-4
          border-l-4 rounded-lg
          shadow-md hover:shadow-lg
          ${getPriorityBorderColor(task.priority)}
          cursor-pointer
          group
          w-full
          ${isExpanded ? "min-h-[200px]" : compact ? "h-auto" : "aspect-square"}
          flex flex-col
          backdrop-blur-[2px]
          ${hasLongContent ? "hover:ring-2 hover:ring-cosmic-cyan/30" : ""}
        `}
        onClick={() => {
          if (hasLongContent) {
            setIsExpanded(!isExpanded);
          }
        }}
      >
        <div className="flex flex-col h-full">
          {/* Header with checkbox and delete */}
          <div className="flex items-start gap-3 mb-2">
            <button
              onClick={(e) => {
                e.stopPropagation();
                onToggle(task.id, task.status);
              }}
              className={`
                flex-shrink-0
                transition-colors duration-300 
                ${
                  task.status === "completed"
                    ? "text-cosmic-cyan"
                    : "text-gray-400 hover:text-cosmic-cyan"
                }
              `}
            >
              <CheckCircleIcon className="w-5 h-5" />
            </button>

            <h3
              className={`flex-1 font-medium ${textStyles} ${
                isExpanded ? "" : "line-clamp-2"
              }`}
            >
              {task.title}
            </h3>

            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete(task.id);
              }}
              className="opacity-0 group-hover:opacity-100 transition-opacity duration-200
                       text-gray-400 hover:text-red-500"
            >
              <TrashIcon className="w-5 h-5" />
            </button>

            <button
              onClick={(e) => {
                e.stopPropagation();
                onEdit(task);
              }}
              className="p-1 text-gray-400 hover:text-cosmic-cyan hover:bg-cosmic-cyan/10 rounded"
            >
              <PencilIcon className="w-4 h-4" />
            </button>
          </div>

          {/* Content area */}
          <div className="flex-1">
            {task.patientName && (
              <p className={`text-sm mb-2 ${textStyles}`}>
                Patient: {task.patientName}
              </p>
            )}

            {task.description && (
              <p
                className={`text-sm ${textStyles} ${
                  isExpanded ? "whitespace-pre-wrap" : "line-clamp-3"
                }`}
              >
                {task.description}
              </p>
            )}
          </div>

          {/* Footer with time and expand indicator */}
          <div className="flex items-center justify-between mt-4">
            <div className={`flex items-center gap-2 text-sm ${textStyles}`}>
              <ClockIcon className="w-4 h-4 flex-shrink-0" />
              <span>
                {format(
                  new Date(task.dueDate),
                  isExpanded ? "MMMM d, yyyy h:mm a" : "MMM d, h:mm a"
                )}
              </span>
            </div>
            {hasLongContent && (
              <ChevronDownIcon
                className={`w-4 h-4 text-gray-400 transition-transform duration-200
                           ${isExpanded ? "rotate-180" : ""}`}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// Update the priority border colors to be more subtle
const getPriorityBorderColor = (priority: string) => {
  switch (priority) {
    case "high":
      return "border-red-400";
    case "medium":
      return "border-yellow-400";
    case "low":
      return "border-green-400";
    default:
      return "border-gray-200";
  }
};

export default TasksPanel;
