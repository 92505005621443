import { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import ChatWindow from '../components/ChatWindow';
import ChatWindowGeneral from '../components/ChatWindowgeneral';
import AddQuickPickPopup from '../components/AddQuickPickPopup';
import EditQuickPickPopup from '../components/EditQuickPickPopup';
import Greeting from '../components/Greeting';
import PatientFilterContainer from '../components/PatientFilterContainer';
import '../styles/QuickPickPopup.css';
import '../styles/PatientExplorer.css';
import { db, auth } from '../config/firebase';
import { updateDoc, doc, deleteDoc, collection, getDocs, setDoc, getDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash, faChevronUp, faChevronDown, faRobot, faBookOpen, faPrescriptionBottleMedical, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import ChatWindowRx from '../components/ChatWindowRx';
import dxSuggestions from '../lists/dxSuggestions';
import clinicalSignsSuggestions from '../lists/clinicalSignsSuggestions';
import exactDrugSuggestions from '../lists/exactDrugSuggestions';
import { parse } from 'date-fns';
import { usePatientStore } from '../stores/patientStore';

const PatientExplorer = ({ llmMode, context, initialPatient }) => {
  const location = useLocation();
  const patientFromPrimer = location.state?.patient;

  const { selectedPrimerPatient, setSelectedPrimerPatient } = usePatientStore();

  const [quickPicks, setQuickPicks] = useState([]);
  const [showAddPopup, setShowAddPopup] = useState(false); // Control for Add popup visibility
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showGreeting, setShowGreeting] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [selectedQuickPick, setSelectedQuickPick] = useState(null);
  const [includePatientData, setIncludePatientData] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [chatMode, setChatMode] = useState('general'); // 'general', 'academic', or 'rx'
  const [isTypeSelectExpanded, setIsTypeSelectExpanded] = useState(false);

  const chatWindowRef = useRef(null);
  const [chatId] = useState(`conv_${new Date().getTime()}`);

  const [input, setInput] = useState('');
  const [diagnosisTags, setDiagnosisTags] = useState([]);
  const [symptomsTags, setSymptomsTags] = useState([]);
  const [medicationTags, setMedicationTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const inputRef = useRef(null);

  const typeSelectRef = useRef(null); // Add this ref

  // Update the useEffect to load quick picks from localStorage
  useEffect(() => {
    const loadQuickPicks = () => {
      try {
        const userData = JSON.parse(localStorage.getItem('userData') || '{}');
        const loadedQuickPicks = userData.user?.settings?.quickPicks || [];
        setQuickPicks(loadedQuickPicks);
      } catch (error) {
        console.error("Error loading quick picks:", error);
        setQuickPicks([]);
      }
    };

    loadQuickPicks();
  }, []); // Empty dependency array since we only need to load once

  const addQuickPick = async (label, question) => {
    const user = auth.currentUser;
    if (user) {
      try {
        // Get clinicId from localStorage
        const userData = JSON.parse(localStorage.getItem('userData') || '{}');
        const clinicId = userData.user?.clinicId;
        
        if (!clinicId) {
          console.error('No clinic ID found in user data');
          return;
        }

        console.log('PatientExplorer: Adding quick pick at path:', 
          `clinics/${clinicId}/users/${user.uid}/settings/quickPicks`);
        
        // Get reference to the quickPicks document
        const quickPicksRef = doc(db, `clinics/${clinicId}/users/${user.uid}/settings/quickPicks`);
        
        // Get current quick picks
        const quickPicksDoc = await getDoc(quickPicksRef);
        const currentQuickPicks = quickPicksDoc.data()?.quickPicks || [];
        
        // Create new quick pick
        const newQuickPick = {
          id: Date.now().toString(),
          label,
          question
        };
        
        // Add to array
        const updatedQuickPicks = [...currentQuickPicks, newQuickPick];

        // Save the updated array back to Firestore
        await setDoc(quickPicksRef, {
          quickPicks: updatedQuickPicks
        }, { merge: true });
        
        // Update local state
        setQuickPicks(updatedQuickPicks);
        
        console.log('Successfully added quick pick and updated state');
      } catch (error) {
        console.error("Error adding quick pick:", error);
      }
    }
  };

  const updateQuickPick = async (id, label, question) => {
    const user = auth.currentUser;
    if (user) {
      try {
        const quickPickRef = doc(db, `clinics/${user.clinicId}/users/${user.uid}/settings/quickPicks/quickPickList/${id}`);
        await updateDoc(quickPickRef, {
          label: label,
          value: question // Note: 'value' is used instead of 'question' in the schema
        });
        
        // Update local state and localStorage
        const updatedQuickPicks = quickPicks.map((quickPick) =>
          quickPick.id === id ? { ...quickPick, label, question } : quickPick
        );
        setQuickPicks(updatedQuickPicks);
        localStorage.setItem('quickpicks', JSON.stringify(updatedQuickPicks));
      } catch (error) {
        console.error("Error updating quick pick:", error);
      }
    }
  };

  // Function to open Add Popup
  const openAddPopup = () => {
    if (quickPicks.length < 5) {
      setShowAddPopup(true);
    }
  };

  // Function to close Add Popup
  const closeAddPopup = () => {
    setShowAddPopup(false); // Ensure the popup closes by setting the state to false
  };

  const openEditPopup = (quickPick) => {
    setSelectedQuickPick(quickPick);
    setShowEditPopup(true);
  };

  const closeEditPopup = () => {
    setShowEditPopup(false);
    setSelectedQuickPick(null);
  };

  // Add new function to check if greeting should be shown
  const shouldShowGreeting = (mode) => {
    const greetingState = sessionStorage.getItem(`greeting_${mode}`);
    return greetingState !== '1';
  };

  // Modify handleInteraction
  const handleInteraction = (event) => {
    const isInChatArea = event.target.closest('.content-area') || 
                        event.target.closest('.input-with-actions') ||
                        event.target.closest('.option-selection');
    
    if (isInChatArea) {
      setFadeOut(true);
      setTimeout(() => {
        setShowGreeting(false);
        // Store greeting state for current mode
        sessionStorage.setItem(`greeting_${chatMode}`, '1');
      }, 500);
    }
  };

  const handleQuickPickClick = (event, question) => {
    if (!event.target.classList.contains('edit-icon')) {
      // Hide greeting when quick pick is clicked
      setFadeOut(true);
      setTimeout(() => {
        setShowGreeting(false);
      }, 500);
      
      chatWindowRef.current.sendResponse(question);
    }
  };

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (event, index) => {
    event.preventDefault();
    if (index !== draggedIndex) {
      const newQuickPicks = [...quickPicks];
      const [removed] = newQuickPicks.splice(draggedIndex, 1);
      newQuickPicks.splice(index, 0, removed);
      setQuickPicks(newQuickPicks);
      localStorage.setItem('quickpicks', JSON.stringify(newQuickPicks));
      setDraggedIndex(index);
    }
  };

  const handleDragEnd = () => {
    setDraggedIndex(null);
  };

  const handleEditQuickPick = (event, quickPick) => {
    event.stopPropagation();
    openEditPopup(quickPick);
  };

  const handleDeleteQuickPick = async (id) => {
    const user = auth.currentUser;
    if (user) {
      try {
        // Get clinicId from localStorage
        const userData = JSON.parse(localStorage.getItem('userData') || '{}');
        const clinicId = userData.user?.clinicId;
        
        if (!clinicId) {
          console.error('No clinic ID found in user data');
          return;
        }

        console.log('PatientExplorer: Deleting quick pick:', id);
        
        // Get reference to the quickPicks document
        const quickPicksRef = doc(db, `clinics/${clinicId}/users/${user.uid}/settings/quickPicks`);
        
        // Get current quick picks
        const quickPicksDoc = await getDoc(quickPicksRef);
        const currentQuickPicks = quickPicksDoc.data()?.quickPicks || [];
        
        // Filter out the deleted quick pick
        const updatedQuickPicks = currentQuickPicks.filter(pick => pick.id !== id);

        // Save the filtered array back to Firestore
        await setDoc(quickPicksRef, {
          quickPicks: updatedQuickPicks
        }, { merge: true });

        // Update local state with the filtered array
        setQuickPicks(updatedQuickPicks);
        
        console.log('Successfully deleted quick pick and updated state');
      } catch (error) {
        console.error('Error deleting quick pick:', error);
      }
    }
  };

  const handlePatientSelect = (selectedOption) => {
    if (selectedOption) {
      setSelectedPrimerPatient(selectedOption.patient);
    } else {
      setSelectedPrimerPatient(null);
    }
  };

  const handleClearChat = useCallback(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.clearConversation();
    }
  }, []);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setInput('');

    switch (option) {
      case 'Dx':
        setSuggestions(dxSuggestions.filter(item => !diagnosisTags.includes(item)));
        break;
      case 'Clinical Signs':
        setSuggestions(clinicalSignsSuggestions.filter(item => !symptomsTags.includes(item)));
        break;
      case 'Exact Drug':
        setSuggestions(exactDrugSuggestions.filter(item => !medicationTags.includes(item)));
        break;
      default:
        setSuggestions([]);
    }
  };

  const handleAddTag = (newTag) => {
    if (newTag !== '') {
      switch (selectedOption) {
        case 'Dx':
          setDiagnosisTags((prevTags) => {
            const updatedTags = [...prevTags, newTag];
            updateChatInput(updatedTags, symptomsTags, medicationTags);
            setSuggestions(suggestions.filter(item => item !== newTag));
            return updatedTags;
          });
          break;
        case 'Clinical Signs':
          setSymptomsTags((prevTags) => {
            const updatedTags = [...prevTags, newTag];
            updateChatInput(diagnosisTags, updatedTags, medicationTags);
            setSuggestions(suggestions.filter(item => item !== newTag));
            return updatedTags;
          });
          break;
        case 'Exact Drug':
          setMedicationTags((prevTags) => {
            const updatedTags = [...prevTags, newTag];
            updateChatInput(diagnosisTags, symptomsTags, updatedTags);
            setSuggestions(suggestions.filter(item => item !== newTag));
            return updatedTags;
          });
          break;
        default:
          break;
      }
      setInput('');
    }
  };

  const handleDeleteTag = (tagToDelete, option) => {
    switch (option) {
      case 'Dx':
        setDiagnosisTags((prevTags) => {
          const updatedTags = prevTags.filter((tag) => tag !== tagToDelete);
          updateChatInput(updatedTags, symptomsTags, medicationTags);
          setSuggestions([...suggestions, tagToDelete]);
          return updatedTags;
        });
        break;
      case 'Clinical Signs':
        setSymptomsTags((prevTags) => {
          const updatedTags = prevTags.filter((tag) => tag !== tagToDelete);
          updateChatInput(diagnosisTags, updatedTags, medicationTags);
          setSuggestions([...suggestions, tagToDelete]);
          return updatedTags;
        });
        break;
      case 'Exact Drug':
        setMedicationTags((prevTags) => {
          const updatedTags = prevTags.filter((tag) => tag !== tagToDelete);
          updateChatInput(diagnosisTags, symptomsTags, updatedTags);
          setSuggestions([...suggestions, tagToDelete]);
          return updatedTags;
        });
        break;
      default:
        break;
    }
  };

  const handleClearAllTags = () => {
    setDiagnosisTags([]);
    setSymptomsTags([]);
    setMedicationTags([]);
    updateChatInput([], [], []);
    setSuggestions([...dxSuggestions, ...clinicalSignsSuggestions, ...exactDrugSuggestions]);
    
    if (chatWindowRef.current) {
      chatWindowRef.current.updateInput('');
    }
  };

  const updateChatInput = (diagnosisTags, symptomsTags, medicationTags) => {
    const messageContent = `
Diagnosis: ${diagnosisTags.join(', ')}
Symptoms: ${symptomsTags.join(', ')}
Medication: ${medicationTags.join(', ')}`;

    if (chatWindowRef.current) {
      chatWindowRef.current.updateInput(messageContent.trim());
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddTag(input);
      setInput('');
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (suggestions.includes(value)) {
      handleAddTag(value);
      setInput('');
    } else {
      setInput(value);
    }
  };

  // Modify handleModeChange
  const handleModeChange = (mode) => {
    // Clear the chat before changing modes
    handleClearChat();
    
    setChatMode(mode);
    
    // Check if greeting should be shown for this mode
    const shouldShow = shouldShowGreeting(mode);
    setFadeOut(false);
    setShowGreeting(shouldShow);
    setGreetingKey(prev => prev + 1);
    
    setCurrentScreen(
      mode === 'academic' ? 'AcademicSearch' :
      'PatientExplorer'
    );
  };

  // Add new state for controlling content visibility
  const [isContentReady, setIsContentReady] = useState(false);

  // Add useEffect to handle initial load
  useEffect(() => {
    // Small delay to ensure UI elements are properly positioned
    const timer = setTimeout(() => {
      setIsContentReady(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  // Add a new prop to handle chat send events
  const handleChatSend = () => {
    setIsTypeSelectExpanded(false);
  };

  // Modify the renderContent function to pass the new prop
  const renderContent = () => {
    if (!isContentReady) return null;

    switch(chatMode) {
      case 'general':
        return (
          <div className="content-area">
            <ChatWindow
              ref={chatWindowRef}
              llmMode={llmMode}
              context={context}
              chatId={chatId}
              chatMode={chatMode}
              onSend={handleChatSend}
              includePatientData={true}
            />
          </div>
        );
      case 'academic':
        return (
          <div className="content-area">
            <ChatWindow
              ref={chatWindowRef}
              llmMode="academic-search"
              context={context}
              chatMode={chatMode}
              onSend={handleChatSend}
              includePatientData={includePatientData}
            />
          </div>
        );
      default:
        return null;
    }
  };

  // Add this near the other mode-related functions
  const getModeInfo = (mode) => {
    switch(mode) {
      case 'general':
        return {
          icon: faCommentDots,
          tag: 'AI-powered veterinary assistant'
        };
      case 'academic':
        return {
          icon: faBookOpen,
          tag: 'Returning cited academic sources'
        };
      default:
        return {
          icon: faRobot,
          tag: ''
        };
    }
  };

  // Add new state for greeting animation
  const [greetingKey, setGreetingKey] = useState(0);
  const [currentScreen, setCurrentScreen] = useState('PatientExplorer');

  // Add this function back
  const reloadQuickPicks = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const quickPicksRef = collection(db, `clinics/${user.clinicId}/users/${user.uid}/settings/quickPicks/quickPickList`);
        const quickPicksSnapshot = await getDocs(quickPicksRef);
        
        const loadedQuickPicks = quickPicksSnapshot.docs.map(doc => ({
          id: doc.id,
          label: doc.data().label,
          question: doc.data().value
        }));
        
        setQuickPicks(loadedQuickPicks);
        localStorage.setItem('quickpicks', JSON.stringify(loadedQuickPicks));
      } catch (error) {
        console.error("Error reloading quick picks:", error);
        const storedQuickPicks = JSON.parse(localStorage.getItem('quickpicks')) || [];
        setQuickPicks(storedQuickPicks);
      }
    }
  };

  // Add this helper function
  const getPatientDisplay = () => {
    if (chatMode !== 'general' && !includePatientData) {
      return <span className="no-patient">Not using patient data</span>;
    }
    
    if (selectedPrimerPatient) {
      return (
        <span className="patient-indicator">
          {selectedPrimerPatient.name} ({selectedPrimerPatient.id})
        </span>
      );
    }
    
    return <span className="no-patient">No patient selected</span>;
  };

  // Add useEffect to check initial greeting state
  useEffect(() => {
    setShowGreeting(shouldShowGreeting(chatMode));
  }, []);

  // Add this useEffect for the click outside handler
  useEffect(() => {
    function handleClickOutside(event) {
      if (typeSelectRef.current && !typeSelectRef.current.contains(event.target)) {
        setIsTypeSelectExpanded(false);
      }
    }

    // Add event listener when dropdown is open
    if (isTypeSelectExpanded) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isTypeSelectExpanded]);

  return (
    <div onClick={handleInteraction}>
      <div
        style={{
          display: showGreeting ? 'block' : 'none',
          opacity: fadeOut ? 0 : 1,
          transition: 'opacity 0.5s ease-out',
          pointerEvents: 'none',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 2
        }}
      >
        <Greeting key={greetingKey} screen={currentScreen} />
      </div>
      
      <PatientFilterContainer
        selectedPatient={selectedPrimerPatient ? {
          value: selectedPrimerPatient.id,
          label: `${selectedPrimerPatient.id} | ${selectedPrimerPatient.name} | ${selectedPrimerPatient.breed}`,
          patient: selectedPrimerPatient
        } : null}
        onPatientSelect={handlePatientSelect}
        isPrimerPage={false}
      />

      <div className={`controls-container ${chatMode}`}>
        <div className="chat-type-container" ref={typeSelectRef}>
          <div 
            className={`chat-type-bar ${chatMode}`}
            onClick={() => setIsTypeSelectExpanded(!isTypeSelectExpanded)}
          >
            <div className="chat-type-info">
              <FontAwesomeIcon 
                icon={getModeInfo(chatMode).icon} 
                className="chat-type-icon"
              />
              <span className="chat-type-title">
                {chatMode.charAt(0).toUpperCase() + chatMode.slice(1)}
              </span>
              <span className="chat-type-tag">{getModeInfo(chatMode).tag}</span>
            </div>
            <div className="chat-type-metadata">
              {getPatientDisplay()}
            </div>
            <FontAwesomeIcon 
              icon={isTypeSelectExpanded ? faChevronUp : faChevronDown} 
            />
          </div>

          <div className={`chat-type-selector ${isTypeSelectExpanded ? 'expanded' : ''}`}>
            <div className="radio-group">
              {['general', 'academic'/* , 'rx' */].map(mode => (
                <label key={mode} className={`radio-option ${mode}`}>
                  <input
                    type="radio"
                    name="mode"
                    checked={chatMode === mode}
                    onChange={() => handleModeChange(mode)}
                  />
                  <div className="radio-option-content">
                    <span className="radio-option-label">
                      {mode.charAt(0).toUpperCase() + mode.slice(1)}
                    </span>
                    <div className="radio-option-description">
                      {getDescription(mode)}
                    </div>
                  </div>
                </label>
              ))}
            </div>

            {(chatMode === 'academic'/* || chatMode === 'rx' */) && (
              <div className="include-patient-data">
                <label>
                  <input
                    type="checkbox"
                    checked={includePatientData}
                    onChange={() => setIncludePatientData(!includePatientData)}
                  />
                  <span className="checkbox-label">Use Selected Patient's Data</span>
                </label>
              </div>
            )}

            {/* Comment out entire Rx settings section
            {chatMode === 'rx' && (
              <div className="rx-settings">
                <div className="option-selection">
                  <div
                    className={`option-box ${selectedOption === 'Dx' ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect('Dx')}
                  >
                    Diagnosis
                  </div>
                  <div
                    className={`option-box ${selectedOption === 'Clinical Signs' ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect('Clinical Signs')}
                  >
                    Symptoms
                  </div>
                  <div
                    className={`option-box ${selectedOption === 'Exact Drug' ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect('Exact Drug')}
                  >
                    Medication
                  </div>
                </div>

                {selectedOption && (
                  <div className="inputs-container active">
                    <div className="input-with-actions">
                      <input
                        ref={inputRef}
                        type="text"
                        value={input}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder={`Enter ${selectedOption}...`}
                        className="input-field"
                        list="suggestions"
                      />
                      <button
                        className="checkmark-btn"
                        onClick={() => {
                          handleAddTag(input);
                          setInput('');
                        }}
                      >
                        <FontAwesomeIcon icon={faCheck} style={{ color: '#3b82f6' }} />
                      </button>
                      <datalist id="suggestions">
                        {suggestions.map((suggestion, i) => (
                          <option key={i} value={suggestion} />
                        ))}
                      </datalist>
                    </div>
                  </div>
                )}

                <div className="tags-container">
                  {diagnosisTags.map((tag, index) => (
                    <div key={index} className="tag">
                      {tag}
                      <button className="delete-tag-btn" onClick={() => handleDeleteTag(tag, 'Dx')}>
                        &#10005;
                      </button>
                    </div>
                  ))}
                  {symptomsTags.map((tag, index) => (
                    <div key={index} className="tag">
                      {tag}
                      <button className="delete-tag-btn" onClick={() => handleDeleteTag(tag, 'Clinical Signs')}>
                        &#10005;
                      </button>
                    </div>
                  ))}
                  {medicationTags.map((tag, index) => (
                    <div key={index} className="tag">
                      {tag}
                      <button className="delete-tag-btn" onClick={() => handleDeleteTag(tag, 'Exact Drug')}>
                        &#10005;
                      </button>
                    </div>
                  ))}
                  {(diagnosisTags.length > 0 || symptomsTags.length > 0 || medicationTags.length > 0) && (
                    <button className="clear-all-btn" onClick={handleClearAllTags}>
                      <FontAwesomeIcon icon={faTrash} /> Clear All
                    </button>
                  )}
                </div>
              </div>
            )}
            */}
          </div>
        </div>

        <div className="quick-picks">
          <div className="quick-picks-buttons">
            {quickPicks.map((quickPick, index) => (
              <button
                key={quickPick.id}
                className="quick-pick-button"
                onClick={(event) => handleQuickPickClick(event, quickPick.question)}
                draggable
                onDragStart={() => handleDragStart(index)}
                onDragOver={(event) => handleDragOver(event, index)}
                onDragEnd={handleDragEnd}
              >
                <span className="quick-pick-label">{quickPick.label}</span>
                <span className="edit-icon" onClick={(event) => handleEditQuickPick(event, quickPick)}>
                  &#x270E;
                </span>
              </button>
            ))}
            {quickPicks.length < 5 && (
              <button className="add-quick-pick" onClick={openAddPopup}>
                <span>+</span>
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Add conditional rendering for content */}
      {isContentReady && renderContent()}

      {showAddPopup && (
        <AddQuickPickPopup 
          onClose={closeAddPopup} 
          onSubmit={addQuickPick} 
          reloadQuickPicks={reloadQuickPicks} 
        />
      )}
      {showEditPopup && selectedQuickPick && (
        <EditQuickPickPopup
          onClose={closeEditPopup}
          onUpdate={updateQuickPick}
          onDelete={handleDeleteQuickPick}
          quickPick={selectedQuickPick}
        />
      )}
    </div>
  );
};

const getDescription = (mode) => {
  switch(mode) {
    case 'general':
      return 'Chat with AI about patient details and general veterinary queries';
    case 'academic':
      return 'Access cited veterinary research and academic information';
    default:
      return '';
  }
};

export default PatientExplorer;
