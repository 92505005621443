import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, getDoc, serverTimestamp, getDocs, collection } from 'firebase/firestore';
import { auth, db } from "../config/firebase";
import { FiMail, FiLock, FiUser, FiHome } from 'react-icons/fi';

const RegisterWithInvite: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  
  const email = searchParams.get('email') || '';
  const firstName = searchParams.get('firstName') || '';
  const lastName = searchParams.get('lastName') || '';
  const role = searchParams.get('role') || '';
  const clinicId = searchParams.get('clinicId') || '';
  const practiceName = searchParams.get('practiceName') || '';

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email || !firstName || !lastName || !role || !clinicId) {
      setError('Invalid invitation link');
      return;
    }
    
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        formData.password
      );

      const clinicDoc = await getDoc(doc(db, 'clinics', clinicId));
      const ownerId = clinicDoc.data()?.ownerId;

      if (!ownerId) {
        throw new Error('Clinic owner not found');
      }

      // Copy the missionBrief document and its appointmentTypes collection
      const sourcePath = `clinics/${clinicId}/users/${ownerId}/settings/missionBrief`;
      const destinationPath = `clinics/${clinicId}/users/${userCredential.user.uid}/settings/missionBrief`;

      // First copy the missionBrief document itself if it exists
      const sourceDoc = await getDoc(doc(db, sourcePath));
      if (sourceDoc.exists()) {
        await setDoc(doc(db, destinationPath), sourceDoc.data());
      }

      // Copy the appointmentTypes collection
      const appointmentTypesRef = collection(db, sourcePath, 'appointmentTypes');
      const appointmentTypes = await getDocs(appointmentTypesRef);

      // Copy each appointment type document
      for (const appointmentDoc of appointmentTypes.docs) {
        await setDoc(
          doc(db, destinationPath, 'appointmentTypes', appointmentDoc.id),
          appointmentDoc.data()
        );
      }

      await setDoc(doc(db, 'clinics', clinicId, 'users', userCredential.user.uid), {
        email: email,
        firstName: firstName,
        lastName: lastName,
        role: role,
        isProfileComplete: true,
        createdAt: serverTimestamp()
      });

      await setDoc(
        doc(db, 'clinics', clinicId, 'users', userCredential.user.uid, 'settings', 'scribeSettings'),
        {
          clientDischargeAdditions: '',
          personalDischargeTemplate: '',
          personalSoapNoteTemplate: '',
          soapNoteAdditions: '',
          usingClientDischargeDefaultTemplate: true,
          usingSoapNoteDefaultTemplate: true
        }
      );

      // Sign out after creating account to ensure clean auth state
      await auth.signOut();
      navigate('/login');
    } catch (err: any) {
      console.error('Error creating account:', err);
      setError(err.message || 'Error creating account');
    }
  };

  return (
    <div className="min-h-screen w-full flex items-center justify-center relative overflow-hidden">
      <div className="stars-background absolute inset-0">
        <div className="stars"></div>
        <div className="stars2"></div>
        <div className="stars3"></div>
      </div>
      
      <div className="relative w-full max-w-lg mx-auto px-4">
        <div className="flex items-center justify-center gap-4 mb-6">
          <a 
            href="https://app.lyravet.com" 
            className="round-home-button"
          >
            <FiHome />
          </a>
          <h1 className="text-2xl font-bold text-white text-center">
            {practiceName ? `Join ${practiceName}'s LyraVet Team` : 'Join LyraVet Team'}
          </h1>
        </div>

        <div className="settings-card w-full bg-white rounded-lg shadow-lg p-6">
          {error && (
            <div className="error-message mb-4 p-3 bg-red-100 text-red-700 rounded">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <div className="space-y-4">
              <div className="setting-item">
                <span className="setting-label">Email</span>
                <input 
                  type="text"
                  className="setting-input"
                  value={email}
                  readOnly
                />
              </div>
              <div className="setting-item">
                <span className="setting-label">First Name</span>
                <input 
                  type="text"
                  className="setting-input"
                  value={firstName}
                  readOnly
                />
              </div>
              <div className="setting-item">
                <span className="setting-label">Last Name</span>
                <input 
                  type="text"
                  className="setting-input"
                  value={lastName}
                  readOnly
                />
              </div>
              <div className="setting-item">
                <span className="setting-label">Role</span>
                <input 
                  type="text"
                  className="setting-input"
                  value={role}
                  readOnly
                />
              </div>
              <div className="setting-item">
                <span className="setting-label">Password</span>
                <input 
                  type="password"
                  className="setting-input"
                  value={formData.password}
                  onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
                  required
                  minLength={6}
                />
              </div>
              <div className="setting-item">
                <span className="setting-label">Confirm Password</span>
                <input 
                  type="password"
                  className="setting-input"
                  value={formData.confirmPassword}
                  onChange={(e) => setFormData(prev => ({ ...prev, confirmPassword: e.target.value }))}
                  required
                  minLength={6}
                />
              </div>
            </div>

            <div className="flex justify-end mt-6">
              <button 
                type="submit" 
                className="button button-primary"
              >
                Create Account
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterWithInvite;