import React, { useState } from 'react';
import '../styles/QuickPickPopup.css';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';

function EditQuickPickPopup({ onClose, onUpdate, onDelete, quickPick }) {
  const [label, setLabel] = useState(quickPick.label);
  const [question, setQuestion] = useState(quickPick.question);

  const handleUpdate = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        // Get clinicId from localStorage
        const userData = JSON.parse(localStorage.getItem('userData') || '{}');
        const clinicId = userData.user?.clinicId;
        
        if (!clinicId) {
          console.error('No clinic ID found in user data');
          return;
        }

        console.log('Starting update with user:', user.uid, 'clinicId:', clinicId);
        
        // Get reference to the quickPicks document
        const quickPicksRef = doc(db, `clinics/${clinicId}/users/${user.uid}/settings/quickPicks`);
        console.log('Fetching from path:', `clinics/${clinicId}/users/${user.uid}/settings/quickPicks`);
        
        // Get current quick picks
        const quickPicksDoc = await getDoc(quickPicksRef);
        const currentQuickPicks = quickPicksDoc.data()?.quickPicks || [];
        
        // Update the specific quick pick in the array
        const updatedQuickPicks = currentQuickPicks.map(pick => 
          pick.id === quickPick.id 
            ? { ...pick, label, question }
            : pick
        );

        // Save the updated array back to Firestore
        await setDoc(quickPicksRef, {
          quickPicks: updatedQuickPicks
        }, { merge: true });

        // Call the onUpdate callback
        onUpdate(quickPick.id, label, question);
        onClose();
      } catch (error) {
        console.error('Error updating quick pick:', error);
      }
    }
  };

  const handleDelete = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        // Get clinicId from localStorage
        const userData = JSON.parse(localStorage.getItem('userData') || '{}');
        const clinicId = userData.user?.clinicId;
        
        if (!clinicId) {
          console.error('No clinic ID found in user data');
          return;
        }

        console.log('Starting delete with user:', user.uid, 'clinicId:', clinicId);
        
        // Get reference to the quickPicks document
        const quickPicksRef = doc(db, `clinics/${clinicId}/users/${user.uid}/settings/quickPicks`);
        console.log('Fetching from path:', `clinics/${clinicId}/users/${user.uid}/settings/quickPicks`);
        
        // Get current quick picks
        const quickPicksDoc = await getDoc(quickPicksRef);
        const currentQuickPicks = quickPicksDoc.data()?.quickPicks || [];
        
        // Filter out the quick pick to delete
        const updatedQuickPicks = currentQuickPicks.filter(pick => pick.id !== quickPick.id);

        // Save the filtered array back to Firestore
        await setDoc(quickPicksRef, {
          quickPicks: updatedQuickPicks
        }, { merge: true });

        // Call the onDelete callback
        onDelete(quickPick.id);
        onClose();
      } catch (error) {
        console.error('Error deleting quick pick:', error);
      }
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup">
        <h3>Edit Quick Pick</h3>
        <div className="popup-content">
          <div className="popup-fields">
            <input
              type="text"
              placeholder="Label"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              required
            />
          </div>
          <div className="popup-buttons">
            <button type="button" onClick={onClose} className="popup-button cancel-button">
              Cancel
            </button>
            <button type="button" onClick={handleUpdate} className="popup-button primary-button">
              Update
            </button>
            <button type="button" onClick={handleDelete} className="popup-button delete-button">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditQuickPickPopup;