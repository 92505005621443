import React, { useState, useEffect } from "react";
import { FaBug } from 'react-icons/fa';
import { BiBug } from 'react-icons/bi';
import { RiBugFill } from 'react-icons/ri';
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import AccountPopup from "../AccountPopup";
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { HiOutlineLightBulb } from 'react-icons/hi';

interface BugReport {
  screen: string;
  description: string;
  severity: 'Low' | 'Medium' | 'High' | 'Critical';
}

interface IdeaSubmission {
  screen: string;
  description: string;
  impact: 'Low' | 'Medium' | 'High';
}

const SystemIcons: React.FC<{ activeScreen: string }> = ({ activeScreen }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isAccountPopupOpen, setIsAccountPopupOpen] = useState(false);
  const { logout, currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [showBugModal, setShowBugModal] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [bugReport, setBugReport] = useState<BugReport>({
    screen: activeScreen,
    description: '',
    severity: 'Medium'
  });
  const [showIdeaModal, setShowIdeaModal] = useState(false);
  const [ideaSubmitSuccess, setIdeaSubmitSuccess] = useState(false);
  const [isIdeaSpinning, setIsIdeaSpinning] = useState(false);
  const [ideaSubmission, setIdeaSubmission] = useState<IdeaSubmission>({
    screen: activeScreen,
    description: '',
    impact: 'Medium'
  });
  
  // Get user data from localStorage
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");
  const firstName = userData.user?.firstName || '';
  const lastName = userData.user?.lastName || '';
  const role = userData.user?.role || 'User';
  
  const displayName = firstName && lastName ? `${firstName} ${lastName}` : currentUser?.email?.split('@')[0] || 'User';

  // Update screen when activeScreen changes
  useEffect(() => {
    setBugReport(prev => ({
      ...prev,
      screen: activeScreen
    }));
  }, [activeScreen]);

  useEffect(() => {
    setIdeaSubmission(prev => ({
      ...prev,
      screen: activeScreen
    }));
  }, [activeScreen]);
  
  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  const handleAccountClick = () => {
    setShowDropdown(false);
    setIsAccountPopupOpen(true);
  };

  const handleBugSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsSpinning(true);
      await addDoc(collection(db, 'bugReports'), {
        ...bugReport,
        userId: currentUser?.uid,
        userEmail: currentUser?.email,
        timestamp: serverTimestamp(),
      });
      
      // Reset form and close modal
      setBugReport({
        screen: activeScreen,
        description: '',
        severity: 'Medium'
      });
      setShowBugModal(false);
      
      // After 1 second of spinning, show the checkmark
      setTimeout(() => {
        setIsSpinning(false);
        setSubmitSuccess(true);
      }, 1000);
      
      // Reset success state after 4 seconds total (1s spin + 3s checkmark)
      setTimeout(() => {
        setSubmitSuccess(false);
      }, 4000);
    } catch (error) {
      console.error('Error submitting bug report:', error);
      setIsSpinning(false);
    }
  };

  const handleIdeaSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsIdeaSpinning(true);
      await addDoc(collection(db, 'ideaSubmissions'), {
        ...ideaSubmission,
        userId: currentUser?.uid,
        userEmail: currentUser?.email,
        timestamp: serverTimestamp(),
      });
      
      setIdeaSubmission({
        screen: activeScreen,
        description: '',
        impact: 'Medium'
      });
      setShowIdeaModal(false);
      
      setTimeout(() => {
        setIsIdeaSpinning(false);
        setIdeaSubmitSuccess(true);
      }, 1000);
      
      setTimeout(() => {
        setIdeaSubmitSuccess(false);
      }, 4000);
    } catch (error) {
      console.error('Error submitting idea:', error);
      setIsIdeaSpinning(false);
    }
  };

  return (
    <>
      <div className="flex items-center space-x-4">
        <div className="relative">
          <button 
            onClick={() => setShowIdeaModal(!showIdeaModal)}
            className="hover:bg-cosmic-cyan-20 p-2 rounded-md transition-colors duration-150"
          >
            {isIdeaSpinning ? (
              <HiOutlineLightBulb className="h-5 w-5 text-yellow-400 transition-colors duration-150" />
            ) : ideaSubmitSuccess ? (
              <svg 
                className="h-5 w-5 text-green-500"
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M5 13l4 4L19 7" 
                />
              </svg>
            ) : (
              <HiOutlineLightBulb className="h-5 w-5 text-deep-space-40" />
            )}
          </button>

          {/* Idea Submission Modal */}
          {showIdeaModal && (
            <div className="absolute right-0 mt-2 w-96 bg-white rounded-lg shadow-lg z-50 p-4 border border-cosmic-cyan-40">
              <form onSubmit={handleIdeaSubmit}>
                <h3 className="text-lg font-semibold mb-1">Submit an Idea</h3>
                <p className="text-deep-space-40 text-sm mb-4">
                  Help us shape the future! Share your ideas for new features or improvements that would enhance your workflow.
                </p>
                
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-1">Where to Add</label>
                  <input 
                    type="text"
                    value={ideaSubmission.screen}
                    onChange={(e) => setIdeaSubmission(prev => ({
                      ...prev,
                      screen: e.target.value
                    }))}
                    className="w-full p-2 rounded border border-cosmic-cyan-20"
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium mb-1">Your Idea</label>
                  <textarea
                    value={ideaSubmission.description}
                    onChange={(e) => setIdeaSubmission(prev => ({
                      ...prev,
                      description: e.target.value
                    }))}
                    className="w-full p-2 rounded border border-cosmic-cyan-20 min-h-[100px]"
                    placeholder="Please describe your idea..."
                    required
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium mb-1">Potential Impact to Your Workflow</label>
                  <select
                    value={ideaSubmission.impact}
                    onChange={(e) => setIdeaSubmission(prev => ({
                      ...prev,
                      impact: e.target.value as IdeaSubmission['impact']
                    }))}
                    className="w-full p-2 rounded border border-cosmic-cyan-20"
                    required
                  >
                    <option value="Low">Low - Would be nice to have if you have time</option>
                    <option value="Medium">Medium - Would significantly improve my workflow</option>
                    <option value="High">High - This would be a game-changing feature</option>
                  </select>
                </div>

                <div className="flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={() => setShowIdeaModal(false)}
                    className="px-4 py-2 text-sm rounded-md hover:bg-cosmic-cyan-20"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 text-sm bg-cosmic-cyan-60 text-white rounded-md hover:bg-cosmic-cyan-70"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>

        <div className="relative">
          <button 
            onClick={() => setShowBugModal(!showBugModal)}
            className="hover:bg-cosmic-cyan-20 p-2 rounded-md transition-colors duration-150"
          >
            {isSpinning ? (
              <BiBug className="h-5 w-5 text-deep-space-40 animate-spin" />
            ) : submitSuccess ? (
              <svg 
                className="h-5 w-5 text-green-500"
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M5 13l4 4L19 7" 
                />
              </svg>
            ) : (
              <BiBug className="h-5 w-5 text-deep-space-40" />
            )}
          </button>

          {/* Bug Report Modal */}
          {showBugModal && (
            <div className="absolute right-0 mt-2 w-96 bg-white rounded-lg shadow-lg z-50 p-4 border border-cosmic-cyan-40">
              <form onSubmit={handleBugSubmit}>
                <h3 className="text-lg font-semibold mb-1">Report a Bug</h3>
                <p className="text-deep-space-40 text-sm mb-4">
                  Found something not working as expected? Help us improve by reporting any issues you encounter.
                </p>
                
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-1">Impacted Screen</label>
                  <input 
                    type="text"
                    value={bugReport.screen}
                    onChange={(e) => setBugReport(prev => ({
                      ...prev,
                      screen: e.target.value
                    }))}
                    className="w-full p-2 rounded border border-cosmic-cyan-20"
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium mb-1">What Happened?</label>
                  <textarea
                    value={bugReport.description}
                    onChange={(e) => setBugReport(prev => ({
                      ...prev,
                      description: e.target.value
                    }))}
                    className="w-full p-2 rounded border border-cosmic-cyan-20 min-h-[100px]"
                    placeholder="Please describe what is bugging you..."
                    required
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium mb-1">Severity</label>
                  <select
                    value={bugReport.severity}
                    onChange={(e) => setBugReport(prev => ({
                      ...prev,
                      severity: e.target.value as BugReport['severity']
                    }))}
                    className="w-full p-2 rounded border border-cosmic-cyan-20"
                    required
                  >
                    <option value="Low">Low - Minor inconvenience, not blocking work</option>
                    <option value="Medium">Medium - Causing some difficulties but has workarounds</option>
                    <option value="High">High - Significantly impacting my work</option>
                    <option value="Critical">Critical - I cannot work with this</option>
                  </select>
                </div>

                <div className="flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={() => setShowBugModal(false)}
                    className="px-4 py-2 text-sm rounded-md hover:bg-cosmic-cyan-20"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 text-sm bg-cosmic-cyan-60 text-white rounded-md hover:bg-cosmic-cyan-70"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>

        <div className="relative">
          <button
            className="flex items-center space-x-3 hover:bg-cosmic-cyan-20 p-2 rounded-md transition-colors duration-150"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <div className="h-8 w-8 flex items-center justify-center text-lg bg-cosmic-cyan-20 rounded-full">
              👤
            </div>
            <div className="hidden md:flex flex-col items-start">
              <span className="text-sm text-deep-space font-semibold">
                {displayName}
              </span>
              <span className="text-xs text-deep-space-40">{role}</span>
            </div>
          </button>

          {/* Dropdown Menu */}
          {showDropdown && (
            <div className="absolute right-0 mt-4 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
              <div className="py-2" role="menu" aria-orientation="vertical">
                <button
                  onClick={handleAccountClick}
                  className="w-full text-left px-4 py-2 text-sm text-deep-space hover:bg-cosmic-cyan-20 transition-colors duration-150"
                  role="menuitem"
                >
                  Account Settings
                </button>
                <button
                  onClick={handleLogout}
                  className="w-full text-left px-4 py-2 text-sm text-deep-space hover:bg-cosmic-cyan-20 transition-colors duration-150"
                  role="menuitem"
                >
                  Sign Out
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <AccountPopup
        user={currentUser}
        isOpen={isAccountPopupOpen}
        onClose={() => setIsAccountPopupOpen(false)}
        refreshFirestoreData={() => {}}
      />
    </>
  );
};

export default SystemIcons;
