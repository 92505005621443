import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import '../styles/ChatWindow.css';
import { collection, addDoc, serverTimestamp, onSnapshot, query, orderBy } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import { ConversationContext } from './ConversationContext';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { usePatientStore } from '../stores/patientStore';

function ChatWindow({ context, llmMode, chatMode, onSend, includePatientData }, ref) {
  const { conversations, updateConversation } = useContext(ConversationContext);
  const [conversation, setConversation] = useState(conversations[context] || []);
  const [userInput, setUserInput] = useState('');
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [chatId, setChatId] = useState(`conv_${new Date().getTime()}`);
  const [chunkQueue, setChunkQueue] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const chatBoxRef = useRef(null);
  const lastMessageRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const selectedPrimerPatient = usePatientStore(state => state.selectedPrimerPatient);

  useEffect(() => {
    const storedConversation = sessionStorage.getItem(`conversation_${context}`);
    if (storedConversation) {
      setConversation(JSON.parse(storedConversation));
    }
  }, [context]);

  useEffect(() => {
    const previousConversation = JSON.stringify(conversations[context]);
    const currentConversation = JSON.stringify(conversation);
    if (previousConversation !== currentConversation) {
      sessionStorage.setItem(`conversation_${context}`, JSON.stringify(conversation));
      updateConversation(context, conversation);
    }
  }, [context, conversation, updateConversation, conversations]);

  const handleStreamingData = useCallback((data) => {
    setIsTyping(true);
    setTimeout(() => {
      setConversation((prevConversation) => {
        const lastMessage = prevConversation[prevConversation.length - 1];
        if (lastMessage && lastMessage.sender === 'assistant') {
          return [
            ...prevConversation.slice(0, -1),
            { ...lastMessage, content: lastMessage.content + data.text },
          ];
        } else {
          return [
            ...prevConversation,
            { sender: 'assistant', content: data.text, createdAt: new Date() },
          ];
        }
      });
      scrollToLastMessage();
      setIsTyping(false);
      setChunkQueue((prevQueue) => prevQueue.slice(1));
    }, 0);
  }, []);

  useEffect(() => {
    if (chunkQueue.length > 0 && !isTyping) {
      const nextChunk = chunkQueue[0];
      handleStreamingData(nextChunk);
    }
  }, [chunkQueue, isTyping, handleStreamingData]);

  useEffect(() => {
    scrollToLastMessage();
    const user = auth.currentUser;
    if (user) {
      const userId = user.uid;
      // Get clinicId from userData
      const userData = JSON.parse(localStorage.getItem('userData') || '{}');
      const clinicId = userData.user?.clinicId;
      
      if (!clinicId) {
        console.error('No clinic ID found in user data');
        return;
      }

      // Update the path to include clinicId
      const chunkRef = collection(db, 'clinics', clinicId, 'users', userId, 'chats', chatId, 'chunks');
      const q = query(chunkRef, orderBy("timestamp"));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const newChunks = [];
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const data = change.doc.data();
            newChunks.push(data);
          }
        });
        setChunkQueue((prevQueue) => {
          const mergedQueue = [...prevQueue, ...newChunks];
          mergedQueue.sort((a, b) => a.chunkIndex - b.chunkIndex);
          return mergedQueue;
        });
      });
      return () => {
        unsubscribe();
      };
    }
  }, [chatId]);

  const scrollToLastMessage = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const copyToClipboard = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedIndex(index);
      setTimeout(() => setCopiedIndex(null), 2000);
    });
  };

  const clearConversation = useCallback(() => {
    sessionStorage.removeItem(`conversation_${context}`);
    setConversation([]);
    setUserInput('');
  }, [context]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault();
      setUserInput(userInput + '\n');
    } else if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (!isLoading) {
        sendMessage(event);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    clearConversation,
    updateInput: (text) => setUserInput(text),
    sendResponse: (question) => {
      sendMessage(new Event('submit'), question);
    }
  }));

  const formatPatientHistory = (historyString) => {
    return historyString || '';
  };

  const sendMessage = async (event, quickPickQuestion = null) => {
    event.preventDefault();
    const trimmedInput = quickPickQuestion || userInput.trim();
    if (!trimmedInput || isLoading) return;

    setIsLoading(true);

    const user = auth.currentUser;
    if (!user) {
      console.error("User not authenticated");
      setIsLoading(false);
      return;
    }

    // Get clinicId from userData in localStorage
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    const clinicId = userData.user?.clinicId;
    
    if (!clinicId) {
      console.error('No clinic ID found in user data');
      setIsLoading(false);
      return;
    }

    const createdAt = new Date();
    const userId = user.uid;
    const newUserMessage = { sender: 'user', content: trimmedInput, createdAt };
    setConversation(prevConversation => [...prevConversation, newUserMessage]);
    setUserInput('');

    // Update the path to include clinicId
    const messageRef = collection(db, 'clinics', clinicId, 'users', userId, 'chats', chatId, 'messages');
    await addDoc(messageRef, { ...newUserMessage, createdAt: serverTimestamp() });
    
    setTimeout(scrollToLastMessage, 0);

    const botMessagePlaceholder = { sender: 'assistant', content: 'Typing...', createdAt };
    setConversation(convo => [...convo, botMessagePlaceholder]);
    setIsBotTyping(true);

    const functionUrlMap = {
      "patient-explorer": "https://us-central1-lyravet-ac8ca.cloudfunctions.net/chatWithBot",
      "academic-search": "https://us-central1-lyravet-ac8ca.cloudfunctions.net/chatWithBotAcademic",
      "RxSupport": "https://us-central1-lyravet-ac8ca.cloudfunctions.net/chatWithBotRxSupport", 
    };
    
    const selectedFunctionUrl = functionUrlMap[llmMode] || "https://us-central1-lyravet-ac8ca.cloudfunctions.net/chatWithBot";
    
    const conversationHistory = conversation.map(msg => ({
      role: msg.sender,
      content: msg.content,
    }));

    let formattedPatientData = null;
    
    if (includePatientData && selectedPrimerPatient?.id && userData?.user?.missionBrief) {
      console.log('Selected Patient:', selectedPrimerPatient);
      
      // Find the correct doctor's appointments that contain this patient
      const doctorId = Object.keys(userData.user.missionBrief).find(doctorId => 
        userData.user.missionBrief[doctorId].appointments.some(apt => 
          apt.patientData?.pimsId === selectedPrimerPatient.id
        )
      );

      const patientData = doctorId ? 
        userData.user.missionBrief[doctorId].appointments.find(apt => 
          apt.patientData?.pimsId === selectedPrimerPatient.id
        )?.patientData : null;
      
      console.log('Found Patient Data:', patientData);

      if (patientData) {
        console.log('Raw patient history:', patientData.history);
        formattedPatientData = {
          id: patientData.patientId,
          name: patientData.name,
          breed: patientData.breed,
          age: patientData.age,
          sex: patientData.sex,
          weight: patientData.weight,
          status: patientData.status,
          pims_id: patientData.pimsId,
          history: patientData.history,
          missionBrief: patientData.missionBrief,
          medications: patientData.medications || [],
          funFact: patientData.funFact,
          species: patientData.species,
          birthday: patientData.birthday,
          alerts: patientData.alerts || [],
          reminders: patientData.reminders || {}
        };
        console.log('Formatted patient data:', formattedPatientData);
      }
    }

    console.log('Chat Mode:', chatMode);
    console.log('LLM Mode:', llmMode);
    console.log('Selected URL:', selectedFunctionUrl);
    console.log('formattedPatientData', formattedPatientData);
    const payload = {
      chatId,
      content: trimmedInput,
      conversationHistory,
      selectedPatientData: formattedPatientData,
      context: context || 'chat',
      clinicId: clinicId
    };
    console.log('Request Payload:', payload);

    try {
      const token = user && (await user.getIdToken());
      console.log('Auth token present:', !!token);
      
      const response = await fetch(selectedFunctionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Full error response:', errorText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (response.ok) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        const chunks = [];

        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          const chunk = decoder.decode(value);
          const lines = chunk.split('\n');
          for (const line of lines) {
            if (line.startsWith('data:')) {
              const data = line.substring(5).trim();
              if (data === '[DONE]') {
                const orderedChunks = chunks.sort((a, b) => a.chunkIndex - b.chunkIndex);
                const responseText = orderedChunks.map(chunk => chunk.responseText).join('');
                setConversation((prevConversation) => [
                  ...prevConversation.slice(0, -1),
                  { sender: 'assistant', content: responseText, createdAt: new Date() },
                ]);
                break;
              } else {
                try {
                  const parsedData = JSON.parse(data);
                  chunks.push(parsedData);
                  const orderedChunks = chunks.sort((a, b) => a.chunkIndex - b.chunkIndex);
                  const responseText = orderedChunks.map(chunk => chunk.responseText).join('');
                  setConversation((prevConversation) => [
                    ...prevConversation.slice(0, -1),
                    { sender: 'assistant', content: responseText, createdAt: new Date() },
                  ]);
                } catch (error) {
                  console.error('Error parsing server-sent event data:', error);
                }
              }
            }
          }
        }
        setIsBotTyping(false);
      } else {
        console.error('Error getting assistant response:', response.statusText);
        setConversation(prevConversation => prevConversation.slice(0, -1));
        setIsBotTyping(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error getting assistant response:', error);
      setConversation(prevConversation => prevConversation.slice(0, -1));
      setIsBotTyping(false);
    } finally {
      setIsLoading(false);
    }

    // Call the onSend prop if it exists
    if (onSend) {
      onSend();
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = inputRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [userInput]);

  useEffect(() => {
    scrollToLastMessage();
  }, [conversation]);

  return (
    <div className="chat-container">
      {conversation.length > 0 && (
        <button 
          className="clear-chat-btn" 
          onClick={clearConversation}
        >
          Clear Chat
        </button>
      )}
      <div className="chat-box" ref={chatBoxRef}>
        <ul className="chat-messages">
          {conversation.map((msg, index) => (
            <li key={index} ref={index === conversation.length - 1 ? lastMessageRef : null}>
              {msg.sender === 'user' && (
                <div className="message user-message">
                  {msg.content.split('\n').map((line, i) => (
                    <React.Fragment key={i}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </div>
              )}
              {msg.sender === 'assistant' && (
                <div className="message ai-message">
                  <div className={`markdown-content ${isBotTyping && msg.content === 'Typing...' ? 'pulsating-text' : ''}`}>
                    <ReactMarkdown 
                      children={msg.content} 
                      remarkPlugins={[remarkGfm]}
                      components={{
                        // Add custom components for markdown elements
                        h2: ({node, ...props}) => <h2 style={{borderBottom: '1px solid #ddd', paddingBottom: '0.3em'}} {...props}/>,
                        h3: ({node, ...props}) => <h3 style={{marginTop: '1.5em'}} {...props}/>,
                        strong: ({node, ...props}) => <strong style={{fontWeight: '600'}} {...props}/>,
                        ul: ({node, ...props}) => <ul style={{marginLeft: '1em'}} {...props}/>,
                        ol: ({node, ...props}) => <ol style={{marginLeft: '1em'}} {...props}/>,
                        code: ({node, inline, ...props}) => (
                          inline ? 
                            <code style={{backgroundColor: '#f0f0f0', padding: '0.2em 0.4em', borderRadius: '3px'}} {...props}/> :
                            <code style={{display: 'block', backgroundColor: '#f6f8fa', padding: '1em', borderRadius: '6px'}} {...props}/>
                        )
                      }}
                    />
                  </div>
                  {msg.content !== 'Typing...' && (
                    <button className="copy-btn" onClick={() => copyToClipboard(msg.content, index)}>
                      {copiedIndex === index ? 'Copied!' : 'Copy'}
                    </button>
                  )}
                </div>
              )}
            </li>
          ))}
          <div ref={messagesEndRef} />
        </ul>
        <form onSubmit={sendMessage} className="chat-form">
          <textarea
            ref={inputRef}
            rows="1"
            value={userInput}
            onChange={(e) => {
              setUserInput(e.target.value);
              adjustTextareaHeight();
            }}
            onKeyDown={handleKeyDown}
            placeholder="Type a message..."
            className={chatMode}
          />
          <button 
            type="submit" 
            disabled={isLoading}
            style={{ backgroundColor: isLoading ? 'grey' : '' }}
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
}

ChatWindow.propTypes = {
  context: PropTypes.string.isRequired,
  llmMode: PropTypes.string.isRequired,
  chatMode: PropTypes.string.isRequired,
  onSend: PropTypes.func,
  includePatientData: PropTypes.bool,
};

ChatWindow.defaultProps = {
  onClearChat: () => {},
  includePatientData: true,
};

export default forwardRef(ChatWindow);
