import React from 'react';
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { collection, query, where, getDocs, updateDoc, deleteDoc, doc, Timestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';

interface Task {
  id: string;
  title: string;
  description?: string;
  status: string;
  priority: "low" | "medium" | "high";
  dueDate: Date;
  createdAt: Date;
  lastModified: Date;
  patientId: string;
  patientName: string;
  AITask?: boolean;
}

interface AISuggestedTasksRowProps {
  patientId: string;
  clinicId: string;
  userId: string;
}

export const AISuggestedTasksRow: React.FC<AISuggestedTasksRowProps> = ({
  patientId,
  clinicId,
  userId,
}) => {
  const [aiTasks, setAiTasks] = React.useState<Task[]>([]);

  const addAITaskToTasks = async (taskData: Task) => {
    try {
      const taskRef = doc(db, "clinics", clinicId, "users", userId, "tasks", taskData.id);
      await updateDoc(taskRef, {
        AITask: false,
        status: "pending",
        lastModified: Timestamp.fromDate(new Date()),
      });
      
      // Update local state immediately
      setAiTasks(prevTasks => prevTasks.filter(task => task.id !== taskData.id));
    } catch (error) {
      console.error("Error updating AI task:", error);
    }
  };

  const deleteAITask = async (taskId: string) => {
    try {
      const taskRef = doc(db, "clinics", clinicId, "users", userId, "tasks", taskId);
      await deleteDoc(taskRef);
      
      // Update local state immediately
      setAiTasks(prevTasks => prevTasks.filter(task => task.id !== taskId));
    } catch (error) {
      console.error("Error deleting AI task:", error);
    }
  };

  React.useEffect(() => {
    const fetchAITasks = async () => {
      if (!clinicId || !userId || !patientId) return;

      try {
        const tasksRef = collection(db, "clinics", clinicId, "users", userId, "tasks");
        const q = query(
          tasksRef, 
          where("AITask", "==", true),
          where("patientId", "==", patientId)
        );
        const querySnapshot = await getDocs(q);
        
        const tasks = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          dueDate: doc.data().dueDate.toDate(),
          createdAt: doc.data().createdAt.toDate(),
          lastModified: doc.data().lastModified.toDate(),
        })) as Task[];

        setAiTasks(tasks);
      } catch (error) {
        console.error("Error fetching AI tasks:", error);
      }
    };

    fetchAITasks();
  }, [clinicId, userId, patientId]);

  if (aiTasks.length === 0) return null;

  return (
    <div className="mb-6">
      <h6 className="text-gray-500 text-sm mb-4">AI Suggestions</h6>
      <div className="space-y-3">
        {aiTasks.map((task) => (
          <div key={task.id} className="w-full bg-white rounded-lg border border-cosmic-cyan/30 p-4">
            <div className="flex items-center justify-between">
              <h4 className="text-base text-gray-900">
                {task.title}
              </h4>
              <div className="flex gap-2">
                <button
                  onClick={() => addAITaskToTasks(task)}
                  className="p-1 text-cosmic-cyan hover:bg-cosmic-cyan/10 rounded"
                >
                  <PlusIcon className="w-4 h-4" />
                </button>
                <button
                  onClick={() => deleteAITask(task.id)}
                  className="p-1 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded"
                >
                  <XMarkIcon className="w-4 h-4" />
                </button>
              </div>
            </div>
            {task.description && (
              <p className="text-gray-600 text-sm mt-2">
                {task.description}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}; 