import React, { useState, FormEvent } from "react";
import { auth } from "../config/firebase";
import { Link, useNavigate } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  AuthError,
} from "firebase/auth";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { db } from "../config/firebase";
import { doc, getDoc, getDocs, collection } from 'firebase/firestore';

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberDevice, setRememberDevice] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      await setPersistence(
        auth,
        rememberDevice ? browserLocalPersistence : browserSessionPersistence
      );

      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Query all clinics to find the one containing this user
      const clinicsSnapshot = await getDocs(collection(db, 'clinics'));
      let userClinicId = null;

      for (const clinicDoc of clinicsSnapshot.docs) {
        const userDocRef = doc(db, 'clinics', clinicDoc.id, 'users', userCredential.user.uid);
        const userDoc = await getDoc(userDocRef);
        
        if (userDoc.exists()) {
          userClinicId = clinicDoc.id;
          break;
        }
      }

      if (!userClinicId) {
        setError("User not associated with a clinic");
        setLoading(false);
        return;
      }

      // Check subscription status
      const clinicDocRef = doc(db, 'clinics', userClinicId);
      const clinicDoc = await getDoc(clinicDocRef);
      const subscriptionActive = clinicDoc.data()?.subscriptionActive;

      if (!subscriptionActive) {
        if (!userCredential.user.email) {
          setError("No email associated with this account");
          setLoading(false);
          return;
        }
        
        window.location.href = `https://lyravet.com/login`;
        return;
      }

      // If subscription is active, proceed with normal login
      navigate("/PatientPrimer");
    } catch (error) {
      console.error("Login error:", error);
      setLoading(false);
      if (error instanceof Error) {
        const authError = error as AuthError;
        switch (authError.code) {
          case "auth/invalid-credential":
            setError("Invalid email or password. Please try again.");
            break;
          case "auth/user-not-found":
            setError("No account found with this email.");
            break;
          case "auth/wrong-password":
            setError("Incorrect password.");
            break;
          case "auth/invalid-email":
            setError("Invalid email format.");
            break;
          case "auth/too-many-requests":
            setError("Too many failed attempts. Please try again later.");
            break;
          default:
            setError("Failed to log in. Please try again.");
        }
      } else {
        setError("An unexpected error occurred.");
      }
    }
  };

  return (
    <div className="min-h-screen flex relative overflow-hidden">
      {/* Left side with GIF background - now 65% */}
      <div 
        className="w-[65%] p-8 flex items-center justify-center relative"
        style={{
          backgroundImage: 'url("/background.gif")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Gradient overlay */}
        <div className="absolute inset-0" style={{
          background: 'linear-gradient(to right, rgba(0, 184, 212, 0.3), rgba(26, 35, 126, 0.8))'
        }}></div>
        
        {/* Logo with more subtle glow effect */}
        <div className="max-w-3xl w-full relative z-10">
          <img
            src="/logoAllWhite.svg"
            alt="Lyra Vet Logo"
            className="w-full h-auto object-contain"
            style={{
              filter: "drop-shadow(0 0 10px rgba(255,255,255,0.2)) drop-shadow(0 0 20px rgba(255,255,255,0.05))"
            }}
          />
        </div>
      </div>

      {/* Curved divider */}
      <div className="absolute h-full w-[100px] right-[35%] transform translate-x-1/2">
        <div className="absolute inset-0 bg-starlight" style={{
          clipPath: "path('M 0 -100 Q 100 300, 0 1000 L 10000 700 L 100 -100 Z')"
        }}/>
      </div>

      {/* Right side with login form - now 35% */}
      <div className="w-[35%] bg-starlight p-8 flex items-center justify-center">
        <div className="w-full max-w-sm">
          <div className="text-center mb-8">
            <h1 className="text-2xl font-bold text-deep-space-60 mb-2">
              Welcome to Lyra Vet
            </h1>
            <p className="text-space-60">Empowering Veterinary Care</p>
          </div>
          <form onSubmit={handleLogin} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-space-80 mb-1">
                Username
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2.5 border border-space-20 rounded-lg focus:ring-2 focus:ring-cosmic-cyan-60 focus:border-cosmic-cyan-60 outline-none transition-all"
                placeholder="Enter your email"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-space-80 mb-1">
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full p-2.5 border border-space-20 rounded-lg focus:ring-2 focus:ring-cosmic-cyan-60 focus:border-cosmic-cyan-60 outline-none transition-all"
                  placeholder="Enter your password"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-space-60 hover:text-space-80 transition-colors"
                >
                  {showPassword ? (
                    <EyeSlashIcon className="h-5 w-5" />
                  ) : (
                    <EyeIcon className="h-5 w-5" />
                  )}
                </button>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={rememberDevice}
                  onChange={(e) => setRememberDevice(e.target.checked)}
                  className="rounded border-space-20 text-cosmic-cyan-60 focus:ring-cosmic-cyan-60"
                />
                <span className="ml-2 text-sm text-space-60">
                  Remember this Device
                </span>
              </label>
              <button
                type="button"
                onClick={() => {
                  if (email) sendPasswordResetEmail(auth, email);
                }}
                className="text-sm text-cosmic-cyan-60 hover:text-cosmic-cyan-80"
              >
                Forgot Password?
              </button>
            </div>

            {error && <div className="text-martian text-sm mt-2">{error}</div>}

            <button
              type="submit"
              className="w-full bg-cosmic-cyan-60 text-white rounded-lg p-2.5 hover:bg-cosmic-cyan-80 transition-colors"
              disabled={loading}
            >
              {loading ? "Logging in..." : "Sign In"}
            </button>
          </form>
          <p className="mt-6 text-center text-sm text-space-60">
            New to Lyra Vet?{" "}
            <a
              href="https://lyravet.com/signup"
              className="text-cosmic-cyan-60 hover:text-cosmic-cyan-80"
            >
              Create an account
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
