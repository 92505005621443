import React, { useState } from "react";
import Logo from "./Logo";
import Navigation from "./Navigation";
import SystemIcons from "./SystemIcons";
import DoctorSelector from "./DoctorSelector";
import { useDoctor } from "../../contexts/DoctorContext";
import type { Doctor } from "../../types/doctor";

const Header: React.FC = () => {
  const { selectedDoctor, setSelectedDoctor } = useDoctor();
  const [activeScreen, setActiveScreen] = useState<string>("");

  return (
    <header className="flex items-center justify-between px-4 py-2 bg-starlight border-b border-space-20">
      <Logo />
      <div className="flex items-center space-x-4">
        <DoctorSelector
          selectedDoctor={selectedDoctor}
          onDoctorChange={setSelectedDoctor}
        />
        <div className="h-6 w-px bg-space-20" /> {/* Separator */}
        <Navigation onActiveScreenChange={setActiveScreen} />
      </div>
      <SystemIcons activeScreen={activeScreen} />
    </header>
  );
};

export default Header;
