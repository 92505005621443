import React, { useState, useEffect, useCallback } from "react";
import { db, auth } from "../config/firebase";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { FaCopy, FaCheck, FaChevronDown } from "react-icons/fa";
import debounce from "lodash/debounce";

interface Patient {
  id: string;
  name: string;
  appointmentTime?: string;
  appointmentType?: string;
  hasNote?: boolean;
  inProgress?: boolean;
  breed?: string;
  pims_id?: string;
}

interface HistoricalNoteProps {
  patient: Patient;
  onClose: () => void;
  initialContent?: string;
  onShowInputs?: () => void;
  date?: string;
}

interface NoteData {
  clientDischarge: string;
  draftSOAP: string;
  additionalDetails: string;
}

const HistoricalNote: React.FC<HistoricalNoteProps> = ({
  patient,
  onClose,
  initialContent,
  onShowInputs,
  date,
}) => {
  const [noteContent, setNoteContent] = useState(initialContent || "");
  const [copyButtonText, setCopyButtonText] = useState("Copy Text");
  const [saveStatus, setSaveStatus] = useState<"saving" | "saved" | "">("");
  const [noteType, setNoteType] = useState<"soap" | "discharge">("soap");

  const getCurrentDateString = () => {
    if (date) return date;
    const today = new Date();
    return today.toLocaleDateString('en-CA');// Returns YYYY-MM-DD
  };

  const debouncedSave = useCallback(
    debounce(async (content: string, type: "soap" | "discharge") => {
      const userId = auth.currentUser?.uid;
      if (!userId || !patient.id) return;

      // Get clinic ID from localStorage
      const userData = JSON.parse(localStorage.getItem("userData") || "{}");
      const clinicId = userData?.user?.clinicId;
      if (!clinicId) {
        console.error("No clinic ID found");
        return;
      }

      const pimsId = patient.pims_id || patient.id;
      const currentDate = getCurrentDateString();

      setSaveStatus("saving");
      try {
        const noteRef = doc(
          db,
          "clinics",
          clinicId,
          "users",
          userId,
          "scribe",
          currentDate,
          "dailyNotes",
          pimsId
        );

        // Get existing note data first
        const noteDoc = await getDoc(noteRef);
        const existingData = noteDoc.exists()
          ? (noteDoc.data() as NoteData)
          : {
              clientDischarge: "",
              draftSOAP: "",
              additionalDetails: "",
            };

        // Update only the relevant field based on note type
        const updateData =
          type === "soap"
            ? { draftSOAP: content }
            : { clientDischarge: content };

        await updateDoc(noteRef, {
          ...existingData,
          ...updateData,
        });

        setSaveStatus("saved");
        setTimeout(() => setSaveStatus(""), 2000);
      } catch (error) {
        console.error("Error saving note:", error);
        setSaveStatus("");
      }
    }, 1000),
    [patient.id]
  );

  useEffect(() => {
    const fetchNote = async () => {
      const userId = auth.currentUser?.uid;
      if (!userId || !patient.id) return;

      // Get clinic ID from localStorage
      const userData = JSON.parse(localStorage.getItem("userData") || "{}");
      const clinicId = userData?.user?.clinicId;
      if (!clinicId) {
        console.error("No clinic ID found");
        return;
      }

      const pimsId = patient.pims_id || patient.id;
      const currentDate = getCurrentDateString();

      try {
        const noteRef = doc(
          db,
          "clinics",
          clinicId,
          "users",
          userId,
          "scribe",
          currentDate,
          "dailyNotes",
          pimsId
        );

        const noteDoc = await getDoc(noteRef);
        if (noteDoc.exists()) {
          const data = noteDoc.data() as NoteData;
          setNoteContent(
            noteType === "soap" ? data.draftSOAP : data.clientDischarge
          );
        } else if (initialContent) {
          setNoteContent(initialContent);
        }
      } catch (error) {
        console.error("Error fetching note:", error);
      }
    };

    fetchNote();
  }, [patient.id, initialContent, noteType]);

  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = e.target.value;
    setNoteContent(newContent);
    debouncedSave(newContent, noteType);
  };

  const handleCopyText = async () => {
    try {
      await navigator.clipboard.writeText(noteContent);
      setCopyButtonText("Copied!");
      setTimeout(() => setCopyButtonText("Copy Text"), 2000);
    } catch (error) {
      console.error("Failed to copy text:", error);
      setCopyButtonText("Failed to copy");
      setTimeout(() => setCopyButtonText("Copy Text"), 2000);
    }
  };

  return (
    <div className="generated-soap-container">
      {onShowInputs && (
        <button className="toggle-inputs-button" onClick={onShowInputs}>
          <FaChevronDown /> Show Note Inputs
        </button>
      )}
      <div className="note-type-toggle" data-active={noteType}>
        <button
          className={`toggle-button ${noteType === "soap" ? "active" : ""}`}
          onClick={() => setNoteType("soap")}
        >
          SOAP Note
        </button>
        <button
          className={`toggle-button ${
            noteType === "discharge" ? "active" : ""
          }`}
          onClick={() => setNoteType("discharge")}
        >
          Client Discharge
        </button>
      </div>
      <div className="generated-soap-content">
        <textarea
          value={noteContent}
          onChange={handleNoteChange}
          className="generated-soap-note"
          placeholder={
            noteType === "soap"
              ? "Enter SOAP note..."
              : "Enter discharge instructions..."
          }
        />
        {saveStatus && (
          <div className={`save-indicator ${saveStatus}`}>
            {saveStatus === "saving" ? (
              "Saving..."
            ) : (
              <>
                <FaCheck /> Saved
              </>
            )}
          </div>
        )}
      </div>
      <div className="generated-soap-controls">
        <button className="copy-button" onClick={handleCopyText}>
          <FaCopy /> {copyButtonText}
        </button>
      </div>
    </div>
  );
};

export default HistoricalNote;
