import React, { useState, useEffect } from 'react';
import { useUser } from '../App';
import { FiUser, FiMail, FiLock, FiSettings, FiClock, FiChevronRight, FiEdit, FiUsers, FiHome } from 'react-icons/fi';
import { doc, getDoc, updateDoc, collection, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../config/firebase'; // Make sure you have this import
import '../styles/AccountSettings.css';
import { Timestamp } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { getFunctions } from 'firebase/functions';
import { auth } from '../config/firebase';
import { useNavigate } from 'react-router-dom';

interface UserProfile {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  isProfileComplete: boolean;
}

interface ClinicData {
  address: {
    city: string;
    country: string;
    state: string;
    street_address: string;
    zipcode: string;
  };
  admins: string[];
  numberOfDoctors: string;
  practiceName: string;
  phoneNumber: string;
}

interface TeamMember {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

interface PendingUser {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  inviteCode: string;
  status: 'pending' | 'active';
  createdAt: Timestamp;
  expiresAt: Timestamp;
}

interface InviteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (userData: Omit<PendingUser, 'inviteCode' | 'status' | 'createdAt' | 'expiresAt'>) => Promise<void>;
  profileData: UserProfile | null;
  clinicData: ClinicData | null;
  clinicId: string | null;
}

const InviteModal: React.FC<InviteModalProps> = ({ isOpen, onClose, onSubmit, profileData, clinicData, clinicId }) => {
  const { user } = useUser();
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    role: 'Staff'
  });
  const [generatedEmail, setGeneratedEmail] = useState('');
  const [copied, setCopied] = useState(false);

  const generateEmail = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Create URL with query parameters
    const registrationUrl = new URL('https://app.lyravet.com/register');
    registrationUrl.searchParams.append('email', formData.email);
    registrationUrl.searchParams.append('firstName', formData.firstName);
    registrationUrl.searchParams.append('lastName', formData.lastName);
    registrationUrl.searchParams.append('role', formData.role);
    registrationUrl.searchParams.append('clinicId', clinicId || '');
    registrationUrl.searchParams.append('practiceName', clinicData?.practiceName || '');

    const emailContent = `
Dear ${formData.firstName},

I'd like to invite you to join our team at ${clinicData?.practiceName} as a ${formData.role}. 

To get started, please follow the link below to create your account:
_______________________________

${registrationUrl.toString()}

-------------------------------
Important: Please keep this link private and do not share it with others, as anyone with access to this link can use it to create an account.

Thank you,
${profileData?.firstName} ${profileData?.lastName}
${clinicData?.practiceName}
    `.trim();

    setGeneratedEmail(emailContent);
  };

  const clearForm = () => {
    setFormData({
      email: '',
      firstName: '',
      lastName: '',
      role: 'Staff'
    });
    setGeneratedEmail('');
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(generatedEmail);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <div>
            <h2>Generate Team Invitation Email</h2>
            <p className="modal-subtext">
              Create an email to send to your team member. Using the provided link, they will be able to create their account and join your clinic.
            </p>
          </div>
          <button onClick={onClose} className="close-button">&times;</button>
        </div>
        <form onSubmit={generateEmail} className="invite-form">
          {!generatedEmail ? (
            <>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  required
                  value={formData.email}
                  onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                />
              </div>
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  required
                  value={formData.firstName}
                  onChange={(e) => setFormData(prev => ({ ...prev, firstName: e.target.value }))}
                />
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  required
                  value={formData.lastName}
                  onChange={(e) => setFormData(prev => ({ ...prev, lastName: e.target.value }))}
                />
              </div>
              <div className="form-group">
                <label>Role</label>
                <select
                  value={formData.role}
                  onChange={(e) => setFormData(prev => ({ ...prev, role: e.target.value }))}
                >
                  <option value="Staff">Staff</option>
                  <option value="Doctor">Doctor</option>
                  <option value="Admin">Admin</option>
                </select>
              </div>
              <div className="modal-actions">
                
                <button 
                  type="button" 
                  onClick={onClose} 
                  className="button button-secondary"
                >
                  Cancel
                </button>
                <button 
                  type="submit" 
                  className="button button-primary"
                >
                  Create Invite
                </button>
              </div>
            </>
          ) : (
            <div className="generated-email-container">
              <textarea
                className="generated-email"
                value={generatedEmail}
                readOnly
                rows={8}
              />
              <div className="modal-actions">
                <button 
                  type="button" 
                  className="button button-primary"
                  onClick={handleCopy}
                >
                  {copied ? 'Copied!' : 'Copy to Clipboard'}
                </button>
                <button 
                  type="button" 
                  className="button button-secondary"
                  onClick={clearForm}
                >
                  New Invite
                </button>
                <button 
                  type="button" 
                  onClick={onClose} 
                  className="button button-secondary"
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

const AccountSettings: React.FC = () => {
  const { user } = useUser();
  const [profileData, setProfileData] = useState<UserProfile | null>(null);
  const [clinicData, setClinicData] = useState<ClinicData | null>(null);
  const [loading, setLoading] = useState(true);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [isEditingClinic, setIsEditingClinic] = useState(false);
  const [editedProfile, setEditedProfile] = useState<UserProfile | null>(null);
  const [editedClinic, setEditedClinic] = useState<ClinicData | null>(null);
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false);
  const navigate = useNavigate();

  // Get clinicId from localStorage
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  const clinicId = userData?.user?.clinicId;

  // Handle edit mode toggles
  const handleEditProfile = () => {
    setIsEditingProfile(true);
    setEditedProfile({ ...profileData! });
  };

  const handleEditClinic = () => {
    setIsEditingClinic(true);
    setEditedClinic({ ...clinicData! });
  };

  // Handle save actions
  const handleSaveProfile = async () => {
    if (!editedProfile || !clinicId) return;
    
    try {
      const userDocRef = doc(db, 'clinics', clinicId, 'users', user!.uid);
      
      const updateData = {
        firstName: editedProfile.firstName,
        lastName: editedProfile.lastName,
        email: editedProfile.email,
        role: editedProfile.role
      };
      
      await updateDoc(userDocRef, updateData);
      setProfileData(editedProfile);
      setIsEditingProfile(false);
    } catch (error) {
      console.error('Error saving profile:', error);
    }
  };

  const handleSaveClinic = async () => {
    if (!editedClinic || !clinicId) return;
    
    try {
      const clinicDocRef = doc(db, 'clinics', clinicId);
      
      const updateData = {
        practiceName: editedClinic.practiceName,
        phoneNumber: editedClinic.phoneNumber,
        numberOfDoctors: editedClinic.numberOfDoctors,
        address: {
          street_address: editedClinic.address.street_address,
          city: editedClinic.address.city,
          state: editedClinic.address.state,
          zipcode: editedClinic.address.zipcode,
          country: editedClinic.address.country
        }
      };
      
      await updateDoc(clinicDocRef, updateData);
      setClinicData(editedClinic);
      setIsEditingClinic(false);
    } catch (error) {
      console.error('Error saving clinic data:', error);
    }
  };

  // Handle cancel actions
  const handleCancelProfile = () => {
    setIsEditingProfile(false);
    setEditedProfile(null);
  };

  const handleCancelClinic = () => {
    setIsEditingClinic(false);
    setEditedClinic(null);
  };

  const handleInviteSubmit = async (userData: Omit<PendingUser, 'inviteCode' | 'status' | 'createdAt' | 'expiresAt'>) => {
    try {
      const inviteCode = Math.random().toString(36).substring(2, 15);
      
      const pendingUser: PendingUser = {
        ...userData,
        inviteCode,
        status: 'pending',
        createdAt: Timestamp.now(),
        expiresAt: Timestamp.fromDate(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)) // 7 days
      };

      // Add to pending users collection
      await addDoc(collection(db, 'clinics', clinicId, 'pendingUsers'), pendingUser);

      // TODO: Send invitation email (will be implemented with Firebase Functions)
      console.log('Invitation sent to:', userData.email);
    } catch (error) {
      console.error('Error creating invitation:', error);
      throw error;
    }
  };

  const handleManageSubscription = async () => {
    setIsSubscriptionLoading(true);
    try {
      const createPortalSession = httpsCallable(getFunctions(), 'createPortalSession');
      const { data } = await createPortalSession({
        clinicId: clinicId,
        returnUrl: window.location.href,
      });
      
      window.location.href = (data as { url: string }).url;
    } catch (error) {
      console.error('Error accessing billing portal:', error);
      alert('Error Occurred: Please contact us at contact@lyravet.com for support');
    } finally {
      setIsSubscriptionLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      localStorage.removeItem('userData'); // Clear user data from localStorage
      localStorage.removeItem('userFirstName');
      navigate('/PatientPrimer');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!user?.uid || !clinicId) {
        console.log('No user ID or clinic ID available');
        return;
      }

      try {
        // Fetch user profile
        const userDocRef = doc(db, 'clinics', clinicId, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        
        // Fetch clinic data
        const clinicDocRef = doc(db, 'clinics', clinicId);
        const clinicDoc = await getDoc(clinicDocRef);
        
        if (userDoc.exists()) {
          setProfileData(userDoc.data() as UserProfile);
        }
        
        if (clinicDoc.exists()) {
          setClinicData(clinicDoc.data() as ClinicData);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user, clinicId]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      if (!user?.uid || !clinicId) return;
      
      try {
        const usersRef = collection(db, 'clinics', clinicId, 'users');
        const usersSnapshot = await getDocs(usersRef);
        
        const members: TeamMember[] = [];
        usersSnapshot.forEach((doc) => {
          members.push(doc.data() as TeamMember);
        });
        
        setTeamMembers(members);
      } catch (error) {
        console.error('Error fetching team members:', error);
      }
    };

    fetchTeamMembers();
  }, [user, clinicId]);

  console.log('Current state:', { loading, profileData });

  return (
    <div className="account-settings-container">
      <div className="stars-background">
        <div className="stars"></div>
        <div className="stars2"></div>
        <div className="stars3"></div>
      </div>
      
      <div className="content-wrapper">
        <div className="flex items-center gap-4 mb-6">
          <a 
            href="https://app.lyravet.com" 
            className="round-home-button"
          >
            <FiHome />
          </a>
          <h1 className="text-2xl font-bold text-white">Account Settings</h1>
        </div>
        
        <div className="settings-grid">
          {/* Left Column */}
          <div className="main-column">
            {/* Personal Information Section */}
            <div className="settings-card profile-section">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  <FiUser className="w-5 h-5 text-cosmic-cyan-60 mr-2" />
                  <h2 className="text-xl font-semibold text-deep-space-60">Personal Information</h2>
                </div>
                {!isEditingProfile ? (
                  <button className="button button-secondary" onClick={handleEditProfile}>
                    <FiEdit className="w-4 h-4" />
                    Edit
                  </button>
                ) : (
                  <div className="flex gap-2">
                    <button className="button button-secondary" onClick={handleCancelProfile}>
                      Cancel
                    </button>
                    <button className="button button-primary" onClick={handleSaveProfile}>
                      Save
                    </button>
                  </div>
                )}
              </div>
              <div className="settings-items">
                <div className="setting-item">
                  <span className="setting-label">First Name</span>
                  <input 
                    type="text"
                    className="setting-input"
                    value={isEditingProfile ? editedProfile?.firstName : profileData?.firstName || ''}
                    onChange={(e) => setEditedProfile(prev => ({ ...prev!, firstName: e.target.value }))}
                    readOnly={!isEditingProfile}
                  />
                </div>
                <div className="setting-item">
                  <span className="setting-label">Last Name</span>
                  <input 
                    type="text"
                    className="setting-input"
                    value={isEditingProfile ? editedProfile?.lastName : profileData?.lastName || ''}
                    onChange={(e) => setEditedProfile(prev => ({ ...prev!, lastName: e.target.value }))}
                    readOnly={!isEditingProfile}
                  />
                </div>
                <div className="setting-item">
                  <span className="setting-label">Email</span>
                  <input 
                    type="text"
                    className="setting-input"
                    value={isEditingProfile ? editedProfile?.email : profileData?.email || ''}
                    onChange={(e) => setEditedProfile(prev => ({ ...prev!, email: e.target.value }))}
                    readOnly={!isEditingProfile}
                  />
                </div>
                <div className="setting-item">
                  <span className="setting-label">Role</span>
                  <input 
                    type="text"
                    className="setting-input"
                    value={isEditingProfile ? editedProfile?.role : profileData?.role || ''}
                    onChange={(e) => setEditedProfile(prev => ({ ...prev!, role: e.target.value }))}
                    readOnly={!isEditingProfile}
                  />
                </div>
              </div>
            </div>

            {/* Admin Information Section */}
            <div className="settings-card admin-section">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  <FiLock className="w-5 h-5 text-cosmic-cyan-60 mr-2" />
                  <h2 className="text-xl font-semibold text-deep-space-60">Clinic Information</h2>
                </div>
                {!isEditingClinic ? (
                  <button className="button button-secondary" onClick={handleEditClinic}>
                    <FiEdit className="w-4 h-4" />
                    Edit
                  </button>
                ) : (
                  <div className="flex gap-2">
                    <button className="button button-secondary" onClick={handleCancelClinic}>
                      Cancel
                    </button>
                    <button className="button button-primary" onClick={handleSaveClinic}>
                      Save
                    </button>
                  </div>
                )}
              </div>
              <div className="settings-items">
                <div className="setting-item">
                  <span className="setting-label">Practice Name</span>
                  <input 
                    type="text"
                    className="setting-input"
                    value={isEditingClinic ? editedClinic?.practiceName : clinicData?.practiceName || ''}
                    onChange={(e) => setEditedClinic(prev => ({ ...prev!, practiceName: e.target.value }))}
                    readOnly={!isEditingClinic}
                  />
                </div>
                <div className="setting-item">
                  <span className="setting-label">Phone Number</span>
                  <input 
                    type="tel"
                    className="setting-input"
                    value={isEditingClinic ? editedClinic?.phoneNumber : clinicData?.phoneNumber || ''}
                    onChange={(e) => setEditedClinic(prev => ({ ...prev!, phoneNumber: e.target.value }))}
                    readOnly={!isEditingClinic}
                  />
                </div>
                <div className="setting-item">
                  <span className="setting-label">Street Address</span>
                  <input 
                    type="text"
                    className="setting-input"
                    value={isEditingClinic ? editedClinic?.address?.street_address : clinicData?.address?.street_address || ''}
                    onChange={(e) => setEditedClinic(prev => ({ ...prev!, address: { ...prev!.address, street_address: e.target.value } }))}
                    readOnly={!isEditingClinic}
                  />
                </div>
                <div className="setting-item">
                  <span className="setting-label">City</span>
                  <input 
                    type="text"
                    className="setting-input"
                    value={isEditingClinic ? editedClinic?.address?.city : clinicData?.address?.city || ''}
                    onChange={(e) => setEditedClinic(prev => ({ ...prev!, address: { ...prev!.address, city: e.target.value } }))}
                    readOnly={!isEditingClinic}
                  />
                </div>
                <div className="setting-item">
                  <span className="setting-label">State</span>
                  <input 
                    type="text"
                    className="setting-input"
                    value={isEditingClinic ? editedClinic?.address?.state : clinicData?.address?.state || ''}
                    onChange={(e) => setEditedClinic(prev => ({ ...prev!, address: { ...prev!.address, state: e.target.value } }))}
                    readOnly={!isEditingClinic}
                  />
                </div>
                <div className="setting-item">
                  <span className="setting-label">Zip Code</span>
                  <input 
                    type="text"
                    className="setting-input"
                    value={isEditingClinic ? editedClinic?.address?.zipcode : clinicData?.address?.zipcode || ''}
                    onChange={(e) => setEditedClinic(prev => ({ ...prev!, address: { ...prev!.address, zipcode: e.target.value } }))}
                    readOnly={!isEditingClinic}
                  />
                </div>
                <div className="setting-item">
                  <span className="setting-label">Country</span>
                  <input 
                    type="text"
                    className="setting-input"
                    value={isEditingClinic ? editedClinic?.address?.country : clinicData?.address?.country || ''}
                    onChange={(e) => setEditedClinic(prev => ({ ...prev!, address: { ...prev!.address, country: e.target.value } }))}
                    readOnly={!isEditingClinic}
                  />
                </div>
                <div className="setting-item">
                  <span className="setting-label">Number of Doctors</span>
                  <input 
                    type="text"
                    className="setting-input"
                    value={isEditingClinic ? editedClinic?.numberOfDoctors : clinicData?.numberOfDoctors || ''}
                    onChange={(e) => setEditedClinic(prev => ({ ...prev!, numberOfDoctors: e.target.value }))}
                    readOnly={!isEditingClinic}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Team Members */}
          <div className="team-column">
            <div className="settings-card clinic-team-section">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  <FiUsers className="w-5 h-5 text-cosmic-cyan-60 mr-2" />
                  <h2 className="text-xl font-semibold text-deep-space-60">Clinic Team</h2>
                </div>
              </div>
              
              <div className="team-members-container">
                <div className="team-members">
                  {teamMembers.map((member, index) => (
                    <div key={index} className="team-member">
                      <div className="member-info">
                        <div className="member-details">
                          <div className="member-name">
                            {member.firstName} {member.lastName}
                          </div>
                          <div className="member-role">
                            {member.role}
                          </div>
                        </div>
                      </div>
                      <div className="member-email">
                        {member.email}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <button 
                className="add-member-button"
                onClick={() => setIsInviteModalOpen(true)}
              >
                <span className="plus-icon">+</span>
                Add Team Member
              </button>
            </div>

            {/* Subscription Management Section */}
            <div className="settings-card subscription-section">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  <FiSettings className="w-5 h-5 text-cosmic-cyan-60 mr-2" />
                  <h2 className="text-xl font-semibold text-deep-space-60">Subscription</h2>
                </div>
              </div>
              <div className="button-container">
                <button 
                  className="button button-primary"
                  onClick={handleManageSubscription}
                  disabled={isSubscriptionLoading}
                >
                  {isSubscriptionLoading ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Loading...
                    </>
                  ) : (
                    'Manage Subscription'
                  )}
                </button>
                <button 
                  className="button button-secondary button-danger"
                  onClick={handleLogout}
                >
                  Sign Out
                </button>
              </div>
            </div>

            <InviteModal
              isOpen={isInviteModalOpen}
              onClose={() => setIsInviteModalOpen(false)}
              onSubmit={handleInviteSubmit}
              profileData={profileData}
              clinicData={clinicData}
              clinicId={clinicId || ''}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings; 